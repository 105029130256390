var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.getloading
    ? _c(
        "div",
        [
          _c("loading", {
            staticClass: "svg-stroke-primary-600",
            attrs: {
              active: _vm.getloading,
              "can-cancel": false,
              "is-full-page": true,
            },
            on: {
              "update:active": function ($event) {
                _vm.getloading = $event
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }