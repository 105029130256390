export default {
  setBalance: (state, data) => {
    state.balance = data.data.content;
    state.can_add = data.restrictions.can_add;
    state.storeBalance = data.data.store_points;
    state.reviewsBalance = data.data.reviews_balance;
  }, 
  setAddDialog: (state, data) => {
    state.addDialog = data;
  },
  setBalanceInfo: (state, data) => {
    state.balanceInfo = data.content;
  },
  setPaymentProcessState: (state, data) => {
    if (data == 1) state.PaymentProcessSuccess = true;
    if (data == 2) state.PaymentProcessfail = true;
  },
  setPaymentProcessFalse: (state) => {
    state.PaymentProcessSuccess = false;
    state.PaymentProcessfail = false;
  },
};
