import Vue from 'vue';
import LeftIcon from '~/static/media/icon/leftIcon.svg?inline';
import RightIcon from '~/static/media/icon/rightIcon.svg?inline';
import EditIcon from '~/static/media/icon/editIcon.svg?inline';
import CloseIcon from '~/static/media/icon/closeIcon.svg?inline';
import user from '~/static/media/icon/user.svg?inline';
import charts from '~/static/media/icon/charts.svg?inline';
import diamond from '~/static/media/icon/diamond.svg?inline';
import advanceSettings from '~/static/media/icon/advanceSettings.svg?inline';
import info from '~/static/media/icon/Info.svg?inline';
import Sections from '~/static/media/icon/Sections.svg?inline';
import plusIcon from '~/static/media/icon/plusIcon.svg?inline';
import download from "~/static/media/icon/download.svg?inline";
import filterIcon from "~/static/media/icon/filter.svg?inline";
import searchIcon from "~/static/media/icon/Search.svg?inline";
import uploadImage from '~/static/media/icon/uploadImg.svg?inline';
import uploadImages from '~/static/media/icon/uploadimage.svg?inline';
import questionRating from '~/static/media/icon/questionRating.svg?inline';
import campaign from '~/static/media/icon/campaign.svg?inline';
import campaign2 from '~/static/media/icon/campaignIcon.svg?inline';
import point from '~/static/media/icon/point.svg?inline';
import message from '~/static/media/icon/message.svg?inline';
import starRate from '~/static/media/icon/starRate.svg?inline';
import arSvg from "~/static/media/icon/ar.svg?inline";
import enSvg from "~/static/media/icon/en.svg?inline";
import stopIcon from '~/static/media/icon/stop.svg?inline';
import viewDetails from '~/static/media/icon/viewDetails.svg?inline';
import verticaldots from '~/static/media/icon/Verticaldots.svg?inline';
import pocket1 from '~/static/media/icon/pocket1.svg?inline';
import pocket2 from '~/static/media/icon/pocket2.svg?inline';
import passwordChange from '~/static/media/icon/changePassword.svg?inline';
import clock from '~/static/media/icon/clock.svg?inline';
import transactions from '~/static/media/icon/transaction.svg?inline';
import noData from '~/static/media/icon/noData.svg?inline';
import branches from '~/static/media/icon/branches.svg?inline';
import eyeon from '~/static/media/icon/eyeon.svg?inline';
import eyeoff from '~/static/media/icon/eyeoff.svg?inline';
import copy from '~/static/media/icon/copy.svg?inline';
import stopIcon2 from '~/static/media/icon/stopIcon.svg?inline';
import countinu from '~/static/media/icon/countinu.svg?inline';
import barcode from '~/static/media/icon/barcode.svg?inline';
import resetIcon from '~/static/media/icon/reset.svg?inline';

import notifications from '~/static/media/icon/Notifications.svg?inline';
import deleteSVG from '~/static/media/icon/delete.svg?inline';
import textIcon from '~/static/media/icon/text.svg?inline';
import warning from '~/static/media/icon/warning.svg?inline';
import warningIcon from '~/static/media/icon/warning2.svg?inline';
import ratings from '~/static/media/icon/ratings.svg?inline'; 
import fileUpload from '~/static/media/icon/fileUpload.svg?inline'; 
import copyIcon2 from '~/static/media/icon/copy-icon2.svg?inline'; 
import surveysIcon from '~/static/media/icon/surveys.svg?inline'; 
import dragmenuIcon from '~/static/media/icon/dragmenu.svg?inline'; 


import faceIcon from '~/static/media/icon/face.svg?inline'; 
import dateIcon from '~/static/media/icon/date.svg?inline'; 

import happyIcon from '~/static/media/icon/happy.svg?inline'; 
import smileyIcon from '~/static/media/icon/smiley.svg?inline'; 
import okIcon from '~/static/media/icon/ok.svg?inline'; 
import sadIcon from '~/static/media/icon/sad.svg?inline'; 
import angryIcon from '~/static/media/icon/angry.svg?inline'; 

 
import timeIcon from '~/static/media/icon/time.svg?inline'; 
import packageTimeIcon from '~/static/media/icon/package-time.svg?inline'; 
import subscriptionIcon from '~/static/media/icon/subscription.svg?inline'; 
import googleIcon from '~/static/media/icon/googleIcon.svg?inline'; 
import LogoWhite from '~/static/media/icon/logo-white.svg?inline'; 
import arrowLeft from '~/static/media/icon/arrow-left.svg?inline'; 
import watchIcon from '~/static/media/icon/watch-icon.svg?inline'; 





import hospitalsIcon from '~/static/media/icon/hospitals.svg?inline'; 
import hotelsIcon from '~/static/media/icon/hotels.svg?inline'; 
import activitiesIcon from '~/static/media/icon/activities.svg?inline'; 
import restaurentsIcon from '~/static/media/icon/restaurents.svg?inline'; 
import airportsIcon from '~/static/media/icon/airports.svg?inline'; 
import shopsIcon from '~/static/media/icon/shops.svg?inline'; 
import parksIcon from '~/static/media/icon/parks.svg?inline'; 
import governmentsIcon from '~/static/media/icon/governments.svg?inline'; 
import companiesIcon from '~/static/media/icon/companies.svg?inline'; 
import transportationsIcon from '~/static/media/icon/transportations.svg?inline'; 
import googleReviewIcon from '~/static/media/icon/google.svg?inline'; 
import openLinkIcon from '~/static/media/icon/open-link.svg?inline'; 
import playerIcon from '~/static/media/icon/player.svg?inline'; 
import outgoingBox from '~/static/media/icon/outgoing-box.svg?inline'; 

import autoMessages from '~/static/media/icon/auto-messages.svg?inline'; 
import CheckIcon from '~/static/media/icon/check-icon.svg?inline'; 
import XIcon from '~/static/media/icon/close-icon.svg?inline'; 
import socialMedia from '~/static/media/icon/social-media.svg?inline'; 
import whatsapp from '~/static/media/icon/whatsapp.svg?inline'; 
import gmail from '~/static/media/icon/gmail.svg?inline'; 
import socialWatch from '~/static/media/icon/social-watch.svg?inline'; 
import socialPoint from '~/static/media/icon/social-point.svg?inline'; 
import attachment from '~/static/media/icon/attachment.svg?inline'; 
import points from "~/static/media/icon/points.svg?inline";
import nps from "~/static/media/icon/nps-icon.svg?inline";
import multipleAnswers from "~/static/media/icon/multiple-answers.svg?inline";
import minusIcon from '~/static/media/icon/minusIcon.svg?inline';

import voiceIcon from '~/static/media/icon/voice.svg?inline';
import pdfIcon from '~/static/media/icon/pdf.svg?inline';
import shareFacebook from '~/static/media/icon/share-facebook.svg?inline';
import shareTelegram from '~/static/media/icon/share-telegram.svg?inline';
import shareWhatsapp from '~/static/media/icon/share-whatsapp.svg?inline';
import shareX from '~/static/media/icon/share-x.svg?inline';
import shareEmail from '~/static/media/icon/share-email.svg?inline';


import documentIcon from '~/static/media/icon/document-icon.svg?inline';
import homeIcon from '~/static/media/icon/home-icon.svg?inline';
import responsesIcon from '~/static/media/icon/responses-icon.svg?inline';
import settingsIcon from '~/static/media/icon/settings-icon.svg?inline';
import analysisIcon from '~/static/media/icon/analysis-icon.svg?inline';
import helpIcon from '~/static/media/icon/help-icon.svg?inline';


import copyUrlIcon from '~/static/media/icon/copy-url.svg?inline';
import folderIcon from '~/static/media/icon/folder.svg?inline';
import noDataIcon from '~/static/media/icon/nodata-icon.svg?inline';
import arrowDownIcon from '~/static/media/icon/arrow-down.svg?inline';
import rolesIcon from '~/static/media/icon/roles-icon.svg?inline';
import cleanIcon from '~/static/media/icon/cleanIcon.svg?inline';
import logoutIcon from '~/static/media/icon/logout.svg?inline';
import surveyNotAvailable from '~/static/media/icon/survey-notavailable.svg?inline';

import performance from '~/static/media/icon/performance.svg?inline';
import uploadIcon from '~/static/media/icon/upload.svg?inline';
import sliderIcon from '~/static/media/icon/slider.svg?inline';
import toggleIcon from '~/static/media/icon/toggle.svg?inline';



import phoneIcon from '~/static/media/icon/phone.svg?inline';
import emailIcon from '~/static/media/icon/email.svg?inline';
import appleIcon from '~/static/media/icon/apple.svg?inline';
import securityIcon from '~/static/media/icon/security.svg?inline';
import uploadFileIcon from '~/static/media/icon/uploadFileIcon.svg?inline';

import logo from '~/static/media/logo.svg?inline';
import oneUser from '~/static/media/icon/oneUser.svg?inline';
import multipleUsers from '~/static/media/icon/multipleUsers.svg?inline';


import uploadFile from '~/static/media/icon/uploadFile.svg?inline';
import downloadFile from '~/static/media/icon/downloadFile.svg?inline';
import fileSvg from '~/static/media/icon/file-svg.svg?inline';
import sentimentIcon from '~/static/media/icon/sentiment.svg?inline';
import googleReviewsIcon from '~/static/media/icon/google-reviews.svg?inline';

import CSChat from '~/static/media/icon/CSChat.svg?inline';
import prevIcon from '~/static/media/icon/prev-icon.svg?inline';
import Chat from "~/static/media/icon/Chat.svg?inline";

// import location from '~/static/media/icon/location.svg?inline';
// import locPosition from '~/static/media/icon/locPosition.svg?inline';

// Vue.component('location-icon',location);
// Vue.component('sentiment-icon',locPosition);
Vue.component('CSChat-icon',CSChat);
Vue.component('chat-icon',Chat);
Vue.component('prev-icon',prevIcon);
Vue.component('sentiment-icon',sentimentIcon);
Vue.component('google-reviews',googleReviewsIcon);
Vue.component('logo',logo);
Vue.component('One-user',oneUser);
Vue.component('multiple-users',multipleUsers);
Vue.component('upload-file',uploadFile);
Vue.component('download-file',downloadFile);
Vue.component('file-svg',fileSvg);
Vue.component('home-icon',homeIcon);
Vue.component('responses-icon',responsesIcon);
Vue.component('settings-icon',settingsIcon);
Vue.component('analysis-icon',analysisIcon);
Vue.component('help-icon',helpIcon);
Vue.component('arrow-down-icon',arrowDownIcon);
Vue.component('roles-icon',rolesIcon);
Vue.component('clear-icon',cleanIcon);
Vue.component('logout-icon',logoutIcon);
Vue.component('survey-not-available-icon',surveyNotAvailable);

Vue.component('performance-icon',performance);
Vue.component('upload-icon',uploadIcon);
Vue.component('slider-icon',sliderIcon);
Vue.component('toggle-icon',toggleIcon);

Vue.component('copy-url-icon',copyUrlIcon);
Vue.component('folder-icon',folderIcon);
Vue.component('nodata-icon',noDataIcon);


Vue.component('check-icon',CheckIcon);
Vue.component('x-icon',XIcon);
Vue.component('close-icon',CloseIcon);
Vue.component('left-icon',LeftIcon);
Vue.component('right-icon',RightIcon);
Vue.component('edit-icon',EditIcon);
Vue.component('user-icon',user);
Vue.component('charts-icon',charts);
Vue.component('diamond-icon',diamond);
Vue.component('advance-settings-icon',advanceSettings);
Vue.component('plus-icon',plusIcon);
Vue.component('sections-icon',Sections);
Vue.component('info-icon',info);
Vue.component('download-icon',download);
Vue.component('filter-icon',filterIcon);
Vue.component('search-icon',searchIcon);
Vue.component('upload-image-icon',uploadImage);
Vue.component('question-rating',questionRating);
Vue.component('star-icon',starRate);
Vue.component('campaign-icon',campaign);
Vue.component('campaign2-icon',campaign2);
Vue.component('point-icon',point);
Vue.component('message-icon',message);
Vue.component('ar-svg-icon',arSvg);
Vue.component('en-svg-icon',enSvg);
Vue.component('stop-icon',stopIcon);
Vue.component('view-details-icon',viewDetails);
Vue.component('vertical-dots-icon',verticaldots);
Vue.component('pocket1-icon',pocket1);
Vue.component('pocket2-icon',pocket2);
Vue.component('password-change-icon',passwordChange);
Vue.component('clock-icon',clock);
Vue.component('transactions-icon',transactions);
Vue.component('no-data-icon',noData);
Vue.component('branches-icon',branches);
Vue.component('eyeon-icon',eyeon);
Vue.component('eyeoff-icon',eyeoff);
Vue.component('stop2-icon',stopIcon2);
Vue.component('copy-icon',copy);
Vue.component('countinu-icon',countinu);
Vue.component('barcode-icon',barcode);
Vue.component('notifications-icon',notifications);
Vue.component('delete-icon',deleteSVG);
Vue.component('upload-images-icon',uploadImages );
Vue.component('text-icon',textIcon );
Vue.component('warning-icon',warning );
Vue.component('warning2-icon',warningIcon );
Vue.component('ratings-icon',ratings );
Vue.component('file-upload',fileUpload );
Vue.component('copy-icon2',copyIcon2 );
Vue.component('surveys-icon',surveysIcon );
Vue.component('dragmenu-icon',dragmenuIcon );
Vue.component('csat-icon',faceIcon );
Vue.component('smiley_faces-icon',faceIcon );
Vue.component('calender-icon',dateIcon );


Vue.component('happy-icon',happyIcon );
Vue.component('smiley-icon',smileyIcon );
Vue.component('ok-icon',okIcon );
Vue.component('sad-icon',sadIcon );
Vue.component('angry-icon',angryIcon );

Vue.component('time-icon',timeIcon );
Vue.component('subscription-icon',subscriptionIcon );
Vue.component('package-time-icon',packageTimeIcon );

Vue.component('reset-icon',resetIcon );
Vue.component('google-icon',googleIcon );
Vue.component('logo-white',LogoWhite );
Vue.component('arrow-left',arrowLeft );
Vue.component('watch-icon',watchIcon );



Vue.component('hospitals-icon',hospitalsIcon );
Vue.component('hotels-icon',hotelsIcon );
Vue.component('activities-icon',activitiesIcon );
Vue.component('restaurents-icon',restaurentsIcon );
Vue.component('airports-icon',airportsIcon );
Vue.component('shops-icon',shopsIcon );
Vue.component('parks-icon',parksIcon );
Vue.component('governments-icon',governmentsIcon );
Vue.component('companies-icon',companiesIcon );
Vue.component('transportations-icon',transportationsIcon );
Vue.component('google-review-icon',googleReviewIcon );
Vue.component('open-link-icon',openLinkIcon );
Vue.component('player-icon',playerIcon );
Vue.component('outgoing-box-icon',outgoingBox );
Vue.component('auto-messages-icon',autoMessages );
Vue.component('social-media-icon',socialMedia);
Vue.component('whatsapp-icon',whatsapp);
Vue.component('gmail-icon',gmail);
Vue.component('social-watch-icon',socialWatch);
Vue.component('social-point-icon',socialPoint);
Vue.component('attachment-icon',attachment);
Vue.component('points-icon',points);
Vue.component('nps-icon',nps);
Vue.component('choices-icon',multipleAnswers);
Vue.component('minus-icon',minusIcon);
Vue.component('voice-icon',voiceIcon);
Vue.component('pdf-icon',pdfIcon);


Vue.component('share-facebook-icon',shareFacebook);
Vue.component('share-telegram-icon',shareTelegram);
Vue.component('share-whatsapp-icon',shareWhatsapp);
Vue.component('share-x-icon',shareX);
Vue.component('share-email-icon',shareEmail);
Vue.component('document-icon',documentIcon);



Vue.component('phone-icon',phoneIcon);
Vue.component('email-icon',emailIcon);
Vue.component('apple-icon',appleIcon);
Vue.component('security-icon',securityIcon);
Vue.component('upload-file-icon',uploadFileIcon);


