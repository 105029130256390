export default {
  getAllSystemUsers(state) {
    return state.allSystemUsers;
  },
  getOneSystemUser(state) {
    return state.oneSystemUser;
  },
  getDeleteDialog(state) {
    return state.deleteDialog;
  },
  getAllRoles(state) {
    return state.allRoles;
  },
  getAllPermissions(state) {
    return state.allPermissions;
  },
  getOneRole(state) {
    return state.oneRole;
  },
  getDeleteRoleDialog(state) {
    return state.deleteRoleDialog;
  },
};
