export default {
   
  getNotifications(state) {
    return state.notifications;
  },  
  getPackage(state) {
    return state.packages;
  },
  getAllPackages(state) {
    return state.allPackages;
  },
  getTutorials(state) {
    return state.tutorials;
  },
  getTutorialsDetails(state) {
    return state.tutorialsDetails;
  }, 
  
};
