var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "landing-header", staticStyle: { "z-index": "99" } },
    [
      _c("header", { staticClass: "review" }, [
        _c(
          "div",
          { staticClass: "main-layout" },
          [
            _c(
              "v-layout",
              { attrs: { "align-center": "" } },
              [
                _c(
                  "NuxtLink",
                  {
                    staticStyle: { display: "flex", "align-items": "center" },
                    attrs: { to: `/${_vm.$i18n.locale}/` },
                  },
                  [
                    _vm.getSectionToReview
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "bg-input rounded-6 text-center layout align-center",
                            staticStyle: { height: "70px", width: "70px" },
                          },
                          [
                            _c("v-img", {
                              staticClass: "mx-auto rounded-4",
                              attrs: {
                                src: _vm.getSectionToReview.store_logo,
                                height: "60px",
                                "max-width": "60px",
                              },
                            }),
                          ],
                          1
                        )
                      : _c("logo-white", { staticClass: "logo" }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.getSectionToReview
                  ? _c(
                      "span",
                      { staticClass: "mx-2 text-medium-16 text-white" },
                      [_vm._v(_vm._s(_vm.getSectionToReview.section_name))]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "list-div",
                staticStyle: { "z-index": "1", display: "flex" },
              },
              [
                _c("ul", { staticClass: "main-list" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage(
                              _vm.$i18n.locale == "ar" ? "en" : "ar"
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$i18n.locale === "ar" ? "English" : "العربية"
                            ) +
                            "\n\n            "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }