export default {
  getAllProjects(state) {
    return state.allProjects;
  }, 
  getOneProject(state) {
    return state.oneProject;
  }, 
  getCopiedProject(state) {
    return state.copiedProject;
  }, 
  getAllStoreProjects(state) {
    return state.allStoreProjects;
  }, 
  
};
