export default () => ({
  statistics: null,
  subscriptionRecords: null,
  data: {
  
    dailyRatingDays:[],
    dailyRatingReviews:[],
    monthlyRatingDays:[],
    monthlyRatingReviews:[],
    questionRatingQuestions:[],
    questionRatingQuestionTypes:[],
    questionRatingReviews:[],
    questionRatingChartData:[],
    surveyRatingSurveys:[],
    surveyRatingReviews:[],
    branchRatings:[]
},
});
