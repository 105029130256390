<template>
  <v-navigation-drawer id="app-drawer" :right="language.rtl == 'rtl' ? true : false" app dark v-model="inputValue"
    floating class="drawerclass" persistent mobile-breakpoint="992" width="265" style="max-height: 100%;">
    <v-list-item style="padding: 9px 13px; height: 65px">
      <div v-if="getIsLoggedIn" class="layout align-center">
        <a href="/" class="my-auto" style="
              background-color: #fff;
              border-radius: 5px;
              width: 38px;
              height: 38px;
            ">
          <img alt="Logo" v-if="getIsLoggedIn && getIsLoggedIn.medias" :src="getImageUrl + getIsLoggedIn.medias.name"
            width="38px" height="38px" />
        </a>
        <div style="margin: 0px 7px">
          <p class="subtitle my-0" style="color: #fff !important; line-height: 15px !important">
            {{ getIsLoggedIn.name }}
          </p>
          <span class="packageLabel" style="height: 14px !important" v-if="getIsLoggedIn.subscription_type == 1">{{
            $t("packages.startPackage1") }}</span>
          <span class="packageLabel" style="height: 14px !important" v-if="getIsLoggedIn.subscription_type == 2">{{
            $t("packages.mediumPackage1") }}</span>
          <span class="packageLabel" style="height: 14px !important" v-if="getIsLoggedIn.subscription_type == 3">{{
            $t("packages.professionalismPackage1") }}</span>
        </div>
      </div>
    </v-list-item>

    <div class="fb-nav-list" :style="responsive ? ' padding-top: 46px; ' : 'padding-top: 55px; '">
      <v-list-item :to="`/${$i18n.locale}/dashboard`">
        <div class="svg-icon">
          <home-icon />
        </div>

        <v-list-item-title v-text="$t('Core.drawer.Dashboard')" />
      </v-list-item>
      <div  v-if="getIsLoggedIn && getIsLoggedIn.restrictions.emailVerified" >
        <template v-for="(item, i) in navlinks">
          <v-list-item :key="i" v-if="item.link" :to="`/${$i18n.locale}/` + item.link" v-permissions="item.permissions">
            <div class="svg-icon">
              <component style="height: 20px; width: 20px" :is="item.icon" />
            </div>

            <v-list-item-title v-text="$t('Core.drawer.' + item.text)" />
          </v-list-item>

          <v-list-group :key="i" v-if="!item.link" no-action>

            <template #activator>
              <v-list-item class="px-0 no-hover">

                <div class="svg-icon ">
                  <analysis-icon />
                </div>
                <v-list-item-title>
                  {{
                    $t("Core.drawer." + item.text)
                  }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item v-for="(listItem, m) in item.links" :key="m" :to="`/${$i18n.locale}/${listItem.action}`">
              <v-list-item-title v-text="$t('Core.drawer.' + listItem.text)" />

            </v-list-item>
          </v-list-group>
        </template>

      </div>
    </div>
    <template v-slot:append>
      <div class="fb-nav-list" style="padding: 15px 0"> 
        <v-list-group no-action>

          <template #activator>
            <v-list-item class="px-0 no-hover">

              <div class="svg-icon ">
                <help-icon />
              </div>
              <v-list-item-title>
                {{
                  $t("Core.drawer.help")
                }}
              </v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item :to="`/${$i18n.locale}/chats`">
            <v-list-item-title v-text="$t('Core.drawer.Chats')" />

          </v-list-item>
          <v-list-item :to="`/${$i18n.locale}/help-center`">
            <v-list-item-title v-text="$t('helpCenter.title')" />

          </v-list-item>
        </v-list-group>

        <v-list-item  @click="logoutPopup = true" >
        <div class="svg-icon">
          <logout-icon />
        </div>

        <v-list-item-title v-text="$t('Core.drawer.Logout')"  />
      </v-list-item>
       
      </div>
    </template>
    <delete-popup @deleteAction="logOutFunction()" :visible="logoutPopup" :title="$t('dashboard.logout')"
      :subTitle="$t('dashboard.logoutSubTitle')" :btntitle="$t('dashboard.logout')" @close="logoutPopup = false"
      :stop="3" />
      <store-name   :visible="showUpdate" @close="showUpdate = false"/>

  </v-navigation-drawer>
</template>

<script>
 

import { mapGetters, mapMutations, mapActions } from "vuex";
import { permissions as permissionsEnum } from "@/static/js/permissions.enum";
import StoreName from "../../general/setting/StoreName.vue";
import deletePopup from "../../general/feedbacks/deletePopup.vue";
export default {
  components: { deletePopup, StoreName },
  props: {
    opened: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    logoutPopup: false,
    mini: false,
    permissions: permissionsEnum,
    showUpdate:false,

    navlinks: [
     
      {
        icon: 'document-icon',
        text: "surveys",
        link: "surveys",
        permissions: [permissionsEnum.readBranches]
      }, 
      {
        icon: 'responses-icon',
        text: "answers",
        link: "ratings",
        label: true,
        permissions: [permissionsEnum.readReviews]
      },
      
      {
        text: 'analysis',
        icon: ' analysis-icon',
        links: [
        {
            action: "analysis/reports",
            text: 'reports'
          },
          {
            action: 'analysis/survey-analysis',
            text: "survey_analysis",
          },
          {
            action: "analysis/sentiment-anlysis",
            text: 'sentiment_anlysis'
          },
          {
            action: "analysis/google-reviews",
            text: 'google_reviews'
          }
         
        ]

      },
      {
        icon: 'settings-icon',
        text: "Settings",
        link: "settings",
        permissions: [permissionsEnum.readPackage, permissionsEnum.readReports, permissionsEnum.editStoreData, permissionsEnum.readSystemUsers, permissionsEnum.editAdvanceSetting]
      },
    ],
  }),
  computed: {
    ...mapGetters({
      language: "app/getlang",
      getIsLoggedIn: "auth/getIsLoggedIn",
      getImageUrl: "app/getImageUrl",
    }),
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    responsive() {
      if (
        this.$vuetify.breakpoint.name == "sm" ||
        this.$vuetify.breakpoint.name == "xs"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.loadDasboardStatisitcs();

    this.loadUserDetail().then(() => {
      // after loading user details check if the  user has any restrictions and if he is not verified
     let notVerifiedRoutes =['dashboard','help-center','chat','email-verification']
        if (this.getIsLoggedIn && this.getIsLoggedIn.restrictions && !this.getIsLoggedIn.restrictions.emailVerified) {
            if (!notVerifiedRoutes.includes(this.$route.path.split("/")[2].toLowerCase())) {
              this.$router.push("/" + this.$i18n.locale + "/dashboard");
            } 
        }
      // this is for checking if the name of the store is still the same name
        if (this.getIsLoggedIn && this.getIsLoggedIn.restrictions && this.getIsLoggedIn.restrictions.emailVerified   && this.getIsLoggedIn.name ==='منصة فيدباك'  && this.$route.path.split("/")[2].toLowerCase() == "dashboard") {
          this.showUpdate=true
    
        }
    })

  },
  methods: {
    ...mapActions({
      loadUserDetail: "auth/loadUserDetail",
      logoutFromStore: "auth/logout",
      loadDasboardStatisitcs: "reports/loadDasboardStatisitcs",
    }),
    ...mapMutations({
      setDrawer: "app/setDrawer",
    }),
    logOutFunction() {
      this.logoutPopup = true;
      this.logoutFromStore();
    },
  },
};
</script>

 
