export default {
  async loadAllQuestions({ commit },data) {
    commit('app/loadingStart', null, { root: true });
    await this.$axios.get(`store-questions?type=${data.type}&category=${data.category}&store_question=${data.store_question}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setAllQuestion', respo.data.data);
      }
    });
  },
  async loadAutoQuestions({ commit },data) {
    commit('app/loadingStart', null, { root: true });
    await this.$axios.get(`store-questions?type=${data.type}&category=${data.category}&store_question=${data.store_question}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setAutoQuestion', respo.data.data);
      }
    });
  },
  async loadOneQuestions({ commit }, questionId) {
    commit('app/loadingStart', null, { root: true });

   return await this.$axios
      .get(`/store-questions/${questionId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOneQuestion', respo.data.data);
          return respo.data.data.content
        }
      });
  },
  async saveNewQuestion({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

   return await this.$axios.post(`/store-questions`, data).then((respo) => {
      if (respo.status === 200) {
      
          if(data.question_type != 10)
          commit('InsertQuestion', {
            ...respo.data.data.content
          });
          return respo.data.data.content
        commit('app/successMessage', 'questionAdded', { root: true });
      }
    });
  },
  async editOneQuestion({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`/store-questions/${data.questionId}`, data.questionDetails)
      .then((respo) => {
        if (respo.status === 200) {
          
            commit('UpdateQuestion', {
              ...respo.data.data.content
            });
          commit('app/successMessage', 'questionUpdated', { root: true });
        }
      });
  },
  async deleteOneQuestion({ commit }, questionId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .delete(`/store-questions/${questionId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit('deleteQuestion', questionId);
          commit('app/successMessage', 'questionDeleted', { root: true });
        }
      });
  },
  async loadAnalysisQuestions({ commit }) {
    commit('app/loadingStart', null, { root: true });
    await this.$axios.get(`analysis-questions`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setAnalysisQuestions', respo.data.data.content);
        commit('setTempAnalysisQuestions', respo.data.data.content);
      }
    });
  },
  async updateAnalysisQuestions({ commit },data) {
    commit('app/loadingStart', null, { root: true });
    await this.$axios.post(`analysis-questions`, data).then((respo) => {
      if (respo.status === 200) {
        commit('setAnalysisQuestions', respo.data.data.content);
        commit('setTempAnalysisQuestions', respo.data.data.content); 
      }
    });
  },
};
