<template>
  <v-app>
    <v-layout
      style="height: 100vh; background-color: #fff"
      justify-center
      align-center
      v-if="error.statusCode === 404"
    >
      <v-layout
        wrap
        style="
          max-width: 1200px;
          height: 100vh;
          background-color: #fff;
          margin: 18px !important;
        "
        justify-center
        align-center
      >
        <v-flex
          class="order-2 order-sm-1 order-lg-1 order-md-1 order-xl-1"
          sm6
          xs12
          md5
          lg5
        >
          <v-layout
            column
            :class="responsive ? 'justify-center align-center' : ''"
          >
            <NuxtLink :to="`${$i18n.locale}/`" style="margin-bottom: 23px">
              <v-img contain :src="'/media/logo.svg'" width="89px" />
            </NuxtLink>
            <h3
              class="login-title"
              :style="
                responsive
                  ? 'margin-bottom: 16px; font-size: 24px !important'
                  : 'margin-bottom: 16px; font-size: 30px !important'
              "
            >
              {{ $t('dashboard.sorry') }}
            </h3>
            <div
              class="text-regular-14 text-font-sub"
              style="margin-bottom: 22px; line-height: 30px"
              :style="
                responsive
                  ? 'font-size: 20px !important'
                  : ' font-size: 26px !important'
              "
            >
              {{ $t('dashboard.noSuchPage') }}
            </div>
            <WameedButton
            @onClick="
                getIsLoggedIn
                  ? $router.back()
                  : $router.push({ path: `/${$i18n.locale}/` })
              "
              class="text-white text-regular-13"
              append-icon="prev-icon"
              style="  max-width: 200px"
              :title="  getIsLoggedIn
                  ? $t('dashboard.goBack')
                  : $t('dashboard.backhome')"

             />
        
          </v-layout>
        </v-flex>

        <v-flex
          class="order-1 order-sm-2 order-lg-2 order-md-3 order-xl-4"
          sm6
          xs12
          md7
          lg7
          style="padding: 7px"
        >
          <v-img :src="require('@/static/media/svg/404.svg')" />
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout
      style="height: 100vh; background-color: #fff"
      justify-center
      align-center
      v-else
    >
      <v-layout
        wrap
        style="
          max-width: 1200px;
          height: 100vh;
          background-color: #fff;
          margin: 18px !important;
        "
        justify-center
        align-center
      >
        <v-flex
          class="order-2 order-sm-1 order-lg-1 order-md-1 order-xl-1"
          sm6
          xs12
          md5
          lg5
        >
          <v-layout
            column
            :class="responsive ? 'justify-center align-center' : ''"
          >
            <NuxtLink :to="`${$i18n.locale}/`" style="margin-bottom: 23px">
              <v-img contain :src="'/media/logo.svg'" width="98px" />
            </NuxtLink>
            <h3
              class="login-title"
              :style="
                responsive
                  ? 'margin-bottom: 16px; font-size: 24px !important'
                  : 'margin-bottom: 16px; font-size: 30px !important'
              "
            >
              {{ $t('dashboard.sorry') }}
            </h3>
            <div
              class="text-regular-14 text-font-secondary"
              style="margin-bottom: 22px; line-height: 30px"
              :style="
                responsive
                  ? 'font-size: 20px !important'
                  : ' font-size: 26px !important'
              "
            >
              {{ $t('dashboard.noSuchPage') }}
            </div>
            <WameedButton
            @onClick="
                getIsLoggedIn
                  ? $router.back()
                  : $router.push({ path: `/${$i18n.locale}/` })
              "
              class="text-white text-regular-13"
              append-icon="prev-icon"
              style="  max-width: 200px"
              :title="  getIsLoggedIn
                  ? $t('dashboard.goBack')
                  : $t('dashboard.backhome')"

             />
          </v-layout>
        </v-flex>

        <v-flex
          class="order-1 order-sm-2 order-lg-2 order-md-3 order-xl-4"
          sm6
          xs12
          md7
          lg7
          style="padding: 7px"
        >
          <v-img :src="require('@/static/media/svg/404.svg')" />
        </v-flex>
      </v-layout>
    </v-layout>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import WameedButton from '../components/material/WameedButton.vue';

export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  components:{
    WameedButton
  },
  layout:'callback',
  asyncData({ app }) {
    return {
      pageNotFound: app.i18n.t('nodata.404NotFound'),
      otherError: app.i18n.t('nodata.Anerroroccurred'),
    };
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title,
    };
  },
  computed: {
    ...mapGetters({
      getIsLoggedIn: 'auth/getIsLoggedIn',
    }),
    responsive() {
      if (this.$vuetify.breakpoint.name == 'xs') {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style >
h1 {
  font-size: 20px;
}
</style>
