<template>
    <button :id="id" :type="type" :disabled="disabled" class="wameed-btn" :class="[
        classes,
        btnVar,
        pill ? 'btn-pill' : '',
        disabled ? 'btn-disabled' : ''
    ]" @click="onClick">
    <slot  />
        <component class="btn-text" :is="appendIcon" v-if="appendIcon" />
        <span v-if="title" :class="['btn-text ', textClasses]">
            {{ title }}
        </span>
        <span v-if="subTitle" :class="['btn-text ', subTextClasses]">
            {{ subTitle }}
        </span>
        <component class="btn-text" :is="prependIcon" v-if="prependIcon" />
        <span label="Spinning" :class="['btn-spinner', loadingVariant]" small></span>
    </button>
</template>

<script>


export default {
    props: {
        id: {
            type: String,
            default: null,
        },
        classes: {
            type: String,
            default: null,
        },
        textClasses: {
            type: String,
            default: '',
        },
        subTextClasses: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: null,
        },
        subTitle: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: null,
        },
        pill: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: "button",
        },
        appendIcon: {
            type: [Object, String],
            default: null,
        },
        prependIcon: {
            type: [Object, String],
            default: null,
        },
        loading: {
            type: Boolean,
            default: null,
        },
        loadingVariant: {
            type: String,
            default: null,
        },
        loadingType: {
            type: String,
            default: "grow",
        },
    },

    computed: {
        btnVar() {
            return 'btn-' + this.variant;
        }
    },
    methods: {
        onClick(e) {
            e.preventDefault()
            this.$emit("onClick");
        }
    }
}
</script>