export default () => ({
  branchToReview: null,
  sectionToReview:null,
  reviewCoupon:null,
  reviewed:false,
  googleReviewLink: null,
  reviewId:null,
  advertisement:null,
  PhoneCodeSent: null,
  OtpVerification: null,
});
