<template>
  <v-app>
    <Nuxt />
    <messages />

  </v-app>
</template>

<script> 
import Messages from '../components/material/messages.vue';
export default {
   components: { Messages },
}
</script>
