export default {
  setAllSystemUsers: (state, data) => {
    state.allSystemUsers = data;
  },
  setOneSystemUser: (state, data) => {
    state.oneSystemUser = data;
  },
  deleteSystemUser: (state, data) => {
    var index = state.allSystemUsers.content.findIndex(
      (user) => user.id === data
    );
    if (index != -1) {
      state.allSystemUsers.content.splice(index, 1);
      state.deleteDialog = false;
    }
  },
  setDeleteDialog: (state, data) => {
    state.deleteDialog = data;
  },
  setAllRoles: (state, data) => {
    state.allRoles = data;
  },
  //   update the user's state property with the data.state value
  changeState: (state, data) => {
    var index = state.allSystemUsers.content.findIndex(
      (user) => user.id === data.id
    );
    if (index != -1) {
      state.allSystemUsers.content[index].state = data.state;
    }
  },
  setAllPermissions: (state, data) => {
    state.allPermissions = data;
  },
  setOneRole: (state, data) => {
    state.oneRole = data;
  },
  // remove that role from the array if found, and then sets state.deleteRoleDialog to false
  deleteRole: (state, data) => {
    var index = state.allRoles.content.findIndex(
      (role) => role.id === data
    );
    if (index != -1) {
      state.allRoles.content.splice(index, 1);
      state.deleteRoleDialog = false;
    }
  },
  setDeleteRoleDialog: (state, data) => {
    state.deleteRoleDialog = data;
  },
  // update the publish property of the role.
  changeRoleState: (state, data) => {
    var index = state.allRoles.content.findIndex(
      (role) => role.id === data.id
    );
    if (index != -1) {
      state.allRoles.content[index].publish = data.publish;
    }
  },
};
