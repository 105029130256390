export default {
  setAllReviews: (state, data) => {
    state.allReviews = data;
  },
  setBranches: (state, data) => {
    state.branches = data.content;
  },
  setGoogleReviews: (state, data) => {
    state.googleReviews = data
  },
  
};
