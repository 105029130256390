import { DxfWriter, HatchPolylineBoundary, vertex, HatchBoundaryPaths, HatchPredefinedPatterns, pattern, LWPolylineFlags, point2d } from "@tarikjabiri/dxf";

export default {
  // loading reviews
  async loadAllReviews({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(
        `store-review?order_by=${data.order_by ? data.order_by : ''}&sort_by=${data.sort_by}&sort_direction=${data.sort_direction}&page=${data.page}&per_page=${data.per_page}&branch=${data.branch
        }&section=${data.section??''}&month=${data.month}&star=${data.star??''
        }&question=${data.question??''}&type=${data.type??''}${data.read_reviews ? `&read_reviews=true` : ``}${data.sentiment ? `&sentiment=${data.sentiment}` : ``}`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setAllReviews', respo.data.data);
        }
      });
  },
  // loadin review details
  async loadOneReview({ commit }, reviewId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`/store-review/${reviewId}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setOneReview', respo.data.data);
      }
    });
  },
  async deleteOneReview({ commit }, reviewId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.delete(`/store-review/${reviewId}`).then((respo) => {
      if (respo.status === 200) {
        commit('deleteReview', reviewId);
        commit('app/successMessage', 'reviewDeleted', { root: true });
      }
    });
  },
  async saveNewAlertReview({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`/send-alert-review`, data).then((respo) => {
      if (respo.status === 200) {
        commit('insertAlertReview');
        commit('app/successMessage', 'alertSent', { root: true });
      }
    });
  },
  async exportToExcel({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(
        `review-group-by-questions?order_by=${data.order_by ? data.order_by : ''}&sort_by=${data.sort_by}&sort_direction=${data.sort_direction}&page=${data.page}&per_page=${data.per_page}&branch=${data.branch
        }&section=${data.section}
        &month=${data.month}&star=${data.star}&question=${data.question
        }&type=${data.type}${data.read_reviews ? `&read_reviews=true` : ``
        }${data.is_sentiment ? `&is_sentiment=true` : ``}${data.sentiment ? `&sentiment=${data.sentiment}` : ``}&file_type=${data.type}`,
        null,
        {
          responseType: 'blob'
        }
      )
      .then((respo) => {
        if (respo.status === 200) {
          // export returned data

          const link = document.createElement('a');
          link.download = data.fileName + '_' + this.$moment().format('YYYY-MM-DD') + '.'+data.type;
          link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + respo.data;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

        }
      });
  },
 
  async loadSentimentRange({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(
        `analysis/reviews/monthly?month=${data.month_id}`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setSentimentRange', respo.data.data.content);
        }
      });
  },

  async loadSentimentQuestions({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(
        `analysis/reviews?month=${data.month_id}`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setSentimentQuestions', respo.data.data);
        }
      });
  },  


  async loadSurveyAnalysis({ commit }, data) {
    commit('app/loadingStart', null, { root: true });
    await this.$axios
      .get(
        `surveys/analysis?branch=${data.branch ? data.branch : ''}&survey=${data.survey}&date=${data.date}`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setSurveyAnalysis', respo.data.data.content);
        }
      });
  },

  async addPadding({ }, data) {
    var parentClientReact = data.element.getBoundingClientRect();
     
    for (var item = 0; item < data.deletedItems.length; item++) {
      document.getElementById(data.deletedItems[item].replace('_', '-')).classList.add("deleted-items");


    }

    var breakAvoids = data.element.querySelectorAll('.break-avoid');
    var parentClientReact = data.element.getBoundingClientRect();
    for (var i = 0; i < breakAvoids.length; i++) {
      var clientRect = breakAvoids[i].getBoundingClientRect();
      if (breakAvoids[i].classList.contains('html2pdf__page-break')) {
        var elHeight = clientRect.top - parentClientReact.top
        let reminder = ((elHeight) % data.pageHeight)


        breakAvoids[i].style['display'] = 'block';
        breakAvoids[i].style['height'] = Math.floor(data.pageHeight - (reminder)) + 'px';
        breakAvoids[i].style['background-color'] = '#fff';
        breakAvoids[i].style['width'] = '100%';
      } else {
        var avoidTop = clientRect.top - parentClientReact.top
        var avoidBottom = clientRect.bottom - parentClientReact.top
        let currentPageHeight = data.pageHeight * (Math.floor(avoidTop / data.pageHeight) + 1)
        if (currentPageHeight > avoidTop && currentPageHeight < avoidBottom) {
          let reminder = ((avoidTop) % data.pageHeight)

          breakAvoids[i].style['margin-top'] = Math.floor(data.pageHeight - (reminder)) + 'px';
        }

      }




    }
    return true

  },

  async resetPadding({ }, data) {
    var parentClientReact = data.element.getBoundingClientRect();
    data.element.classList.remove("download");
    for (var item = 0; item < data.deletedItems.length; item++) {
      document.getElementById(data.deletedItems[item].replace('_', '-')).classList.remove("deleted-items");
    }
    var breakAvoids = data.element.querySelectorAll('.break-avoid');
    var parentClientReact = data.element.getBoundingClientRect();
    breakAvoids.forEach((el, i) => {
      var clientRect = el.getBoundingClientRect();
      if (el.classList.contains('html2pdf__page-break')) {


        breakAvoids[i].style['display'] = 'block';
        el.style['height'] = '0px';
        el.style['width'] = '0%';
      } else {
        var avoidTop = clientRect.top - parentClientReact.top
        var avoidBottom = clientRect.bottom - parentClientReact.top
        let currentPageHeight = data.pageHeight * (Math.floor(avoidTop / data.pageHeight) + 1)
        if (currentPageHeight > avoidTop && currentPageHeight < avoidBottom) {

          el.style['margin-top'] = '0px';
        }

      }




    });
    return true

  },
 
  // pdf download
  // This code snippet defines an asynchronous function called downloadPdf which uses HTML2Canvas to capture the content of an element as a canvas and then converts it into a PDF. 
  // It then saves the PDF with the specified title and resets padding on the element. Finally, it triggers a loading finish action using Vuex.
  async downloadPdf({ dispatch, commit }, data) {
    let pageHeight = 1260;
    let pageWidth = 860;
    await dispatch('addPadding', { element: data.element, pageHeight: pageHeight, deletedItems: data.deletedItems }).then(async (res) => {
      setTimeout(async () => {
        await data.html2canvas(data.element, {
          type: 'dataURL',
          allowTaint: false,
          useCORS: true,
          logging: true,
          scale: 2,
          letterRendering: true
        }).then(async (canvas) => {


          const pdf = new data.jsPDF('p', 'pt', 'a4', true);



          for (var i = 0; i <= data.element.clientHeight / pageHeight; i++) {
            //! This is all just html2canvas stuff
            var srcImg = canvas;
            var sX = 0;
            var sY = pageHeight * i; // start pageHeight pixels down for every new page
            var sWidth = pageWidth;
            var sHeight = pageHeight;
            var dX = 0;
            var dY = 0;
            var dWidth = pageWidth;
            var dHeight = pageHeight;

            window.onePageCanvas = document.createElement("canvas");
            onePageCanvas.setAttribute('width', pageWidth);
            onePageCanvas.setAttribute('height', pageHeight);
            // onePageCanvas.style.transform= 'scale(2)';
            var ctx = onePageCanvas.getContext('2d');
            // details on this usage of this function: 
            // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
            // ctx.scale(2,1)
            ctx.drawImage(srcImg, sX, sY * 2, sWidth * 2, sHeight * 2, dX, dY, dWidth, dHeight);

            // document.body.appendChild(canvas);
            var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

            var width = onePageCanvas.width;
            var height = onePageCanvas.clientHeight;

            //! If we're on anything other than the first page,
            // add another page
            if (i > 0) {
              pdf.addPage(); //8.5" x 11" in pts (in*72)
            }
            //! now we declare that we're working on that page
            pdf.setPage(i + 1);
            //! now we add content to that page!
            pdf.addImage(canvasDataURL, 'PNG', 30, 30, (width * .62), (height * .62));

          }
          pdf.save(data.title + this.$moment().format('YYYY-MM-DD'))
          //! after the for loop is finished running, we save the pdf.
          dispatch('resetPadding', { element: data.element, deletedItems: data.deletedItems })

          commit('app/loadingFinish', null, { root: true });

        });
      }, 100);

    })
  },

};
