<template>
  <v-app id="loginStyle" style="height: 100vh">
    <client-only>
    <div
      class="d-flex flex-column flex-root"
      style="height: 100vh; background-color: #fff"
    >
      <v-layout   class="login "  >
        <v-flex
          class="
            login-content
            flex-column-fluid
            d-flex
            flex-column
            justify-space-between
            loginbg
          "
        >
          <div class="text-right d-flex justify-center align-center">
            <v-layout class="login-form px-2" justify-center align-center>
              <NuxtLink :to="`/${$i18n.locale}/`">
                <img alt="Logo" :src="'/media/logo.svg'" width="98px" />
              </NuxtLink>
              <v-spacer /> 
              <v-btn
                text
                class="login-subtitle my-auto px-0"
                @click="changeLanguage()"
                ><span class="login-subtitle">
                  {{ $t('Core.Header.language.other') }}</span
                ></v-btn
              >
            </v-layout>
          </div>
          <div
            class="d-flex flex-row-fluid flex-center justify-center"
            style="padding-top: 72px"
          >
            <div class="login-form">
              <nuxt />
            </div>
          </div>
          <div class="text-right d-flex justify-center">
            <div
              class="text-center d-flex justify-content-end pt-5 pb-lg-0 pb-10"
            >
              <NuxtLink :to="`/${$i18n.locale}/`" style="text-decoration: none">
                <span class="text-regular-14 text-font-sub">
                  {{ $t('LogIn.devlopedBy') }}</span
                >
                <span class="text-regular-14 text-font-sub text-primary-600">
                  {{ $t('LogIn.org') }}</span
                >
              </NuxtLink>
            </div>
          </div>
        </v-flex>
        <v-flex
          lg8
          md8
          sm12
          xs12
          justify-center
          align-center
          v-if="!responsive"
          class=" d-flex flex-column flex-row-auto loginimgbg"
        >
          <v-layout style="height: 100%;width:100%" align-center justify-center class="bg-gray-50 rounded-16">
            <v-img
              style="max-width: 80%; margin: auto; max-height: 400px"
              contain
              src="/media/svg/loginsideimage.svg"
            />
          </v-layout>
        </v-flex>
      </v-layout>
      <messages />
    </div>
  </client-only>

  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import Messages from '../components/material/messages.vue';
export default {
  components: { Messages },
  head() {
    return {
      htmlAttrs: {
        lang: this.language.locale,
        direction: this.language.rtl,
        dir: this.language.rtl,
        style: `direction: ${this.language.rtl}`,
      },
    };
  },
  computed: {
    ...mapGetters({ language: 'app/getlang' }),
    responsive() {
      if (
        this.$vuetify.breakpoint.name == 'sm' ||
        this.$vuetify.breakpoint.name == 'xs'
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        let loginData = this.$cookies.get('login-data', { parseJSON: true });
         if (loginData) {
           this.$router.push(`/${this.$i18n.locale}/dashboard`);
        }
      }, 500);
      this.$nuxt.$loading.start();
      setTimeout(() => {
        this.$nuxt.$loading.finish();
        this.showcontent = true;
      }, 2000);
    });


    this.$vuetify.rtl = this.$i18n.locale=='ar';
   
  },
  methods: { 

    changeLanguage() {
      //changing language by route
      this.$router.push({
        path:
          this.language.locale !== 'ar'
            ? '/ar' + this.$route.path.substring(3, this.$route.path.length)
            : '/en' + this.$route.path.substring(3, this.$route.path.length),
      });
      setTimeout(() => {
          this.$router.go()

        }, 100);
    },
  },
};
</script>
