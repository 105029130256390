var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DialogLayout", {
    attrs: {
      visible: _vm.visible,
      title: _vm.$t("setting.complete_info"),
      "btn-title": _vm.$t("actions._save"),
    },
    on: {
      close: function ($event) {
        _vm.show = false
      },
      submit: function ($event) {
        return _vm.updateSubinfo()
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "v-layout",
              { attrs: { wrap: "" } },
              [
                _c(
                  "v-layout",
                  {
                    staticClass:
                      "bg-info-50 wrap border-info-600 rounded-8 mb-6 py-4 mx-6",
                    staticStyle: { gap: "8px" },
                    attrs: { "align-center": "", "justify-space-between": "" },
                  },
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "text-regular-14 mx-2",
                        attrs: { "align-center": "" },
                      },
                      [
                        _c("warning2-icon", { staticClass: "svg-info mx-2" }),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass: "text-medium-14 text-font-main my-0",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("setting.account_name_info")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-flex",
                  {
                    staticStyle: { padding: "0 24px" },
                    attrs: { lg12: "", md12: "", xs12: "", sm12: "" },
                  },
                  [
                    _c("text-field", {
                      attrs: {
                        label: _vm.$t("setting.account_name"),
                        required: "",
                        classes: "controlPanel",
                      },
                      model: {
                        value: _vm.name,
                        callback: function ($$v) {
                          _vm.name = $$v
                        },
                        expression: "name",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }