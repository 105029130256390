export default {
  async loadAllCategories({ commit }) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(`/stores/categories`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setAllCategories', respo.data.data);
        }
      })
      .catch((error) => {
        throw error;
      });
  },
};
