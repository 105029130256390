var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "client-only",
        [
          _c("notifications", {
            attrs: {
              width: _vm.messagewith(),
              "animation-name": "v-fade",
              group: "failmessages",
              position:
                _vm.$i18n.locale === "ar" ? "bottom left" : "bottom right",
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function (props) {
                  return [
                    _c(
                      "v-alert",
                      { staticClass: "px-0 py-0 mx-3 FailMessages" },
                      [
                        _c(
                          "v-layout",
                          {
                            staticStyle: {
                              width: "100% !important",
                              "justify-content": "space-between",
                              height: "100%",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { margin: "auto 18px" } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t(props.item.text))
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "rounded-6 px-0",
                                staticStyle: { margin: "12px" },
                                attrs: { text: "", small: "" },
                                on: { click: props.close },
                              },
                              [_c("close-icon", { staticClass: "my-1" })],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "client-only",
        [
          _c("notifications", {
            attrs: {
              width: _vm.messagewith(),
              group: "successmesages",
              position:
                _vm.$i18n.locale === "ar" ? "bottom left" : "bottom right",
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function (props) {
                  return [
                    _c(
                      "v-alert",
                      { staticClass: "mx-3 px-0 py-0 SuccessMesages" },
                      [
                        _c(
                          "v-layout",
                          {
                            staticStyle: {
                              width: "100% !important",
                              "justify-content": "space-between",
                              height: "100%",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { margin: "auto 18px" } },
                              [_vm._v(" " + _vm._s(_vm.$t(props.item.text)))]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "rounded-6 px-0",
                                staticStyle: { margin: "12px" },
                                attrs: { text: "", small: "" },
                                on: { click: props.close },
                              },
                              [_c("close-icon", { staticClass: "my-1" })],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }