<template>
  <div>
    <v-app-bar id="core-toolbar" app :height="responsive ? `55` : `65`" class="elevation-0" :style="responsive
        ? `background-color: #8363AA;padding:0;`
        : `background-color: #ffffff;padding-left: 10px !important;padding-right: 10px !important;`
      " style="z-index: 99">
      <v-layout style="max-width: 1872px">
        <v-btn icon v-show="responsive" @click.stop="onClickBtn" dark class="d-flex d-md-none" text>
          <v-icon v-if="!this.getdrawer">fas fa-bars</v-icon>
          <x-icon v-if="this.getdrawer" class="svg-white" />
        </v-btn>
        <v-flex v-show="!responsive" lg6 md7 sm4 xs4>
          <v-autocomplete v-model="model" :items="items" :loading="false"
            :placeholder="$t('Core.Header.Search') + ' ...'" :search-input.sync="search" hide-selected solo hide-no-data
            hide-details="auto" item-text="name" item-value="abbr" return-object append-icon="">
            <div slot="prepend-inner">
              <search-icon />
            </div>
          </v-autocomplete>
        </v-flex>
        <v-spacer />
        <a v-show="responsive" href="/" class="my-auto" style="
            background-color: #fff;
            border-radius: 5px;
            width: 38px;
            height: 38px;
          ">
          <img alt="Logo" v-if="getIsLoggedIn && getIsLoggedIn.medias" :src="getImageUrl + getIsLoggedIn.medias.name"
            width="38px" height="38px" />
        </a>

        <v-spacer v-show="responsive" />
        <v-btn @click="userMenu = !userMenu" v-show="responsive" text size="">
          <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
          <svg id="Stockholm-icons-_-General-_-User" data-name="Stockholm-icons-/-General-/-User"
            xmlns="http://www.w3.org/2000/svg" width="25.987" height="25.987" viewBox="0 0 16.987 16.987">
            <path id="Shape" d="M0,0H16.987V16.987H0Z" fill="none" fill-rule="evenodd" />
            <path id="Mask" d="M10.831,8.662a2.831,2.831,0,1,1,2.831-2.831A2.831,2.831,0,0,1,10.831,8.662Z"
              transform="translate(-2.338 -0.877)" fill="#fff" opacity="0.3" />
            <path id="Mask-Copy"
              d="M3,18.1C3.275,14.717,6.017,13,9.358,13c3.389,0,6.173,1.623,6.381,5.1a.486.486,0,0,1-.532.566H3.515A.784.784,0,0,1,3,18.1Z"
              transform="translate(-0.877 -3.799)" fill="#fff" />
          </svg>

          <!--end::Svg Icon-->
        </v-btn>
        <v-layout v-show="!responsive" justify-end align-center>
          <!--begin::Quick panel-->

          <v-badge color="#089E90" :value="getIsLoggedIn.unread_alerts > 0 ? 1 : 0" bordered dot overlap top offset-x="22"
            offset-y="14">
            <v-btn text class="mx-1 px-0" style="min-width: 38px" @click="showNotification = true">
              <notifications-icon />
            </v-btn>
          </v-badge>

          <v-badge color="#089E90" :value="getStatistics && getStatistics.no_read_messages > 0 ? 1 : 0" bordered dot
            overlap top offset-x="15" offset-y="15">
            <v-btn :to="`/${$i18n.locale}/chats`" text class="mx-1 px-0" style="min-width: 38px">
              <chat-icon />
            </v-btn>
          </v-badge>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn id="lang-img" v-bind="attrs" v-on="on" text>
                <ar-svg-icon v-if="language.locale === 'ar'" />
                <en-svg-icon v-if="language.locale === 'en'" />
              </v-btn>
            </template>
            <v-list class="fb-lang-list p-0 m-0" style="max-width: 147px; padding: 6px 0px">
              <v-list-item :class="$i18n.locale === 'ar' ? `active` : null" @click="changeLanguage('ar')"
                style="min-height: 22px; min-width: 140px; padding: 4px 12px">
                <v-list-item-title>
                  <v-layout align-center justify-space-between>
                    <v-layout>
                      <span>
                        <ar-svg-icon style="height: 16px; width: 16px" />
                      </span>
                      <span class="text-regular-12 text-font-main" style="margin: 0 8px">العربية</span>
                    </v-layout>
                    <v-icon v-if="$i18n.locale === 'ar'" style="font-size: 12px;">fa-check</v-icon>
                  </v-layout>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeLanguage('en')" style="min-height: 22px; min-width: 140px; padding: 4px 12px">
                <v-list-item-title>
                  <v-layout justify-space-between>
                    <v-layout>
                      <span>
                        <en-svg-icon style="height: 16px; width: 16px" />
                      </span>
                      <span class="text-regular-12 text-font-main" style="margin: 0 8px">English</span>
                    </v-layout>
                    <v-icon v-if="$i18n.locale === 'en'" style="font-size: 12px;">fa-check</v-icon>
                  </v-layout>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <div class="px-2" v-if="getIsLoggedIn">
            <v-btn text class="bg-light text-regular-13 text-font-sub px-0 "
            style="height: 40px; min-width: 40px; margin: 0 10px">
              <span v-if="getIsLoggedIn.owner_name" style="font-weight: bolder">{{
      getIsLoggedIn.owner_name.substring(0, 1)
    }}</span>
            </v-btn>
            <span class="mx-1" style="
                font-weight: 700 !important;
                color: #b5b5c3 !important;
                font-size: 1rem;
              ">Hi,</span>
            <span class="" style="
                font-weight: 600 !important;
                color: #7e8299 !important;
                font-size: 1rem;
              " v-if="getIsLoggedIn.owner_name">{{ getIsLoggedIn.owner_name.split(" ")[0] }}</span>
          </div>

        </v-layout>
      </v-layout>
    </v-app-bar>
    <v-layout class="fb-user-menu" v-show="responsive && userMenu" justify-end align-center>

      <v-badge color="#089E90" :value="getIsLoggedIn.unread_alerts > 0 ? 1 : 0" bordered dot overlap top offset-x="22"
        offset-y="14">
        <v-btn text class="mx-1 px-0" style="min-width: 38px" @click="showNotification = true">
          <notifications-icon />
        </v-btn>
      </v-badge>

      <v-badge color="#089E90" :value="getStatistics && getStatistics.no_read_messages > 0 ? 1 : 0" bordered dot overlap
        top offset-x="15" offset-y="15">
        <v-btn :to="`/${$i18n.locale}/chats`" text class="mx-1 px-0" style="min-width: 38px">
          <chat-icon />
        </v-btn>
      </v-badge>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text class="mx-1 px-0" style="min-width: 28px" id="lang-img" v-bind="attrs" v-on="on">
            <ar-svg-icon v-if="language.locale === 'ar'" />
            <en-svg-icon v-if="language.locale === 'en'" />
          </v-btn>
        </template>
        <v-list class="fb-lang-list p-0 m-0" style="max-width: 147px; padding: 6px 0px">
          <v-list-item :class="$i18n.locale === 'ar' ? `active` : null" @click="changeLanguage('ar')"
            style="min-height: 22px; min-width: 140px; padding: 4px 12px">
            <v-list-item-title>
              <v-layout align-center justify-space-between>
                <v-layout>
                  <span>
                    <ar-svg-icon style="height: 16px; width: 16px" />
                  </span>
                  <span class="text-regular-12 text-font-main" style="margin: 0 8px">العربية</span>
                </v-layout>
                <v-icon v-if="$i18n.locale === 'ar'" style="font-size: 12px;">fa-check</v-icon>
              </v-layout>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="changeLanguage('en')" style="min-height: 22px; min-width: 140px; padding: 4px 12px">
            <v-list-item-title>
              <v-layout justify-space-between>
                <v-layout>
                  <span>
                    <en-svg-icon style="height: 16px; width: 16px" />
                  </span>
                  <span class="text-regular-12 text-font-main" style="margin: 0 8px">English</span>
                </v-layout>
                <v-icon v-if="$i18n.locale === 'en'" style="font-size: 12px;">fa-check</v-icon>
              </v-layout>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="px-2" v-if="getIsLoggedIn">
        <v-btn text class="bg-light text-regular-13 text-font-sub px-0 "
        style="height: 40px; min-width: 40px; margin: 0 10px">
          <span v-if="getIsLoggedIn.owner_name" style="font-weight: bolder">{{
      getIsLoggedIn.owner_name.substring(0, 1)
    }}</span>
        </v-btn>
      </div>

    </v-layout>
    <notification v-if="showNotification" :visible="showNotification" @close="showNotification = false" />
  </div>
</template>
<script> 
import { mapGetters } from "vuex";
import Notification from '../../material/Notification.vue'
export default {
  components: {  Notification   },
  data: () => ({
    model: null,
    search: null,
    items: [],
    showNotification: false,
    userMenu: false,
  }),
  computed: {
    ...mapGetters({
      getdrawer: "app/getdrawer",
      getImageUrl: "app/getImageUrl",
      language: "app/getlang",
      getIsLoggedIn: "auth/getIsLoggedIn",
      getStatistics: "reports/getStatistics",
    }),
    responsive() {
      if (
        this.$vuetify.breakpoint.name == "sm" ||
        this.$vuetify.breakpoint.name == "xs"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    model(val) {
      if (val != null) {
        this.$router.push({ path: `/${this.$i18n.locale}${val.abbr}` });
      }
    },
    search(val) {
      if (val !== "") {
        this.items = [
          {
            name: this.$i18n.t("Core.drawer.Dashboard"),
            abbr: "/dashboard",
            id: 1,
          },
          {
            name: this.$i18n.t("projects.title"),
            abbr: "/projects",
            id: 1,
          },
          {
            name: this.$i18n.t("Core.drawer.questionsAndCoupons"),
            abbr: "/settings/questions-and-rewards",
            id: 1,
          },
          {
            name: this.$i18n.t("Core.drawer.surveys"),
            abbr: "/surveys",
            id: 1,
          },
          { name: this.$i18n.t("Core.drawer.Points"), abbr: "/settings/balance", id: 1 },
          {
            name: this.$i18n.t("Core.drawer.answers"),
            abbr: "/answers",
            id: 1,
          },
          { name: this.$i18n.t("Core.drawer.Chats"), abbr: "/chats", id: 1 },
          {
            name: this.$i18n.t("Core.drawer.Settings"),
            abbr: "/settings",
            id: 1,
          },
          {
            name: this.$i18n.t("questionsAndCoupons.choices_questions"),
            abbr: "/settings/questions-and-rewards/choices-questions",
            id: 1,
          },
          {
            name: this.$i18n.t("questionsAndCoupons.star_questions"),
            abbr: "/settings/questions-and-rewards/star-questions",
            id: 1,
          },
          {
            name: this.$i18n.t("setting.advanceSettings"),
            abbr: "/settings/advancesetting",
            id: 1,
          },
          {
            name: this.$i18n.t("setting.packageInfo"),
            abbr: "/settings/package",
            id: 1,
          },
          {
            name: this.$i18n.t("setting.personalInfo"),
            abbr: "/settings/storeinformation",
            id: 1,
          },
        ];
      } else {
        this.items = [];
      }
    },
  },

  mounted() {
  },
  methods: { 

    changeLanguage(lang) {
      //changing language by route

      this.$nextTick(() => {
        this.$nuxt.$loading.start();
        this.$router.push({
          path:
            "/" + lang + this.$route.path.substring(3, this.$route.path.length),
        });
        setTimeout(() => {
          this.$router.go()

        }, 100);
        setTimeout(() => {
          this.$nuxt.$loading.finish();
          this.showcontent = true;
        }, 2000);
      });
    },
    // open drawer on responsive
    onClickBtn() {
      this.setDrawer(!this.getdrawer);
    },
  },
};
</script>
