export default () => ({
  allQuestions: null,
  autoQuestions: null,
  oneQuestion: {},
  addDialog: false,
  deleteDialog: false,
  updateDialog: false,
  analysisQuestions:null,
  tempAnalysisQuestions:[]
});
