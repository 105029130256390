export default {
  setAllProjects: (state, data) => {
    state.allProjects = data.data.content;
  },
  setAllStoreProjects: (state, data) => {
    state.allStoreProjects = data.data.content;

  },
  setOneProject: (state, data) => {
    state.oneProject = data.content;
  }, 
  // incease total count of survey in project
  updateSurveyCount: (state, data) => {
    const index = state.allProjects.findIndex((item) => item.id === data.branch_id);
    if(index>-1){
      Object.assign( state.allProjects[index], {...state.allProjects[index],surveys_count:state.allProjects[index].surveys_count+1});
      
    }
    
  }, 
  // update project in list and close the dialog
  updateProject: (state, data) => {
    if (state.allProjects) {
      const index = state.allProjects.find((item) => item.id === data.id);
      if (index) {
        Object.assign(index, data);
        state.updateDialog = true;
      }
      state.oneProject = data;
    }
  }, 
  // delete project from list
  deleteProject: (state,data) => {
    var index = state.allProjects.findIndex((item) => item.id === data);
    if (index > -1) {
      state.allProjects.splice(index, 1);
    }
  }, 
  // change project state 
  setProjectState: (state, data) => {
          // disable/activate project
    if (data.type == 1) {
      state.oneProject.state = state.oneProject.state == 1 ? 2 : 1;
    }
          // hide/show project
    if (data.type == 2) {
      state.oneProject.is_show = state.oneProject.is_show == 1 ? 2 : 1;
    }
  },  
  setCopiedProject: (state,data) => {
    state.copiedProject=data
  },
};
