<template>
  <v-layout justify="center">
    <v-dialog persistent v-model="show" transition="dialog-transition">
     
      <v-layout  justify-center align-center>
        <!--begin::Card-->
        <v-card
          max-width="400"
          width="400"
          min-width="300"
          style="margin: 66px 18px 0px"
        >
          <!--begin::Header-->
          <v-card-title
            class="justify-content-end pa-0 "
            style="margin: 16px 14px 10px"
          >
              <v-btn text small @click="show = false" class="wameed-btn sm-icon-btn border-input bg-input  px-0">
                            <close-icon class="svg-font-sub my-1" style="
                        width: 12px;
                        height: 12px;
                      "/>
                        </v-btn>
          </v-card-title>
          <!--end::Header-->
          <!--begin::Body-->
          <v-layout
            justify-center
            align-center
            style="height: 100%; padding: 38px"
            class="feedback-modal py-0"
          >
            <v-flex style="max-width: 270px">
              <v-layout
                justify-center
                align-center
                column
                style="height: 100%"
                class=""
              >
                <div text class="   feedback-icon  bg-light-danger  rounded-6  text-center
                    d-flex
                    justify-center
                    align-center">
                  <delete-icon v-if="stop == 1" class="my-3 mx-auto svg-rect-danger" />
                  <stop-icon v-if="stop == 2" class="my-3 mx-auto svg-danger" />
                  <logout-icon v-if="stop == 3" class="my-3 mx-auto svg-danger" />
                  <info-icon v-if="stop == 4" class="my-3 mx-auto  svg-circle-danger svg-danger" />
                </div>

                <span
                  class="nodataTitle"
                  style="margin-top: 18px; margin-bottom: 15px; padding: 0px"
                >
                  {{ title }}
                </span>
                <span class="nodataSubTitle">
                  {{ subTitle }}
                </span>
                <v-layout justify-center style="gap: 14px" class="ma-5 ">
                  <v-btn
                    @click="emitAction"
                    text class="wameed-btn text-regular-13 text-danger btn-light-danger  w-50" style="
                      min-width: 124px !important;
                    ">
                    {{ btntitle }}
                  </v-btn>
                  <v-btn
                    text
                    @click="show = false"
                    class="wameed-btn text-regular-13 text-font-sub btn-light w-50" style="
                      min-width: 124px !important;
                    "
                  >
                    {{ $t('actions.cancel') }}
                  </v-btn>
                </v-layout>
              </v-layout>
            </v-flex>
          </v-layout>
          <!--end::Body-->
        </v-card>
        <!--end::Card-->
      </v-layout>
    </v-dialog>
  </v-layout>
</template>
<script>

export default {
  inheritsAttrs: false,

  props: {
    item: {
      type: String,
      default: '',
    },
    stop: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    btntitle: {
      type: String,
      default: '',
    },
    btnAction: {
      type: Boolean,
      default: null,
    },
    visible: {
      type: Boolean,
      default: null,
    },
  },
  components: {
    
  },
  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    emitAction() {
      this.$emit('deleteAction');
    },
  },
};
</script>  