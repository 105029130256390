var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "client-only",
    [
      _c(
        "v-app",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showcontent,
              expression: "showcontent",
            },
          ],
          class: _vm.$i18n.locale,
          attrs: { id: _vm.$i18n.locale },
        },
        [
          _c(
            "div",
            { attrs: { id: "main-cpanel-div" } },
            [
              _c("drawer-new"),
              _vm._v(" "),
              _c("toolbar"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "scroll",
                      rawName: "v-scroll",
                      value: _vm.onScroll,
                      expression: "onScroll",
                    },
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.fab,
                      expression: "fab",
                    },
                  ],
                  staticClass: "scroll-up-btn",
                  attrs: { dark: "", small: "", fixed: "", bottom: "" },
                  on: { click: _vm.toTop },
                },
                [
                  _c("v-icon", { staticStyle: { "font-size": "20px" } }, [
                    _vm._v("fa-chevron-up"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.getIsLoggedIn
                ? _c(
                    "v-lazy",
                    {
                      attrs: {
                        options: {
                          threshold: 0.5,
                        },
                        transition: "fade-transition",
                      },
                      model: {
                        value: _vm.isviewActive,
                        callback: function ($$v) {
                          _vm.isviewActive = $$v
                        },
                        expression: "isviewActive",
                      },
                    },
                    [_c("CoreControlView")],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("messages"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }