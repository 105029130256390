import Vue from 'vue';
export default {
  setDrawer: (state, property) => {
    state.drawer = property;
  },  
  SET_LANG(state, lang) {
    if (state.locales.includes(lang.locale)) {
      state.language = lang;
    }
  }, 
  successMessage: (state, data) => {
    Vue.notify({
      duration: 1000,
      speed: 400,
      type: 'success',
      group: 'successmesages',
      closeOnClick: true,
      text: 'successMessage.' + data,
    });

    state.messages = 'success';
  },
  failMessage: (state, data) => {
    Vue.notify({
      duration: 1000,
      speed: 400,
      type: 'error',
      group: 'failmessages',
      text: 'Error_Message.' + data,
      title: 'Error_Message.successMessage1',
    });

    state.messages = 'error';
  },
  loadingFinish: (state) => {
    state.loadingCounter = state.loadingCounter - 1;
    if (state.loadingCounter < 1) {
      state.loading = false;
    }
  },
  loadingStart: (state) => {
    state.loading = true;
    state.loadingCounter = state.loadingCounter + 1;
  },
};
