export default {
  setAllQuestion: (state, data) => {
    state.allQuestions = data.content;
  },
  setAutoQuestion: (state, data) => {
    state.autoQuestions = data.content;
  },
  setOneQuestion: (state, data) => {
    state.oneQuestion = data.content;
  },
  InsertQuestion: (state, data) => {
    if(state.allQuestions){
    state.allQuestions.unshift(data);
    state.addDialog = true;
    }
  },
  setAddDialog: (state) => {
    state.addDialog = false;
  },
  //  update the question with the new data and sets "oneQuestion" to the updated data
  UpdateQuestion: (state, data) => {
    if(state.allQuestions){
    const index = state.allQuestions.find((item) => item.id === data.id);
    if (index) {
      Object.assign(index, data);
   
    }
    state.oneQuestion = data;
    state.updateDialog = true;
  }
  },
  setUpdateDialog: (state) => {
    state.updateDialog = false;
  },
  // remove the question at that index from the array.
  deleteQuestion: (state, questionId) => {
    if(state.allQuestions){
      var index = state.allQuestions.findIndex((item) => item.id === questionId);
    if (index > -1) {
      state.allQuestions.splice(index, 1);
      state.deleteDialog = true;
    }
  }
  },
  setDeleteDialog: (state) => {
    state.deleteDialog = false;
  },
  setAnalysisQuestions: (state,data) => {
    state.analysisQuestions = data;
  }, 
  setTempAnalysisQuestions: (state,data) => {
    state.tempAnalysisQuestions = data;
  },
};
