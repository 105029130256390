var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "open-delay": 50000,
            transition: _vm.show
              ? "scroll-x-transition"
              : "scroll-x-reverse-transition",
            width: "280px",
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "ratingFilterDialog",
              attrs: { height: "100%", "min-height": "100vh", width: "280px" },
            },
            [
              _c(
                "v-layout",
                {
                  staticClass: "py-6 px-4",
                  attrs: { dark: "", color: "white", "align-center": "" },
                },
                [
                  _c(
                    "v-toolbar-title",
                    { staticClass: "text-medium-16 text-font-main" },
                    [_vm._v(_vm._s(_vm.$t("dashboard.notifications")))]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "wameed-btn sm-icon-btn border-input bg-input px-0",
                      attrs: { text: "", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [
                      _c("close-icon", {
                        staticClass: "svg-font-sub my-1",
                        staticStyle: { width: "12px", height: "12px" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  { staticStyle: { height: "85%", "overflow-y": "auto" } },
                  _vm._l(_vm.getNotifications, function (notification, i) {
                    return _c(
                      "div",
                      { key: i, attrs: { to: "/", text: "" } },
                      [
                        _c(
                          "v-layout",
                          { staticClass: "py-2 px-3" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "wameed-btn sm-icon-btn border-input bg-input text-center px-1",
                                class:
                                  notification.is_read == 2
                                    ? "svg-font-sub"
                                    : "svg-primary-600",
                                staticStyle: { height: "38px", width: "38px" },
                                attrs: { text: "", small: "" },
                              },
                              [
                                _c("notifications-icon", {
                                  staticClass: "text-center",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ma-1" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "text-medium-12 text-font-main",
                                  staticStyle: { "line-height": "12px" },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(notification.title) +
                                      "\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "text-regular-10 text-font-sub",
                                  staticStyle: { "line-height": "20px" },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(notification.message) +
                                      "\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "text-regular-8 text-font-sub" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.notificationTime(
                                        notification.created_at
                                      ) +
                                        "-" +
                                        _vm.notificationDate(
                                          notification.created_at
                                        )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }