export default {
  async loadAllCoupons({ commit }, data) {
    commit('app/loadingStart', null, { root: true });
    await this.$axios
      .get(
        `coupons?page=${data.page}&per_page=${data.per_page}&state=${data.state}&type=${data.type}`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setAllCoupons', respo.data);
        }
      });
  },
  async loadOneCoupons({ commit }, couponId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`/coupons/${couponId}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setOneCoupons', respo.data.data);
      }
    });
  },
  async saveNewCoupon({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`/coupons`, data).then((respo) => {
      if (respo.status === 200) {
      
          commit('InsertCoupon', respo.data.data);
        commit('app/successMessage', 'coupondded', { root: true });
      }
    });
  },
  async editOneCoupon({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`/coupons/${data.couponId}`, data.couponDetails)
      .then((respo) => {
        if (respo.status === 200) {
         commit('UpdateCoupon', respo.data.data.content);
          commit('app/successMessage', 'couponUpdated', { root: true });
        }
      });
  },
  async deleteOneCoupon({ commit }, couponId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.delete(`/coupons/${couponId}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('deleteCoupon', couponId);
        commit('app/successMessage', 'couponDeleted', { root: true });
      }
    });
  },
  async couponChangeState({ commit }, couponId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`/coupons/change-state/${couponId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit('UpdateCoupon', respo.data.data.content);
          commit(
            'app/successMessage',
            respo.data.data.content.state == 1
              ? 'couponIsActive'
              : 'couponIsDisabled',
            { root: true }
          );
        }
      });
  },
  async couponAddMultiple({ commit, dispatch }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .post(`/coupons/upload`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((respo) => {
        if (respo.status === 200) {
          commit('app/successMessage', 'couponsdded', { root: true });
          dispatch('loadAllCoupons', {
            page: 1,
            per_page: 20,
            state: '',
            type: '',
          });
        }
      });
  },
 
};
