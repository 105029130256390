export default {
  async loadDasboardStatisitcs({ commit }) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`dashboard-details`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setStatistics', respo.data);
      }
    });
  },

  async loadAllSubscriptionRecords({ commit }) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`store-bills`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setAllSubscriptionRecords', respo.data.data);
      }
    });
  },

  async loadMonthlyRating({ commit },filter) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`reviews/rate/monthly?month=${filter.month_id}&branch_id=${filter.branch_id}&survey_id=${filter.survey_id}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setMonthlyRatings', respo.data.data);
      }
    });
  },

  async loadDailyRating({ commit },filter) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`reviews/rate/daily?month=${filter.month_id}&branch_id=${filter.branch_id}&survey_id=${filter.survey_id}&day=${filter.day_id}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setDailyRatings', respo.data.data);
      }
    });
  },
  async loadQuestionRating({ commit },filter) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`reviews/rate/questions?question=${filter.question_id}&survey_id=${filter.survey_id}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setQuestionRatings', respo.data.data);
      }
    });
  },
  async loadSurveyRating({ commit },filter) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`reviews/rate/surveys?day=${filter.day_id}&branch_id=${filter.branch_id}&month=${filter.month_id}&year=${filter.year_id}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setSurveyRatings', respo.data.data);
      }
    });
  },
  async loadBranchRating({ commit },filter) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`reviews/rate/branches?day=${filter.day_id}&month=${filter.month_id}&year=${filter.year_id}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setBranchRatings', respo.data.data);
      }
    });
  },

};
