import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2a9670dc = () => interopDefault(import('../pages/callback/index.vue' /* webpackChunkName: "pages/callback/index" */))
const _1e65a876 = () => interopDefault(import('../pages/_lang/index.vue' /* webpackChunkName: "pages/_lang/index" */))
const _9fb8e982 = () => interopDefault(import('../pages/_lang/chats.vue' /* webpackChunkName: "pages/_lang/chats" */))
const _79d0d338 = () => interopDefault(import('../pages/_lang/dashboard.vue' /* webpackChunkName: "pages/_lang/dashboard" */))
const _308d6248 = () => interopDefault(import('../pages/_lang/help-center/index.vue' /* webpackChunkName: "pages/_lang/help-center/index" */))
const _2fe568e6 = () => interopDefault(import('../pages/_lang/login.vue' /* webpackChunkName: "pages/_lang/login" */))
const _641a052c = () => interopDefault(import('../pages/_lang/privacy.vue' /* webpackChunkName: "pages/_lang/privacy" */))
const _47fdefc6 = () => interopDefault(import('../pages/_lang/ratings/index.vue' /* webpackChunkName: "pages/_lang/ratings/index" */))
const _69184492 = () => interopDefault(import('../pages/_lang/restore-account.vue' /* webpackChunkName: "pages/_lang/restore-account" */))
const _53e49af2 = () => interopDefault(import('../pages/_lang/settings/index.vue' /* webpackChunkName: "pages/_lang/settings/index" */))
const _46702178 = () => interopDefault(import('../pages/_lang/Signup.vue' /* webpackChunkName: "pages/_lang/Signup" */))
const _20347070 = () => interopDefault(import('../pages/_lang/store/index.vue' /* webpackChunkName: "pages/_lang/store/index" */))
const _8f01eb80 = () => interopDefault(import('../pages/_lang/surveys/index.vue' /* webpackChunkName: "pages/_lang/surveys/index" */))
const _6f3932aa = () => interopDefault(import('../pages/_lang/terms.vue' /* webpackChunkName: "pages/_lang/terms" */))
const _f548db36 = () => interopDefault(import('../pages/_lang/verify-account.vue' /* webpackChunkName: "pages/_lang/verify-account" */))
const _670a4e7e = () => interopDefault(import('../pages/_lang/analysis/google-reviews/index.vue' /* webpackChunkName: "pages/_lang/analysis/google-reviews/index" */))
const _41ffac98 = () => interopDefault(import('../pages/_lang/analysis/reports.vue' /* webpackChunkName: "pages/_lang/analysis/reports" */))
const _97e3b0c4 = () => interopDefault(import('../pages/_lang/analysis/sentiment-anlysis.vue' /* webpackChunkName: "pages/_lang/analysis/sentiment-anlysis" */))
const _6b01b470 = () => interopDefault(import('../pages/_lang/analysis/survey-analysis.vue' /* webpackChunkName: "pages/_lang/analysis/survey-analysis" */))
const _69a3a596 = () => interopDefault(import('../pages/_lang/Projects/create.vue' /* webpackChunkName: "pages/_lang/Projects/create" */))
const _0bf2b37e = () => interopDefault(import('../pages/_lang/settings/advanceSetting.vue' /* webpackChunkName: "pages/_lang/settings/advanceSetting" */))
const _776b9cac = () => interopDefault(import('../pages/_lang/settings/analysis-questions.vue' /* webpackChunkName: "pages/_lang/settings/analysis-questions" */))
const _83a06c08 = () => interopDefault(import('../pages/_lang/settings/balance.vue' /* webpackChunkName: "pages/_lang/settings/balance" */))
const _1f406c69 = () => interopDefault(import('../pages/_lang/settings/package/index.vue' /* webpackChunkName: "pages/_lang/settings/package/index" */))
const _7f103cd1 = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/index.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/index" */))
const _ac1e55ca = () => interopDefault(import('../pages/_lang/settings/storeInformation.vue' /* webpackChunkName: "pages/_lang/settings/storeInformation" */))
const _53b33806 = () => interopDefault(import('../pages/_lang/settings/system-users/index.vue' /* webpackChunkName: "pages/_lang/settings/system-users/index" */))
const _1aaec555 = () => interopDefault(import('../pages/_lang/store/review/index.vue' /* webpackChunkName: "pages/_lang/store/review/index" */))
const _7f16887e = () => interopDefault(import('../pages/_lang/surveys/create.vue' /* webpackChunkName: "pages/_lang/surveys/create" */))
const _d61266fa = () => interopDefault(import('../pages/_lang/analysis/google-reviews/update-branches.vue' /* webpackChunkName: "pages/_lang/analysis/google-reviews/update-branches" */))
const _e16cb3ce = () => interopDefault(import('../pages/_lang/settings/package/records.vue' /* webpackChunkName: "pages/_lang/settings/package/records" */))
const _1151acea = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/calender-questions.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/calender-questions" */))
const _19ca149e = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/choices-questions.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/choices-questions" */))
const _7e7bab4f = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/coupons/index.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/coupons/index" */))
const _731e0334 = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/csat-questions.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/csat-questions" */))
const _f6042a20 = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/nps-questions.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/nps-questions" */))
const _bd82939c = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/performance-questions/index.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/performance-questions/index" */))
const _6c93fe9c = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/slider-questions.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/slider-questions" */))
const _3e8d90ab = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/smiley-faces-questions.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/smiley-faces-questions" */))
const _592f2ea3 = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/star-questions.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/star-questions" */))
const _5b9ed23e = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/text-questions.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/text-questions" */))
const _1e097de5 = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/toggle-questions.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/toggle-questions" */))
const _245e1dd2 = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/upload-questions.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/upload-questions" */))
const _1dab5db1 = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/voice-questions.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/voice-questions" */))
const _262685e7 = () => interopDefault(import('../pages/_lang/settings/system-users/create-user.vue' /* webpackChunkName: "pages/_lang/settings/system-users/create-user" */))
const _1c1a74eb = () => interopDefault(import('../pages/_lang/settings/system-users/roles/index.vue' /* webpackChunkName: "pages/_lang/settings/system-users/roles/index" */))
const _654a6574 = () => interopDefault(import('../pages/_lang/store/review/success.vue' /* webpackChunkName: "pages/_lang/store/review/success" */))
const _a3674a68 = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/performance-questions/create.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/performance-questions/create" */))
const _92501900 = () => interopDefault(import('../pages/_lang/settings/system-users/roles/create-role.vue' /* webpackChunkName: "pages/_lang/settings/system-users/roles/create-role" */))
const _4a81a316 = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/coupons/_details.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/coupons/_details" */))
const _f4d860e4 = () => interopDefault(import('../pages/_lang/settings/questions-and-rewards/performance-questions/_id/update.vue' /* webpackChunkName: "pages/_lang/settings/questions-and-rewards/performance-questions/_id/update" */))
const _7edf6b8c = () => interopDefault(import('../pages/_lang/settings/system-users/roles/_role/update.vue' /* webpackChunkName: "pages/_lang/settings/system-users/roles/_role/update" */))
const _a34f7f1a = () => interopDefault(import('../pages/_lang/settings/system-users/_user/update.vue' /* webpackChunkName: "pages/_lang/settings/system-users/_user/update" */))
const _533bcbe1 = () => interopDefault(import('../pages/_lang/email-verification/_token.vue' /* webpackChunkName: "pages/_lang/email-verification/_token" */))
const _65fd5d7d = () => interopDefault(import('../pages/_lang/help-center/_details.vue' /* webpackChunkName: "pages/_lang/help-center/_details" */))
const _5bb21114 = () => interopDefault(import('../pages/_lang/Projects/_id/index.vue' /* webpackChunkName: "pages/_lang/Projects/_id/index" */))
const _3b53c869 = () => interopDefault(import('../pages/_lang/reset-password/_restore.vue' /* webpackChunkName: "pages/_lang/reset-password/_restore" */))
const _6849052b = () => interopDefault(import('../pages/_lang/surveys/_id/index.vue' /* webpackChunkName: "pages/_lang/surveys/_id/index" */))
const _14cd14b7 = () => interopDefault(import('../pages/_lang/Projects/_id/answers.vue' /* webpackChunkName: "pages/_lang/Projects/_id/answers" */))
const _41f77512 = () => interopDefault(import('../pages/_lang/Projects/_id/update.vue' /* webpackChunkName: "pages/_lang/Projects/_id/update" */))
const _654bd440 = () => interopDefault(import('../pages/_lang/surveys/_id/update.vue' /* webpackChunkName: "pages/_lang/surveys/_id/update" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/callback",
    component: _2a9670dc,
    caseSensitive: false,
    name: "callback"
  }, {
    path: "/:lang",
    component: _1e65a876,
    caseSensitive: false,
    name: "lang"
  }, {
    path: "/:lang?/chats",
    component: _9fb8e982,
    caseSensitive: false,
    name: "lang-chats"
  }, {
    path: "/:lang?/dashboard",
    component: _79d0d338,
    caseSensitive: false,
    name: "lang-dashboard"
  }, {
    path: "/:lang?/help-center",
    component: _308d6248,
    caseSensitive: false,
    name: "lang-help-center"
  }, {
    path: "/:lang?/login",
    component: _2fe568e6,
    caseSensitive: false,
    name: "lang-login"
  }, {
    path: "/:lang?/privacy",
    component: _641a052c,
    caseSensitive: false,
    name: "lang-privacy"
  }, {
    path: "/:lang?/ratings",
    component: _47fdefc6,
    caseSensitive: false,
    name: "lang-ratings"
  }, {
    path: "/:lang?/restore-account",
    component: _69184492,
    caseSensitive: false,
    name: "lang-restore-account"
  }, {
    path: "/:lang?/settings",
    component: _53e49af2,
    caseSensitive: false,
    name: "lang-settings"
  }, {
    path: "/:lang?/Signup",
    component: _46702178,
    caseSensitive: false,
    name: "lang-Signup"
  }, {
    path: "/:lang?/store",
    component: _20347070,
    caseSensitive: false,
    name: "lang-store"
  }, {
    path: "/:lang?/surveys",
    component: _8f01eb80,
    caseSensitive: false,
    name: "lang-surveys"
  }, {
    path: "/:lang?/terms",
    component: _6f3932aa,
    caseSensitive: false,
    name: "lang-terms"
  }, {
    path: "/:lang?/verify-account",
    component: _f548db36,
    caseSensitive: false,
    name: "lang-verify-account"
  }, {
    path: "/:lang?/analysis/google-reviews",
    component: _670a4e7e,
    caseSensitive: false,
    name: "lang-analysis-google-reviews"
  }, {
    path: "/:lang?/analysis/reports",
    component: _41ffac98,
    caseSensitive: false,
    name: "lang-analysis-reports"
  }, {
    path: "/:lang?/analysis/sentiment-anlysis",
    component: _97e3b0c4,
    caseSensitive: false,
    name: "lang-analysis-sentiment-anlysis"
  }, {
    path: "/:lang?/analysis/survey-analysis",
    component: _6b01b470,
    caseSensitive: false,
    name: "lang-analysis-survey-analysis"
  }, {
    path: "/:lang?/Projects/create",
    component: _69a3a596,
    caseSensitive: false,
    name: "lang-Projects-create"
  }, {
    path: "/:lang?/settings/advanceSetting",
    component: _0bf2b37e,
    caseSensitive: false,
    name: "lang-settings-advanceSetting"
  }, {
    path: "/:lang?/settings/analysis-questions",
    component: _776b9cac,
    caseSensitive: false,
    name: "lang-settings-analysis-questions"
  }, {
    path: "/:lang?/settings/balance",
    component: _83a06c08,
    caseSensitive: false,
    name: "lang-settings-balance"
  }, {
    path: "/:lang?/settings/package",
    component: _1f406c69,
    caseSensitive: false,
    name: "lang-settings-package"
  }, {
    path: "/:lang?/settings/questions-and-rewards",
    component: _7f103cd1,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards"
  }, {
    path: "/:lang?/settings/storeInformation",
    component: _ac1e55ca,
    caseSensitive: false,
    name: "lang-settings-storeInformation"
  }, {
    path: "/:lang?/settings/system-users",
    component: _53b33806,
    caseSensitive: false,
    name: "lang-settings-system-users"
  }, {
    path: "/:lang?/store/review",
    component: _1aaec555,
    caseSensitive: false,
    name: "lang-store-review"
  }, {
    path: "/:lang?/surveys/create",
    component: _7f16887e,
    caseSensitive: false,
    name: "lang-surveys-create"
  }, {
    path: "/:lang?/analysis/google-reviews/update-branches",
    component: _d61266fa,
    caseSensitive: false,
    name: "lang-analysis-google-reviews-update-branches"
  }, {
    path: "/:lang?/settings/package/records",
    component: _e16cb3ce,
    caseSensitive: false,
    name: "lang-settings-package-records"
  }, {
    path: "/:lang?/settings/questions-and-rewards/calender-questions",
    component: _1151acea,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-calender-questions"
  }, {
    path: "/:lang?/settings/questions-and-rewards/choices-questions",
    component: _19ca149e,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-choices-questions"
  }, {
    path: "/:lang?/settings/questions-and-rewards/coupons",
    component: _7e7bab4f,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-coupons"
  }, {
    path: "/:lang?/settings/questions-and-rewards/csat-questions",
    component: _731e0334,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-csat-questions"
  }, {
    path: "/:lang?/settings/questions-and-rewards/nps-questions",
    component: _f6042a20,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-nps-questions"
  }, {
    path: "/:lang?/settings/questions-and-rewards/performance-questions",
    component: _bd82939c,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-performance-questions"
  }, {
    path: "/:lang?/settings/questions-and-rewards/slider-questions",
    component: _6c93fe9c,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-slider-questions"
  }, {
    path: "/:lang?/settings/questions-and-rewards/smiley-faces-questions",
    component: _3e8d90ab,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-smiley-faces-questions"
  }, {
    path: "/:lang?/settings/questions-and-rewards/star-questions",
    component: _592f2ea3,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-star-questions"
  }, {
    path: "/:lang?/settings/questions-and-rewards/text-questions",
    component: _5b9ed23e,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-text-questions"
  }, {
    path: "/:lang?/settings/questions-and-rewards/toggle-questions",
    component: _1e097de5,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-toggle-questions"
  }, {
    path: "/:lang?/settings/questions-and-rewards/upload-questions",
    component: _245e1dd2,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-upload-questions"
  }, {
    path: "/:lang?/settings/questions-and-rewards/voice-questions",
    component: _1dab5db1,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-voice-questions"
  }, {
    path: "/:lang?/settings/system-users/create-user",
    component: _262685e7,
    caseSensitive: false,
    name: "lang-settings-system-users-create-user"
  }, {
    path: "/:lang?/settings/system-users/roles",
    component: _1c1a74eb,
    caseSensitive: false,
    name: "lang-settings-system-users-roles"
  }, {
    path: "/:lang?/store/review/success",
    component: _654a6574,
    caseSensitive: false,
    name: "lang-store-review-success"
  }, {
    path: "/:lang?/settings/questions-and-rewards/performance-questions/create",
    component: _a3674a68,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-performance-questions-create"
  }, {
    path: "/:lang?/settings/system-users/roles/create-role",
    component: _92501900,
    caseSensitive: false,
    name: "lang-settings-system-users-roles-create-role"
  }, {
    path: "/:lang?/settings/questions-and-rewards/coupons/:details?",
    component: _4a81a316,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-coupons-details"
  }, {
    path: "/:lang?/settings/questions-and-rewards/performance-questions/:id?/update",
    component: _f4d860e4,
    caseSensitive: false,
    name: "lang-settings-questions-and-rewards-performance-questions-id-update"
  }, {
    path: "/:lang?/settings/system-users/roles/:role/update",
    component: _7edf6b8c,
    caseSensitive: false,
    name: "lang-settings-system-users-roles-role-update"
  }, {
    path: "/:lang?/settings/system-users/:user?/update",
    component: _a34f7f1a,
    caseSensitive: false,
    name: "lang-settings-system-users-user-update"
  }, {
    path: "/:lang?/email-verification/:token?",
    component: _533bcbe1,
    caseSensitive: false,
    name: "lang-email-verification-token"
  }, {
    path: "/:lang?/help-center/:details?",
    component: _65fd5d7d,
    caseSensitive: false,
    name: "lang-help-center-details"
  }, {
    path: "/:lang?/Projects/:id",
    component: _5bb21114,
    caseSensitive: false,
    name: "lang-Projects-id"
  }, {
    path: "/:lang?/reset-password/:restore?",
    component: _3b53c869,
    caseSensitive: false,
    name: "lang-reset-password-restore"
  }, {
    path: "/:lang?/surveys/:id",
    component: _6849052b,
    caseSensitive: false,
    name: "lang-surveys-id"
  }, {
    path: "/:lang?/Projects/:id?/answers",
    component: _14cd14b7,
    caseSensitive: false,
    name: "lang-Projects-id-answers"
  }, {
    path: "/:lang?/Projects/:id?/update",
    component: _41f77512,
    caseSensitive: false,
    name: "lang-Projects-id-update"
  }, {
    path: "/:lang?/surveys/:id/update",
    component: _654bd440,
    caseSensitive: false,
    name: "lang-surveys-id-update"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
