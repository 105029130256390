export default {
  // login with email and phone 
  async tryLoginAction({ commit, dispatch }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`login`, data).then((respo) => {
      if (respo.status === 200) {
         var responseData = respo.data.data;
        if (data.type == 'email') {
      dispatch('setToken',responseData);
         
        } else {
          // if phone then set phone and redirect to verify-account page
          commit('setPhoneVerification', responseData);
         commit('setOtpVerification', null);

          this.$router.push({
            path: '/' + this.app.i18n.locale + '/verify-account',
          });
        }
      }
    });
  },
  //    set authentication token , load user data and  redirect
  async setToken({ commit, dispatch }, data){
    this.$axios.defaults.headers.common[
      'X-Authorization'
    ] = `Bearer ${data.token}`;
    this.$cookies.set('login-data', JSON.stringify(data), {
  
      path: '/', 
    });
    let loginData = this.$cookies.get('login-data', { parseJSON: true });
    if (loginData) {
      if (
        this.$axios.defaults.headers.common['X-Authorization'] === undefined
      ) {
        this.$axios.defaults.headers.common['X-Authorization'] =
          'Bearer ' + loginData.token;
      }
      dispatch('loadUserDetail');
      this.$router.go();
    }
  },
  // login with google
  async googleLogin({ commit, dispatch }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`callback/google`, data).then((respo) => {
      if (respo.status === 200) {
        var responseData = respo.data.data;
        dispatch('setToken',responseData);

      }
    });
  },
  // login with apple
  async appleLogin({ commit, dispatch }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`callback/apple`, data).then((respo) => {
      if (respo.status === 200) {
        var responseData = respo.data.data;
        dispatch('setToken',responseData);

      }
    });
  },

  // check if user is logged in and load data if not exist
  async checkLoggedIn({ state, dispatch }) {
    let loginData = this.$cookies.get('login-data', { parseJSON: true });
    if (loginData) {
      if (this.$axios.defaults.headers.common['X-Authorization'] === undefined) {
        this.$axios.defaults.headers.common['X-Authorization'] =
          'Bearer ' + loginData.token;
      }
      if (!state.isLoggedIn) {
        await dispatch('loadUserDetail');
      }

    }
  },
  // logout and remove cookies
  async logout({ commit }) {
    this.$cookies.removeAll();

    this.$cookies.remove('login-data', {
      domain: process.env.cookieDomain,
      path: '/',
      secure: true,
      sameSite: true,
    });
    this.$cookies.remove('login-data', {
      domain: process.env.cookieDomain,
      path: '/en',
      secure: true,
      sameSite: true,
    });
    this.$cookies.remove('login-data', {
      domain: process.env.cookieDomain,
      path: '/ar',
      secure: true,
      sameSite: true,
    });
    let loginData = this.$cookies.get('login-data', { parseJSON: true });
    if (!loginData) {
      this.$axios.defaults.headers.common['X-Authorization'] = undefined;

      commit('setLoggedOutuser');
      this.$router.push({ path: '/' + this.app.i18n.locale + '/login' });
      setTimeout(() => {
        this.$router.go();
      }, 500);
    }
  },

  // create new account with email
  async registerStore({ commit, dispatch }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .post(`register`, data.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((respo) => {
        if (respo.status === 200) {
          var responseData = respo.data.data;
        dispatch('setToken',responseData);

          
        }
      });
  },
  // send forgot password link
  async forgot_password({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    return await this.$axios.post(`forgot-password`, data).then((respo) => {
 
      if (respo.status === 200) {
        commit('setCheckEmail', true);
      }
    });
  }, 

  // check veritied otp
  async otp_verification({ commit, dispatch }, data) {
    commit('app/loadingStart', null, { root: true });

    return await this.$axios
      .post(`check-verified`, data)
      .then((respo) => {
       
        if (respo.status === 200) {
          // set the token if verified
          commit('setOtpVerification', 1);
          var responseData = respo.data.data;
          dispatch('setToken',responseData);
        
        }
      })
      .catch((error) => {
        // set the otp verification if it is an error
        if (error.response && error.response.status == 400) {
            if (error.response.data.data.code == '01_06') {
            commit('setOtpVerification', 2);
          }
        }
        throw error;
      });
  },
  // reset password and redirect to login
  async reset_password({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .post(`reset-password`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('app/successMessage', 'PasswordResetSuccess', {
            root: true,
          });
          this.$router.push({ path: '/' + this.app.i18n.locale + '/login' });
         
        }
      })
      .catch((error) => {
        if (error.response.status == 400) {
          if (error.response.data.data.code == '01_07') {
            this.$router.push({
              path: '/' + this.app.i18n.locale + '/restore-account',
            });
          }
        }
        throw error;
      });
  }, 
  
  async updateStoreDetails({ commit }, data) {
    commit('app/loadingStart', null, { root: true });
    await this.$axios.post(`store/${data.id}`, data.details).then((respo) => {
      if (respo.status === 200) {
          
        commit('storeDetailsUpdate', respo.data.data.content);
      }
    });
  }, 
  async changeStorePassword({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`change-password`, data).then((respo) => {
      if (respo.status === 200) {
        commit('updatePassword');
      }
    });
  },
  async loadUserDetail({ commit }) {
    commit('app/loadingStart', null, { root: true });

    return await this.$axios.get(`auth-store`, null).then((respo) => {
      if (respo.status === 200) {
      
        commit('setLoggedInuser', respo.data.data.content);
        
        return respo.data.data.content;
      }
    });
  },
  // resend the email verification token
  async resendUrl({ commit }) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`resend-email-verified`).then((respo) => {
      if (respo.status === 200) {
        //  
      }
    });
  },
  // check if the valid token 
  async CheckValidToken({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`check-link-verified`, data).then((respo) => {
      if (respo.status === 200) {
        //  
        this.$router.push({
          path: '/' + this.app.i18n.locale + '/dashboard',
        });
      }
    }).catch((error) => {
      if (error.response.status == 400) {
        if (error.response.data.data.code == '01_07') {
        
          setTimeout(() => {
            this.$router.push({
              path: '/' + this.app.i18n.locale + '/dashboard',
            });
          }, 500);

         
        }
      }
      throw error;
    });
  }
};
