export default {
  getAllReviews(state) {
    return state.allReviews;
  },
  getBranches(state) {
    return state.branches;
  },
  getGoogleReviews(state) {
    return state.googleReviews;
  },
  
};
