<template>
  <div>
    <section id="contact-us-section">
      <div class="flex-layout">
        <div class=" mx-auto text-area text-center">
          <v-chip>
            {{ $t('homePage.contactUs') }}
          </v-chip>
          <h3 class="text-title " v-html="$t('homePage.needHelp')"></h3>
          <v-form @submit.prevent="sendMessage()" ref="form" v-model="valid" action="">
            <v-layout wrap align-center class="form-content">
              <v-flex>
                <input v-model="name" :placeholder="$t('homePage.name')" type="text" name="" id="" />

              </v-flex>
              <v-flex>
                <input v-model="email" :placeholder="$t('homePage.email')" isMobile type="email" name="" id="" />

              </v-flex>
              <v-flex lg12 md12 sm12 xs12>
                <textarea v-model="message" :placeholder="$t('homePage.message')" name="" id="" cols="30" rows="8"
                  style="resize: none">
                        </textarea>
              </v-flex>
              <v-btn type="submit" :disabled="!message || !email || !name" color="white"
                class="rounded-12 text-medium-16  text-main ">
                {{ $t("homePage.sendNow") }}
                <arrow-left />
              </v-btn>
            </v-layout>
          </v-form>

        </div>
      </div>
    </section>
    <footer>
      <div class="footer main-layout">
        <div class="footer-logo px-2">
          <NuxtLink :to="`/${$i18n.locale}/`" aria-label="Home">
            <logo-white class="logo" />
          </NuxtLink>
          <p class="text-regular-18 text-white " v-html="$t('homePage.footer_content')">
          </p>
        </div>

        <div id="main-links" class="list-div">
          <ul class="main-list">
            <li>
              <a :href="`/${$i18n.locale}/#`" aria-label="Home"> {{ $t("homePage.home") }} </a>
            </li>
            <li>
              <NuxtLink :to="`/${$i18n.locale}/#about-section`">{{
                $t("homePage.who_we_are")
              }}</NuxtLink>
            </li>
            <li>
              <NuxtLink :to="`/${$i18n.locale}/packages`">
                {{ $t("homePage.packages") }}
              </NuxtLink>
            </li>

          </ul>
          <ul class="main-list">
            <li>
              <NuxtLink :to="`/${$i18n.locale}/#questions-section`">{{
                $t("homePage.faq")
              }}</NuxtLink>
            </li>
            <li>
              <NuxtLink :to="`/${$i18n.locale}/privacy`">
                {{ $t("homePage.privacy") }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="`/${$i18n.locale}/terms`">
                {{ $t("homePage.termsandCondations") }}
              </NuxtLink>
            </li>
          </ul>
          <ul class="main-list">
            <li>
              <NuxtLink id="contact-text" :to="`/${$i18n.locale}/#contact-us-section`">{{
                $t("homePage.contactUs")
              }}</NuxtLink>
            </li>
            <li>
              <a href="mailto:info@feedback.com" aria-label="mail us" class="feedback-email">
                {{ 'info@feedback.sa' }}
              </a>
            </li>
            <li id="social-links">
              <a href="https://www.instagram.com/feedback.saudi/" aria-label="instagram">
            <span class="fab fa-instagram"></span>
            </a>
            <a href="https://twitter.com/feedbacksaudi?s=11" aria-label="twitter">
              <span class="fab fa-twitter"></span>
            </a>

            <a href="https://www.facebook.com/Feedbacksa-112378451229741" aria-label="facebook">
              <span class="fab fa-facebook-f"></span>
            </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
    <div style="background-color: #fff">
    <v-layout class="  mt-auto  py-5" justify-center align-center>
      <div class="text-center d-flex justify-content-end  ">
        <NuxtLink :to="`/${$i18n.locale}/`" style="text-decoration: none">
          <span class="text-medium-16 text-font-main">
            {{ $t('branches.all_rights') }}</span>
          <span class="text-medium-16 text-font-sub text-primary-600">
            {{ $t('LogIn.org') }}</span>
        </NuxtLink>
      </div>
    </v-layout>
  </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  head() {
    return {

    };
  },
  data: () => ({
    valid: true,
    name: "",
    email: "",
    message: "",
  }),
  computed: {
  },
  mounted() {
    this.$gsap
      .timeline({
        scrollTrigger: {
          trigger: "#contact-us-section",
          start: "top bottom",
          end: "+=100",
          // toggleActions: "restart",
        }
      })

      .fromTo("#contact-us-section .v-chip", {
        duration: 0,
        x: -10,
        opacity: 0,
        ease: "power2.easeInOut",

      }, {
        opacity: 1,

      })
      .from("#contact-us-section .text-title ", {
        duration: 0.2,
        opacity: 0,
        x: -10,
      })
      .from("#contact-us-section input", {
        duration: 0.2,
        opacity: 0,
        x: -10,
      })
      .from("#contact-us-section textarea", {
        duration: 0.2,
        opacity: 0,
        x: -10,
      })
      .fromTo("#contact-us-section .v-btn", {
        duration: 0.2,
        opacity: 0,
        x: -10,
      }, {
        opacity: 1,

      });

    this.$gsap.timeline({
      scrollTrigger: {
        trigger: "footer",
        start: "top bottom",
        end: "+=100",
        // toggleActions: "restart",
      }
    }).from("footer .logo", {
      duration: 1,
      y: -10,
      opacity: 0,
      ease: "power2.easeInOut",
    }).from("footer p", {
      duration: 0.6,
      y: -10,
      opacity: 0,
      ease: "power2.easeInOut",
    }).from("footer #main-links .main-list li ", {
      duration: 0.3,
      opacity: 0,
      x: -10,
      stagger: 0.2,

    }).from("footer #social-links a ", {
      duration: 0.2,
      opacity: 0,
      x: -10,
      stagger: 0.2,
    });
  },
  methods: {
    ...mapActions({ contactUS: "shop/contactUS" }),
    ...mapMutations({
      failMessage: "app/failMessage",
    }),

    changeLanguage(lang) {
      //changing language by route
      this.$router.push({
        path:
          "/" + lang + this.$route.path.substring(3, this.$route.path.length),
      });
      setTimeout(() => {
          this.$router.go()

        }, 100);
      this.$nextTick(() => {
        this.$nuxt.$loading.start();
        setTimeout(() => {
          this.$nuxt.$loading.finish();
          this.showcontent = true;
        }, 2000);
      });
    },
    // sending message
    sendMessage() {
      if (this.name.length < 1) {
        return this.failMessage("YourNameMustBeValid");
      } else if (this.email.length < 1 || !this.email.match(/.+@.+\..+/)) {
        return this.failMessage("EmailValidation");
      } else if (this.message.length < 1) {
        return this.failMessage("messageLength");
      } else {
        this.contactUS({
          name: this.name,
          email: this.email,
          message: this.message,
        }).then(() => {
          this.name = null
          this.email = null
          this.message = null
        })
      }
    },
  },
};
</script>
