<template>
  <DialogLayout :visible="visible" @close="show = false" @submit="updateSubinfo()" :title="$t('setting.complete_info')"
    :btn-title="$t('actions._save')">
    <template #default>
      <v-layout wrap>
        <!-- warning for account name that it should be changed -->
        <v-layout align-center justify-space-between class="bg-info-50 wrap border-info-600 rounded-8 mb-6 py-4 mx-6"
          style="gap: 8px;">

          <v-layout align-center class=" text-regular-14 mx-2">
            <warning2-icon class="svg-info mx-2" />
            <div>
              <p class="text-medium-14 text-font-main  my-0">
                {{ $t('setting.account_name_info') }}
              </p>
            </div>
          </v-layout>


        </v-layout>
        <v-flex lg12 md12 xs12 sm12 style="padding: 0 24px">
          <text-field :label="$t('setting.account_name')" required classes="controlPanel" v-model="name" />
        </v-flex> 
      </v-layout>

    </template>

  </DialogLayout>
</template>
<script>


import TextField from '../../material/TextField.vue';

import DialogLayout from '../../material/DialogLayout.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  inheritsAttrs: false,
  head() {
    return {
      script: [],

    };
  },

  props: {

    visible: {
      type: Boolean,
      default: null,
    },
  },
  components: {

    DialogLayout,
    TextField,
  },
  data: () => ({

    name: '',

  }),
  computed: {
    ...mapGetters({
      getIsLoggedIn: 'auth/getIsLoggedIn',
    }),

    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    getIsLoggedIn: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.name = val.name
        }

      }
    },

  },
  methods: {
    ...mapActions({ updateStoreDetails: 'auth/updateStoreDetails' }),

    // update the name of the store
    updateSubinfo() {
      let formData = new FormData();
      formData.append('name', this.name);
      formData.append('email', this.getIsLoggedIn.email??'');
      formData.append('password', '');
      formData.append('password_confirmation', '');
      formData.append('main_address', this.getIsLoggedIn.city ?? '');
      formData.append(
        'description',
        this.getIsLoggedIn.description ? this.getIsLoggedIn.description : ''
      );
      formData.append(
        'website_url',
        this.getIsLoggedIn.website_url ? this.getIsLoggedIn.website_url : ''
      );
      formData.append(
        'mobile_number',
        this.getIsLoggedIn.mobile_number ?? ''
      );
      formData.append(
        'landline_phone',
        this.getIsLoggedIn.landline_phone
          ? this.getIsLoggedIn.landline_phone
          : ''
      );
      formData.append(
        'facebook_url',
        this.getIsLoggedIn.facebook_url ? this.getIsLoggedIn.facebook_url : ''
      );
      formData.append(
        'twitter_url',
        this.getIsLoggedIn.twitter_url ? this.getIsLoggedIn.twitter_url : ''
      );
      formData.append(
        'instagram_url',
        this.getIsLoggedIn.instagram_url ? this.getIsLoggedIn.instagram_url : ''
      );
      formData.append(
        'youtube_url',
        this.getIsLoggedIn.youtube_url ? this.getIsLoggedIn.youtube_url : ''
      );
      formData.append(
        'linkedin_url',
        this.getIsLoggedIn.linkedin_url ? this.getIsLoggedIn.linkedin_url : ''
      );
      formData.append(
        'tiktok_url',
        this.getIsLoggedIn.tiktok_url ? this.getIsLoggedIn.tiktok_url : ''
      );
      formData.append(
        'media',
        this.FileToUpload == null ? '' : this.FileToUpload
      );
      formData.append('_method', 'put');
      this.updateStoreDetails({
        id: this.getIsLoggedIn.id,
        details: formData,
      }).then(() => {
        this.show = false
      });
    },
  },
};
</script>