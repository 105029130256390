<template>
  <div>
    <client-only>
      <notifications
        :width="messagewith()"
        animation-name="v-fade"
        group="failmessages"
        :position="$i18n.locale === 'ar' ? 'bottom left' : 'bottom right'"
      >
        <template slot="body" slot-scope="props">
          <v-alert class="px-0 py-0 mx-3 FailMessages">
            <v-layout
              style="
                width: 100% !important;
                justify-content: space-between;
                height: 100%;
                align-items: center;
              "
            >
              <span class="" style="margin: auto 18px">
                {{ $t(props.item.text) }}</span
              >
              <v-btn
              @click="props.close"
                text
                small
                class="rounded-6 px-0"
                style="margin: 12px"
              >
                <close-icon  class="my-1" />
              </v-btn>
            </v-layout>
          </v-alert>
        </template>
      </notifications>
    </client-only>
    <client-only>
      <notifications
        :width="messagewith()"
        group="successmesages"
        :position="$i18n.locale === 'ar' ? 'bottom left' : 'bottom right'"
      >
        <template slot="body" slot-scope="props">
          <v-alert class="mx-3 px-0 py-0 SuccessMesages">
            <v-layout
              style="
                width: 100% !important;
                justify-content: space-between;
                height: 100%;
                align-items: center;
              "
            >
              <span style="margin: auto 18px"> {{ $t(props.item.text) }}</span>
              <v-btn
              @click="props.close"
                text
                small
                class="rounded-6 px-0"
                style="margin: 12px"
              >
                <close-icon  class="my-1" />
              </v-btn>
            </v-layout>
          </v-alert>
        </template>
      </notifications>
    </client-only>
  </div>
</template>

<script>

export default {
  components: {  },
  data() {
    return {
      autherror: false,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    messagewith() {
      if (
        this.$vuetify.breakpoint.name == "sm" ||
        this.$vuetify.breakpoint.name == "xs"
      ){
       return '90%'
      } else {
      return '40%';
      }
    },
  },
};
</script>
