<template>
  <!--begin::Header-->
  <div class="user-footer-bar" style="background-color: #fff">
    <v-layout class="  mt-auto  py-5" justify-center align-center>
      <div class="text-center d-flex justify-content-end  ">
        <NuxtLink :to="`/${$i18n.locale}/`" style="text-decoration: none">
          <span class="text-medium-16 text-font-main">
            {{ $t('branches.all_rights') }}</span>
          <span class="text-medium-16 text-font-sub text-primary-600">
            {{ $t('LogIn.org') }}</span>
        </NuxtLink>
      </div>
    </v-layout>
  </div>
  <!--end::Header-->
</template>

<script>
export default {
   
};
</script>
