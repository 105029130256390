import Echo from 'laravel-echo';
// this is for reading the event of the chat ( getting new messages as real time)
export default async ({ store, $cookies, $config: { baseURL } }) => {
  const secretKey = '5bef289b287b07a6e073';
  let loginData = $cookies.get('login-data', { parseJSON: true });
  window.Pusher = require('pusher-js');
  try {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: secretKey,
      cluster: 'ap2',
      forceTLS: false,
      authModule: true,
      authEndpoint: baseURL + '/broadcasting/auth',
      connectOnLogin: true,
      disconnectOnLogout: true,
      auth: {
        headers: {
          Authentication: 'Bearer ' + loginData.token??'',
        },
      },
    });

    window.Echo.channel('chat_channel').listen('.chat_event', (e) => {
      if (e.message.store_id == store.state.auth.isLoggedIn.id)
        store.commit('chat/pushMessage', e.message);
      if (e.message.sender == 'Super_Admin') {
        store.commit('reports/addNEwMessage');
      }
    });
  } catch (error) {
    console.log(error);
  }
};
