export default {
   
  async loadBranchToReview({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(`/branch`, {
        headers: {
          storeId: data.store_id,
          branchId: data.branch_id,
          locale: this.app.i18n.locale
        }
      })
      .then(respo => {
        if (respo.status === 200) {
          commit('setBranchToReview', respo.data.data);
        }
      });
  },
  async submitReview({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .post(`/reviews`, data, {
        headers: {
          locale: this.app.i18n.locale
        }
      })
      .then(respo => {
        if (respo.status === 200) {
          commit('setReviewCoupon',respo.data.data);
          this.$cookies.remove('surveyId',   { parseJSON: false, path: '/' });       
          this.$router.push({
            path: '/' + this.app.i18n.locale + '/store/review/success/'
          });
        }
      });
  },
  async setClickedGoogleLink({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .post(`/google-reviews`, data, {
        headers: {
          locale: this.app.i18n.locale
        }
      })
      .then(respo => {
        if (respo.status === 200) {
          window.location.replace(data.reviewLink);
        }
      });
  },
  async setClickedAdLink({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .post(`/go-to-advertisement`, {
        advertisement_id:data.id
      }, {
        headers: {
          locale: this.app.i18n.locale
        }
      })
      .then(respo => {
        if (respo.status === 200) {
          window.location.replace(data.url);
        }
      });
  },
  async checkRedirectLink({ commit }, slug) {
    // checking redirect link to set cookies
    return await this.$axios.get(`url/redirect/${slug}`, null).then((respo) => {
      let data = respo.data.data;
      this.$cookies.set(
        'review-details',
        JSON.stringify({
          store_id: data.store_id,
          branch_id: data.branch_id,
        }),
        { parseJSON: false, sameSite:'Lax',Secure:true}
      );
      this.$cookies.set(
        'review-details',
        JSON.stringify({
          store_id: data.store_id,
          branch_id: data.branch_id,
        }),
        { parseJSON: false, path: '/',sameSite:'Lax',Secure:true}
      );
      this.$cookies.set(
        'review-details',
        JSON.stringify({
          store_id: data.store_id,
          branch_id: data.branch_id,
        }),
        { parseJSON: false, path: '/en' ,sameSite:'Lax',Secure:true }
      );
      if (data.survey_id) {
        this.$cookies.set(
          'surveyId',
          JSON.stringify({
            surveyId: data.survey_id,
          }),
          { parseJSON: false, path: '/',sameSite:'Lax',Secure:true}
        );
        this.$cookies.set(
          'surveyId',
          JSON.stringify({
            surveyId: data.survey_id,
          }),
          { parseJSON: false, path: '/ar' ,sameSite:'Lax',Secure:true}
        );

        this.$cookies.set(
          'surveyId',
          JSON.stringify({
            surveyId: data.survey_id,
          }),
          { parseJSON: false, path: '/en',sameSite:'Lax',Secure:true}
        );
        return '/store/review';
      } else {
        this.$cookies.remove(
          'surveyId',
        
          { parseJSON: false, path: '/' }
        );
        this.$cookies.remove(
          'surveyId',
         
          { parseJSON: false, path: '/ar' }
        );

        this.$cookies.remove(
          'surveyId',
 
          { parseJSON: false, path: '/en' }
        );
        return '/store/';

      }


    })
  },
  async phone_verification({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    return await this.$axios.post(`send-verification-code`, data).then((respo) => {
     
      if (respo.status === 200) {
        commit('setPhoneVerification', respo.data.data.otp_id);

        commit('setOtpVerification', null);
      }
    });
  },

  async otp_verification({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    return await this.$axios
      .post(`code-verification`, data)
      .then((respo) => {
        
        if (respo.status === 200) {
          commit('setOtpVerification', 1);
        }
      })
      .catch((error) => {
        if (error.response.status == 400) {
          if (error.response.data.data.code == '01_06') {
            commit('setOtpVerification', 2);
          }
        }
        throw error;
      });
  },


};
