<template>
  <v-app class="d-flex flex-column justify-space-between">
    <div v-if="isLoading">
      <landing-loading />

    </div>
    <div v-if="!isLoading">
    <user-header />
    <div id="user-layout-body"  >
      <Nuxt />
    </div>
    <user-footer />
    <messages />
    </div>
  </v-app>
</template>

<script>
import { mapGetters, } from 'vuex';
import Messages from '../components/material/messages.vue';
import UserFooter from '../components/core/user/UserFooter.vue';
import UserHeader from '../components/core/user/UserHeader.vue';
import LandingLoading from '../components/material/LandingLoading.vue';
export default {
  components: { Messages, UserHeader, UserFooter, LandingLoading},
  head() {
    return {
      htmlAttrs: {
        lang: this.language.locale,
        direction: this.language.rtl,
        dir: this.language.rtl,
        style: `direction: ${this.language.rtl}`,
      },
    };
  },
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({ language: 'app/getlang' }),
  },
  mounted() {
    this.$nextTick(() => {
      this.$nuxt.$loading.start();
      setTimeout(() => {
        this.$nuxt.$loading.finish();
        this.showcontent = true;
        this.isLoading = false;
      }, 200);
    });

    this.$vuetify.rtl = this.$i18n.locale=='ar';
   
 
  },
  methods: {
  },
};
</script>
