export default {
  getIsLoggedIn(state) {
    return state.isLoggedIn;
  }, 
  getStoreBranchesState(state) {
    return state.storeBranchesState;
  },
  getUpdateDialog(state) {
    return state.updateDialog;
  },
  getEmailSent(state) {
    return state.EmailSent;
  },
  getPhoneCode(state) {
    return state.PhoneCodeSent;
  },
  getOtpVerification(state) {
    return state.OtpVerification;
  },
};
