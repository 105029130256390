export default {
  async loadBalance({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(`store-points?month=${data.month}&operation=${data.operation}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setBalance', respo.data);
          if (respo.data.data.paymentResult === 1) {
            commit('setPaymentProcessState', 1);
          }
          if (respo.data.data.paymentResult === 2) {
            commit('setPaymentProcessState', 2);
          }
        }
      });
  },
  async saveNewBalance({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`/store-points`, data).then((respo) => {
      if (respo.status === 200) {
        window.location.replace(respo.data.data.url);
      }
    });
  }, 
  async loadBalanceInfo({ commit }) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`store-points-info`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setBalanceInfo', respo.data.data);
      }
    });
  },
  async checkBalanceProcess({ commit }) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`store-points-info`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setPaymentProcessState', respo.data.paymentResult);
      }
    });
  },
  async setPaymentProcess({ commit, dispatch }) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`change-payment-state`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setPaymentProcessFalse');
        dispatch('auth/loadUserDetail', null, { root: true });
      }
    });
  },
};
