export default {
  setAllReviews: (state, data) => {
    state.allReviews = data;
  },
  setOneReview: (state, data) => {
    state.oneReview = data.content;
  },
  // remove that review from the list and close delete confirmation dialog.
  deleteReview: (state, data) => {
    var index = state.allReviews.content.find(
      (review) => review.review_id === data
    );
    if (index != -1) {
      state.allReviews.content.splice(index, 1);
      state.deleteDialog = false;
    }
  },
  setDeleteDialog: (state, data) => {
    state.deleteDialog = data;
  },
  insertAlertReview: (state) => {
    state.alertReviewDialog = false;
  },
  setAlertReviewDialog: (state, data) => {
    state.alertReviewDialog = data;
  },
 
  setSentimentRange(state, data) {
    state.sentimentRange = data;
  }, 
  setSentimentQuestions(state, data) {
    state.sentimentQuestions = data.content;
  },
  
  setSurveyAnalysis: (state, data) => {
    state.surveyAnalysis = data;
  },
};
