export default {
  async loadAllSystemUsers({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(`system/users?page=${data.page}&per_page=${data.per_page}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setAllSystemUsers', respo.data.data);
        }
      });
  },
  async loadOneSystemUsers({ commit }, id) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(`system/users/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOneSystemUser', respo.data.data.content);
        }
      });
  },
  async createSystemUser({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .post(`system/users`,data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOneSystemUser', respo.data.data.content);
          this.$router.back();
            
        }
      });
  },
  async updateSystemUser({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`system/users/${data.id}`,data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOneSystemUser', respo.data.data.content);
          this.$router.back();
            
        }
      });
  },
  async changeUserState({ commit }, id) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`system/users/state/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('changeState', respo.data.data.content);
        }
      });
  },
  async loadAllRoles({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(`roles?page=${data.page}&per_page=${data.per_page}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setAllRoles', respo.data.data);
        }
      });
  },

  async deleteOneUser({ commit }, id) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.delete(`system/users/${id}`).then((respo) => {
      if (respo.status === 200) {
        commit('deleteSystemUser', id);
        commit('app/successMessage', 'userDeleted', { root: true });
      }
    });
  },

  async loadAllPermission({ commit }) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(`permissions`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setAllPermissions', respo.data.data.content);
        }
      });
  },

  async loadOneRole({ commit }, id) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(`roles/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOneRole', respo.data.data.content);
        }
      });
  },
  async createRole({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .post(`roles`,data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOneRole', respo.data.data.content);
          this.$router.back();
            
        }
      });
  },
  async updateRole({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`roles/${data.id}`,data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOneRole', respo.data.data.content);
          this.$router.back();
            
        }
      });
  },
  async changeRoleState({ commit }, id) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`roles/state/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('changeRoleState', respo.data.data.content);
        }
      });
  },
  async deleteOneRole({ commit }, id) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.delete(`roles/${id}`).then((respo) => {
      if (respo.status === 200) {
        commit('deleteRole', id);
        commit('app/successMessage', 'rolesDeleted', { root: true });
      }
    });
  },

};
