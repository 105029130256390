export default {
  getBalance(state) {
    return state.balance;
  }, 
  getCanAdd(state) {
    return state.can_add;
  },
  getStoreBalance(state) {
    return state.storeBalance;
  },
  getBalanceInfo(state) {
    return state.balanceInfo;
  },
  getAddDialog(state) {
    return state.addDialog;
  },
  getPaymentProcessSuccess(state) {
    return state.PaymentProcessSuccess;
  },
  getPaymentProcessfail(state) {
    return state.PaymentProcessfail;
  },
  getReviewsBalance(state) {
    return state.reviewsBalance;
  },
};
