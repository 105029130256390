var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "d-flex flex-column justify-space-between" },
    [
      _vm.isLoading ? _c("div", [_c("landing-loading")], 1) : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            [
              _c("user-header"),
              _vm._v(" "),
              _c("div", { attrs: { id: "user-layout-body" } }, [_c("Nuxt")], 1),
              _vm._v(" "),
              _c("user-footer"),
              _vm._v(" "),
              _c("messages"),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }