export default {
  setAllSurveys: (state, data) => {
    state.allSurveys = data.data.content;
    state.allQuestions = data.data.questions;

  },
  setAllProjectsSurveys: (state, data) => {
    state.allProjectsSurveys = data.data.content;
  },
  setOneSurvey: (state, data) => {
    state.oneSurvey = data.content;
  }, 

  //  update the survey with the new data and sets "oneSurvey" to the updated data
  updateSurvey: (state, data) => {
    if (state.allSurveys) {
      const index = state.allSurveys.find((item) => item.id === data.id);
      if (index) {
        Object.assign(index, data);
        state.oneSurvey = data;
      }
      state.oneSurvey = data;
    }
  },
  //  update the state object by toggling the value of specific properties  
  setSurveyState: (state, data) => {
    if (data.type == 1) {
      state.oneSurvey.state = state.oneSurvey.state == 1 ? 2 : 1;
    }
    if (data.type == 2) {
      state.oneSurvey.is_show = state.oneSurvey.is_show == 1 ? 2 : 1;
    }
  }, 
  setCopiedSurvey: (state,data) => {
    state.copiedSurvey=data
  },
  setSurveyBank: (state,data) => {
    state.surveyBank=data
  },
  // remove the survey at that index from the array.
  deleteSurvey: (state,data) => {
    var index = state.allSurveys.findIndex((item) => item.id === data);
    if (index > -1) {
      state.allSurveys.splice(index, 1);
    }
  },
  // remove moved survey from allSurveys
  applyMoveSurvey: (state,data) => {
    var index = state.allSurveys.findIndex((item) => item.id === data.id);
    if (index > -1) {
      if(state.allSurveys[index].branch_id !=data.branch_id)
      state.allSurveys.splice(index, 1);
    }
  },
};
