export default {
  setAllCoupons: (state, data) => {
    state.allCoupons = data.data;
  },
  setOneCoupons: (state, data) => {
    state.oneCoupon = data.content;
  },
  // insert new coupon to the list
  InsertCoupon: (state, data) => {
    state.allCoupons.content.unshift(data.content);
    state.addDialog = false;
  },
  setAddDialog: (state, data) => {
    state.addDialog = data;
  },

  UpdateCoupon: (state, data) => {
    if (state.allCoupons && state.allCoupons.content) {
      const index = state.allCoupons.content.find(
        (item) => item.id === data.id
      );
      // if coupon exists in the list then update it
      if (index) {
        Object.assign(index, data);
      }
    }
    // update current coupon and close the dialog
    state.oneCoupon = data;
    state.updateDialog = false;
    state.changeStateDialog = false;
  },
  setUpdateDialog: (state, data) => {
    state.updateDialog = data;
  },

  // delete one coupon from list
  deleteCoupon: (state, couponId) => {
    if (state.allCoupons && state.allCoupons.content) {
      var index = state.allCoupons.content.findIndex(
        (item) => item.id === couponId
      );
      if (index > -1) {
        state.allCoupons.content.splice(index, 1);
      }
    }
    state.deleteDialog = false;
  },
  setDeleteDialog: (state, data) => {
    state.deleteDialog = data;
  },
  setChangeStateDialog: (state, data) => {
    state.changeStateDialog = data;
  },
};
