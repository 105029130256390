<template>
  <v-layout justify="center">
    <v-dialog
      v-model="show"
      :open-delay="50000"
      :transition="show ? 'scroll-x-transition' : 'scroll-x-reverse-transition'"
      width="280px"
    >
      <v-card
        height="100%"
        min-height="100vh"
        width="280px"
        class="ratingFilterDialog"
      >
        <v-layout
          dark
          color="white"
          align-center
          class="py-6 px-4"
        >
          <v-toolbar-title class="text-medium-16 text-font-main">{{
            $t("dashboard.notifications")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small @click="show = false" class="wameed-btn sm-icon-btn border-input bg-input  px-0">
                            <close-icon class="svg-font-sub my-1" style="
                        width: 12px;
                        height: 12px;
                      "/>
                        </v-btn>
        </v-layout>
        <div>

          <div style="height: 85%; overflow-y: auto">
            <div
              :to="'/'"
              text
              v-for="(notification, i) in getNotifications"
              :key="i"
            >
              <v-layout class="py-2 px-3"  >
                <v-btn
                  text
                  small
                  class="wameed-btn sm-icon-btn border-input bg-input text-center px-1 "
                  :class="notification.is_read == 2 ? 'svg-font-sub' : 'svg-primary-600'"
                 
                  style="height: 38px; width: 38px;"
                >
                  <notifications-icon class="text-center" />
                </v-btn>

                <div class="ma-1"  >
                  <div class="text-medium-12 text-font-main   " style="line-height: 12px">
                    {{ notification.title }}
                  </div>
                  <div class="text-regular-10 text-font-sub " style="line-height: 20px">
                    {{ notification.message }}
                  </div>
                  <span class="text-regular-8 text-font-sub">{{
                    notificationTime(notification.created_at) +
                    "-" +
                    notificationDate(notification.created_at)
                  }}</span>
                </div>
              </v-layout>
            </div>
          </div>

        </div>
      </v-card>
      <div>
      </div>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {   },
  props: {
    visible: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...mapGetters({ getNotifications: "shop/getNotifications" }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  mounted() {
    this.loadNotifications();
  },
  methods: {
    ...mapActions({ loadNotifications: "shop/loadNotifications" }),
    notificationDate(date) {
      var d = new Date(date);
      return d.getDate() + " / " + (d.getMonth() + 1) + " / " + d.getFullYear();
    },
    notificationTime(date) {
      var d = new Date(date);
      return d.toLocaleTimeString(
        this.$i18n.locale == "ar" ? "ar-AR" : "en-GB"
      );
    },
  },
};
</script>
