var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _vm.error.statusCode === 404
        ? _c(
            "v-layout",
            {
              staticStyle: { height: "100vh", "background-color": "#fff" },
              attrs: { "justify-center": "", "align-center": "" },
            },
            [
              _c(
                "v-layout",
                {
                  staticStyle: {
                    "max-width": "1200px",
                    height: "100vh",
                    "background-color": "#fff",
                    margin: "18px !important",
                  },
                  attrs: { wrap: "", "justify-center": "", "align-center": "" },
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass:
                        "order-2 order-sm-1 order-lg-1 order-md-1 order-xl-1",
                      attrs: { sm6: "", xs12: "", md5: "", lg5: "" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          class: _vm.responsive
                            ? "justify-center align-center"
                            : "",
                          attrs: { column: "" },
                        },
                        [
                          _c(
                            "NuxtLink",
                            {
                              staticStyle: { "margin-bottom": "23px" },
                              attrs: { to: `${_vm.$i18n.locale}/` },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  contain: "",
                                  src: "/media/logo.svg",
                                  width: "89px",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "h3",
                            {
                              staticClass: "login-title",
                              style: _vm.responsive
                                ? "margin-bottom: 16px; font-size: 24px !important"
                                : "margin-bottom: 16px; font-size: 30px !important",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("dashboard.sorry")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "text-regular-14 text-font-sub",
                              staticStyle: {
                                "margin-bottom": "22px",
                                "line-height": "30px",
                              },
                              style: _vm.responsive
                                ? "font-size: 20px !important"
                                : " font-size: 26px !important",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("dashboard.noSuchPage")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("WameedButton", {
                            staticClass: "text-white text-regular-13",
                            staticStyle: { "max-width": "200px" },
                            attrs: {
                              "append-icon": "prev-icon",
                              title: _vm.getIsLoggedIn
                                ? _vm.$t("dashboard.goBack")
                                : _vm.$t("dashboard.backhome"),
                            },
                            on: {
                              onClick: function ($event) {
                                _vm.getIsLoggedIn
                                  ? _vm.$router.back()
                                  : _vm.$router.push({
                                      path: `/${_vm.$i18n.locale}/`,
                                    })
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    {
                      staticClass:
                        "order-1 order-sm-2 order-lg-2 order-md-3 order-xl-4",
                      staticStyle: { padding: "7px" },
                      attrs: { sm6: "", xs12: "", md7: "", lg7: "" },
                    },
                    [
                      _c("v-img", {
                        attrs: { src: require("@/static/media/svg/404.svg") },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-layout",
            {
              staticStyle: { height: "100vh", "background-color": "#fff" },
              attrs: { "justify-center": "", "align-center": "" },
            },
            [
              _c(
                "v-layout",
                {
                  staticStyle: {
                    "max-width": "1200px",
                    height: "100vh",
                    "background-color": "#fff",
                    margin: "18px !important",
                  },
                  attrs: { wrap: "", "justify-center": "", "align-center": "" },
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass:
                        "order-2 order-sm-1 order-lg-1 order-md-1 order-xl-1",
                      attrs: { sm6: "", xs12: "", md5: "", lg5: "" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          class: _vm.responsive
                            ? "justify-center align-center"
                            : "",
                          attrs: { column: "" },
                        },
                        [
                          _c(
                            "NuxtLink",
                            {
                              staticStyle: { "margin-bottom": "23px" },
                              attrs: { to: `${_vm.$i18n.locale}/` },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  contain: "",
                                  src: "/media/logo.svg",
                                  width: "98px",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "h3",
                            {
                              staticClass: "login-title",
                              style: _vm.responsive
                                ? "margin-bottom: 16px; font-size: 24px !important"
                                : "margin-bottom: 16px; font-size: 30px !important",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("dashboard.sorry")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-regular-14 text-font-secondary",
                              staticStyle: {
                                "margin-bottom": "22px",
                                "line-height": "30px",
                              },
                              style: _vm.responsive
                                ? "font-size: 20px !important"
                                : " font-size: 26px !important",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("dashboard.noSuchPage")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("WameedButton", {
                            staticClass: "text-white text-regular-13",
                            staticStyle: { "max-width": "200px" },
                            attrs: {
                              "append-icon": "prev-icon",
                              title: _vm.getIsLoggedIn
                                ? _vm.$t("dashboard.goBack")
                                : _vm.$t("dashboard.backhome"),
                            },
                            on: {
                              onClick: function ($event) {
                                _vm.getIsLoggedIn
                                  ? _vm.$router.back()
                                  : _vm.$router.push({
                                      path: `/${_vm.$i18n.locale}/`,
                                    })
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    {
                      staticClass:
                        "order-1 order-sm-2 order-lg-2 order-md-3 order-xl-4",
                      staticStyle: { padding: "7px" },
                      attrs: { sm6: "", xs12: "", md7: "", lg7: "" },
                    },
                    [
                      _c("v-img", {
                        attrs: { src: require("@/static/media/svg/404.svg") },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }