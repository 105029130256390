var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticStyle: { height: "100vh" }, attrs: { id: "loginStyle" } },
    [
      _c("client-only", [
        _c(
          "div",
          {
            staticClass: "d-flex flex-column flex-root",
            staticStyle: { height: "100vh", "background-color": "#fff" },
          },
          [
            _c(
              "v-layout",
              { staticClass: "login" },
              [
                _c(
                  "v-flex",
                  {
                    staticClass:
                      "login-content flex-column-fluid d-flex flex-column justify-space-between loginbg",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-right d-flex justify-center align-center",
                      },
                      [
                        _c(
                          "v-layout",
                          {
                            staticClass: "login-form px-2",
                            attrs: { "justify-center": "", "align-center": "" },
                          },
                          [
                            _c(
                              "NuxtLink",
                              { attrs: { to: `/${_vm.$i18n.locale}/` } },
                              [
                                _c("img", {
                                  attrs: {
                                    alt: "Logo",
                                    src: "/media/logo.svg",
                                    width: "98px",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("v-spacer"),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "login-subtitle my-auto px-0",
                                attrs: { text: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeLanguage()
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "login-subtitle" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("Core.Header.language.other")
                                      )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row-fluid flex-center justify-center",
                        staticStyle: { "padding-top": "72px" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "login-form" },
                          [_c("nuxt")],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-right d-flex justify-center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-center d-flex justify-content-end pt-5 pb-lg-0 pb-10",
                          },
                          [
                            _c(
                              "NuxtLink",
                              {
                                staticStyle: { "text-decoration": "none" },
                                attrs: { to: `/${_vm.$i18n.locale}/` },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-regular-14 text-font-sub",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("LogIn.devlopedBy"))
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-regular-14 text-font-sub text-primary-600",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("LogIn.org"))
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.responsive
                  ? _c(
                      "v-flex",
                      {
                        staticClass:
                          "d-flex flex-column flex-row-auto loginimgbg",
                        attrs: {
                          lg8: "",
                          md8: "",
                          sm12: "",
                          xs12: "",
                          "justify-center": "",
                          "align-center": "",
                        },
                      },
                      [
                        _c(
                          "v-layout",
                          {
                            staticClass: "bg-gray-50 rounded-16",
                            staticStyle: { height: "100%", width: "100%" },
                            attrs: { "align-center": "", "justify-center": "" },
                          },
                          [
                            _c("v-img", {
                              staticStyle: {
                                "max-width": "80%",
                                margin: "auto",
                                "max-height": "400px",
                              },
                              attrs: {
                                contain: "",
                                src: "/media/svg/loginsideimage.svg",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("messages"),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }