export default {
  async LoadAllMessages({ commit }) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(`get-store-messages`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setAllMessages', respo.data.data.content);
          commit('reports/readMessages', null, { root: true });
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  async sendOneMessage({ commit }, data) {
    commit('app/loadingStart', null, { root: true });
    let formData = new FormData();
    formData.append('message', data.newMessage);
    formData.append('file', data.FileToUpload ? data.FileToUpload : '');

    await this.$axios
      .post(`store-send-message`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((respo) => {
        if (respo.status === 200) {
         
        }
      })
      .catch((error) => {
        throw error;
      });
  },
};
