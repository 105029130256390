<template>
    <v-layout justify="center">
        <v-dialog v-model="show" transition="dialog-top-transition"  :retain-focus="false">
            <v-layout  justify-center align-center class="my-2">
                <v-card :width="dialogWidth" min-width="300" class="mt-16 mb-0 mx-5 rounded-6">
                    <v-card-title class="justify-space-between align-baseline pa-6" >
                        <div>
                            <div class="text-medium-16 text-font-main" style="line-height:10px">{{ title }} </div>
                        </div>
                        <v-btn text small @click="show = false" class="wameed-btn sm-icon-btn border-input bg-input  px-0">
                            <close-icon class="svg-font-sub my-1" style="
                        width: 12px;
                        height: 12px;
                      "/>
                        </v-btn>
                    </v-card-title>
                    <v-layout justify-space-between align-content-space-between column style="height: 100%"
                        class="  py-0 px-0">
                        <v-form ref="form" v-model="valid">
                            <slot   />
                            <v-layout wrap justify-space-between class="ma-6" style="gap:8px">
                                <slot name="custom"  />
                                <v-layout justify-end wrap style="gap:8px" >
                                    <WameedButton   classes="  text-regular-13 gray-600  " :title="$t('actions.cancel')"
                                        variant="gray-100" @onClick="show = false" />
                                    <WameedButton   :disabled="!valid || disabledCondition" classes="   text-regular-13 text-white   "
                                        :title="btnTitle" type="submit" variant="primary-600" @onClick="$emit('submit')" />
                                </v-layout>
                            </v-layout>
                        </v-form>
                    </v-layout>
                </v-card>
            </v-layout>
        </v-dialog>
    </v-layout>
</template>
<script>

import WameedButton from '@/components/material/WameedButton.vue';
export default {
    props: {
        visible: {
            type: Boolean,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        btnTitle: {
            type: String,
            default: null,
        },
        disabledCondition:{
            type: Boolean,
            default: false,
        },
        dialogWidth:{
            type: String,
            default: '600px',
        }
    },
    components: {
        WameedButton,
    },
    data: (vm) => ({
        valid: false,
        

    }),
    watch: {
        

    },
    computed: { 
        show: {
            get() {
                return this.visible;
            },
            set(value) {
                if (!value) {
                    this.$refs.form.reset();
                   
                    this.$emit('close');
                }
            },
        }, 
    },
    methods: {

    }

}
</script>