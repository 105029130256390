//import i18n from '~/plugins/i18n'
export default {
  setPackageDetails: (state, data) => {
    state.packages = data.data;
  },
  setPackages: (state, data) => {
    state.allPackages = data;
  },  
  setAllNotifications: (state, data) => {
    state.notifications = data;
  },  
  setTutorials(state, data) {
    state.tutorials = data;
  },
  setTutorialsDetails(state, data) {
    state.tutorialsDetails = data;
  }, 
};
