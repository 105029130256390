export default () => ({
  locales: ['ar', 'en'],
  language: {
    locale: 'ar',
    rtl: 'rtl',
    rtl2: 'rtl.',
    direction: 'right',
  },

  messages: 'success',
  loading: false,
  drawer: null,
  loadingCounter: -1,
  imageUrl: process.env.baseUrl,
});
