import https from 'https';
// axsios interceptor

export default function ({ $axios, app, store,error }) {
  const agent = new https.Agent({
    rejectUnauthorized: false
  });
  // setting locale and auth headers
  $axios.onRequest((config) => {
    config.httpsAgent = agent;
    config.headers.common['locale']= app.i18n.locale
    app.$cookies;
    config.headers.common['locale'] = app.i18n.locale;
    let loginData = app.$cookies.get('login-data', { parseJSON: true });
    if (loginData) {
      config.headers.common['X-Authorization'] = 'Bearer ' + loginData.token;
    }
    return config;
  });
  $axios.onResponse((response) => {
      setTimeout(() => {
        store.commit('app/loadingFinish', null, { root: true });
      }, 10);
    return response;
  });

  // handle various error statuses. It includes logic to handle different status codes, such as 400, 401, 404, 422, and 500. It also checks for a specific URL and handles it differently. It then returns a rejected promise
  $axios.onError((errorRes) => {
    setTimeout(() => {
      store.commit('app/loadingFinish', null, { root: true });
    }, 10);
    if (errorRes.response.status == 400) {
      store.commit(
        'app/failMessage',
        errorRes.response.data.data.code ? errorRes.response.data.data.code : 'error'
      );
    }

    if (errorRes.response.status == 401) {
      store.dispatch('auth/logout');
      store.commit('app/failMessage', errorRes.response.status);
    }
    if (errorRes.response.status == 404) {
     error({ message: 'This page could not be found.', statusCode: 404 })
    }

    if (
      errorRes.response.status == 404 ||
      errorRes.response.status == 422 ||
      errorRes.response.status == 500
    ) {
      if(errorRes.response.config.url=='/coupons/upload')
      store.commit('app/failMessage','couponError');
      else if( errorRes.response.status != 403)
      store.commit('app/failMessage', errorRes.response.status);
    }
    return Promise.reject(errorRes);
  });
}
