const permissions = {
       readDashboard: "read dashboard",
       readBranches: "read branches",
       addBranches: "add branches",
       editBranches: "edit branches",
       deleteBranches: "delete branches",

       readQuestions: "read questions",
       addQuestions: "add questions",
       editQuestions: "edit questions",
       deleteQuestions: "delete questions",

       readRewards: "read rewards",
       addRewards: "add rewards",
       editRewards: "edit rewards",
       deleteRewards: "delete rewards",

       readPoints: "read points",
       addPoints: "add points",
       editPoints: "edit points",
       deletePoints: "delete points",

       readReviews: "read reviews",
       sendReviews: "send reviews",
       exportReviews: "export reviews",
       deleteReviews: "delete reviews",

       readChats: "read chats",
       // sendChats: "send chats",
       readReports: "read reports",
       exportReports: "export reports",

       readPackage: "read package",
       renewalPackage: "renewal package",

       editStoreData: "edit store data",
       editAdvanceSetting: "edit advance setting",
       editAnalysisQuestions: "edit analysis questions",

       readSystemUsers: "read system users",
       addSystemUsers: "add system users",
       editSystemUsers: "edit system users",
       deleteSystemUsers: "delete system users",
       
       readRoles: "read roles",
       addRoles: "add roles",
       editRoles: "edit roles",
       deleteRoles: "delete roles",

}




export {permissions}
