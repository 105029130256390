export default {
  getAllSurveys(state) {
    return state.allSurveys;
  },
  getAllProjectsSurveys(state) {
    return state.allProjectsSurveys;
  },
  getOneSurvey(state) {
    return state.oneSurvey;
  }, 
  getCopiedSurvey(state) {
    return state.copiedSurvey;
  }, 
  getSurveyBank(state) {
    return state.surveyBank;
  },
  getAllQuestions(state) {
    return state.allQuestions;
  },
};
