import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';

export default ({ store, $config: { mapAPIKey } }) => {
  Vue.use(VueGoogleMaps, {
    load: {
      key: mapAPIKey,
      libraries: 'places',
      region: store.state.app.language.locale.toUpperCase(),
      language: store.state.app.language.locale,
    },
  });
};
