export default {

  setBranchToReview: (state, data) => {
    state.branchToReview = data.content;
    state.reviewed=false
  },
  setSectionToReview: (state, data) => {
    state.sectionToReview = data;
  },

  setReviewCoupon: (state,data) => {
    state.reviewed=true
    if(state.sectionToReview  && state.sectionToReview.coupon_details) {
    state.reviewCoupon = state.sectionToReview.coupon_details;
    }
    state.sectionToReview={store_logo:state.sectionToReview.store_logo,
      section_name:state.sectionToReview.section_name,
    };
    state.googleReviewLink=data.content.google_review_link
    state.reviewId=data.content.review_id
    if(data.content.advertisement)
    state.advertisement=data.content.advertisement
  }, 

  setPhoneVerification(state, data) {
    state.PhoneCodeSent = data;
  },
  setOtpVerification(state, data) {
    state.OtpVerification = data;
  },
};
