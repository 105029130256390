export default {
   
  async loadPackages({ commit }) {

    await this.$axios.get(`packages`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setPackages', respo.data.data.content);
      }
    });
  }, 
 
  async contactUS({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`contect-us`, data).then((respo) => {
      if (respo.status === 200) {
        commit('app/successMessage', 'MessageSent', { root: true });
      }
    });
  },
  async upgradePackage({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`package-renewal`, data).then((respo) => {
      if (respo.status === 200) {
        // redirect to payment page
        if (respo.data.data && respo.data.data.url) {
          window.location.replace(respo.data.data.url);

        } 

      }
    });
  },
  async loadNotifications({ commit }) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`store-alerts`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setAllNotifications', respo.data.data.content);
      }
    });
  },
  async checkCoupon({ commit, dispatch }, data) {
    commit('app/loadingStart', null, { root: true });

  return  await this.$axios
      .post(`/coupons/subscription/check`, data)
      .then((respo) => {
        commit('app/successMessage', 'couponIsApplied', { root: true });
        return respo.data.data.content
      });
  },
  

  async loadTutorials({ commit }) {

    await this.$axios.get(`tutorials/sections`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setTutorials', respo.data.data.content);
      }
    });
  },
  async loadTutorialsDetails({ commit }, data) {

    await this.$axios.get(`tutorials/${data}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setTutorialsDetails', respo.data.data.content);
      }
    });
  },
 

};
