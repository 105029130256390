export default {
  
  async loadAllBranches({ commit }) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`/google-reviews-branches`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setBranches', respo.data.data);
      }
    });
  },
  async sendRequest({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

  return   await this.$axios.post(`/google-reviews-branches`, {branches:data}).then((respo) => {
      if (respo.status === 200) { 
        commit('setBranches', respo.data.data);
        commit('app/successMessage', 'requestSent', { root: true });
        return true
      }
    });
  },
  async updateRequest({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`/google-reviews-branches/update`, {branches:data}).then((respo) => {
      if (respo.status === 200) { 
        commit('app/successMessage', 'requestSent', { root: true });
      }
    });
  },
  async loadGoogleReviews({ commit }, data) {
    commit('app/loadingStart', null, { root: true });
   
    await this.$axios.get(`google-reviews?place_id=${data}`).then((respo) => {
      if (respo.status === 200) {
        commit('setGoogleReviews',respo.data.data);

      }
    });
  },
  async ExportGoogleReviews({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(`google-reviews/download?place_id=${data.placeId}&file_type=${data.type}`,
        null,
        {
          responseType: 'blob'
        }
      )
      .then((respo) => {
        if (respo.status === 200) {
          // prepare data and export it
          const link = document.createElement('a');
          link.download = data.filename+'_'+this.$moment().format('YYYY-MM-DD')+ '.'+data.type;
          link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + respo.data;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
             
        }
      });
  },
 
};
