<template>
  <div
    style="position: relative"
    id="core-view "
    :style="
      responsive
        ? ''
        : this.$i18n.locale == 'ar'
        ? 'margin-top:117px;margin-right:266px'
        : 'margin-top:117px;margin-left:266px'
    "
  >
    <v-layout
      class="align-center"
      justify-space-between
      style="margin: 2px 20px"
    >
      <v-flex v-if="responsive" lg9 md9 sm9 xs12>
        <v-autocomplete
          v-model="model"
          :items="items"
          :loading="false"
          :placeholder="$t('Core.Header.Search') + ' ...'"
          :search-input.sync="search"
          hide-selected
          solo
          class="searchBar2"
          hide-no-data
          hide-details="auto"
          item-text="name"
          item-value="abbr"
          return-object
          append-icon=""
        >
          <div slot="prepend-inner">
            <search-icon />
          </div>
        </v-autocomplete>
      </v-flex>
    </v-layout>
    <div style="max-width: 1900px">
      <v-fade-transition mode="out-in">
        <nuxt />
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    model: null,
    search: null,
    items: [],
  }),
  metaInfo() {
    return {
      title: "",
    };
  },
  computed: {
    responsive() {
      if (
        this.$vuetify.breakpoint.name == "sm" ||
        this.$vuetify.breakpoint.name == "xs"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    model(val) {
      if (val != null) {
        this.$router.push({ path: `/${this.$i18n.locale}${val.abbr}` });
      }
    },
    // search list
    search(val) {
      if (val !== "") {
        this.items = [
          {
            name: this.$i18n.t("Core.drawer.Dashboard"),
            abbr: "/dashboard",
            id: 1,
          },
          {
            name: this.$i18n.t("Core.drawer.projects"),
            abbr: "/projects",
            id: 1,
          },
          {
            name: this.$i18n.t("Core.drawer.questionsAndCoupons"),
            abbr: "/settings/questions-and-rewards",
            id: 1,
          },
          {
            name: this.$i18n.t("Core.drawer.surveys"),
            abbr: "/surveys",
            id: 1,
          },
          { name: this.$i18n.t("Core.drawer.Points"), abbr: "/settings/balance", id: 1 },
          {
            name: this.$i18n.t("Core.drawer.answers"),
            abbr: "/answers",
            id: 1,
          },
          { name: this.$i18n.t("Core.drawer.Chats"), abbr: "/chats", id: 1 },
          {
            name: this.$i18n.t("Core.drawer.Settings"),
            abbr: "/settings",
            id: 1,
          },
          {
            name: this.$i18n.t("questionsAndCoupons.choices_questions"),
            abbr: "/settings/questions-and-rewards/choices-questions",
            id: 1,
          },
           {
            name: this.$i18n.t("questionsAndCoupons.star_questions"),
            abbr: "/settings/questions-and-rewards/star-questions",
            id: 1,
          },
          {
            name: this.$i18n.t("setting.advanceSettings"),
            abbr: "/settings/advancesetting",
            id: 1,
          },
          {
            name: this.$i18n.t("setting.packageInfo"),
            abbr: "/settings/package",
            id: 1,
          },
          {
            name: this.$i18n.t("setting.personalInfo"),
            abbr: "/settings/storeinformation",
            id: 1,
          },
        ];
      } else {
        this.items = [];
      }
    },
  },
  created() {},
  mounted() {
  },
  methods: {},
};
</script>
 
