<template>
  <div v-if="getloading"  >
    <loading
      :active.sync="getloading"
      :can-cancel="false"
      :is-full-page="true"
      class="svg-stroke-primary-600"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapGetters, mapMutations } from 'vuex';
export default {
  components: {
    Loading,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({ getloading: 'app/getloading' }),
  },
  mounted() {
    setTimeout(() => {
      this.loadingFinish();
      }, 30000);
  },
  methods: {
    ...mapMutations({
      loadingFinish: 'app/loadingFinish',
      loadingStart: 'app/loadingStart',
    }),
    start() {
      this.loadingStart();
    },
    finish() {
      this.loadingFinish();
    },
  },
};
</script>
