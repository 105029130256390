var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { transition: "dialog-top-transition", "retain-focus": false },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-layout",
            {
              staticClass: "my-2",
              attrs: { "justify-center": "", "align-center": "" },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "mt-16 mb-0 mx-5 rounded-6",
                  attrs: { width: _vm.dialogWidth, "min-width": "300" },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "justify-space-between align-baseline pa-6",
                    },
                    [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "text-medium-16 text-font-main",
                            staticStyle: { "line-height": "10px" },
                          },
                          [_vm._v(_vm._s(_vm.title) + " ")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "wameed-btn sm-icon-btn border-input bg-input px-0",
                          attrs: { text: "", small: "" },
                          on: {
                            click: function ($event) {
                              _vm.show = false
                            },
                          },
                        },
                        [
                          _c("close-icon", {
                            staticClass: "svg-font-sub my-1",
                            staticStyle: { width: "12px", height: "12px" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    {
                      staticClass: "py-0 px-0",
                      staticStyle: { height: "100%" },
                      attrs: {
                        "justify-space-between": "",
                        "align-content-space-between": "",
                        column: "",
                      },
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _vm._t("default"),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            {
                              staticClass: "ma-6",
                              staticStyle: { gap: "8px" },
                              attrs: { wrap: "", "justify-space-between": "" },
                            },
                            [
                              _vm._t("custom"),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                {
                                  staticStyle: { gap: "8px" },
                                  attrs: { "justify-end": "", wrap: "" },
                                },
                                [
                                  _c("WameedButton", {
                                    attrs: {
                                      classes: "  text-regular-13 gray-600  ",
                                      title: _vm.$t("actions.cancel"),
                                      variant: "gray-100",
                                    },
                                    on: {
                                      onClick: function ($event) {
                                        _vm.show = false
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("WameedButton", {
                                    attrs: {
                                      disabled:
                                        !_vm.valid || _vm.disabledCondition,
                                      classes:
                                        "   text-regular-13 text-white   ",
                                      title: _vm.btnTitle,
                                      type: "submit",
                                      variant: "primary-600",
                                    },
                                    on: {
                                      onClick: function ($event) {
                                        return _vm.$emit("submit")
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }