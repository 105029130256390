var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "landing-header", staticStyle: {} }, [
    _c("header", [
      _c("div", { staticClass: "main-layout responsive-header" }, [
        _c(
          "div",
          { staticStyle: { "line-height": "14px" } },
          [
            _c(
              "NuxtLink",
              {
                staticStyle: { display: "flex", "align-items": "center" },
                attrs: { to: `/${_vm.$i18n.locale}/`, "aria-label": "logo" },
              },
              [_c("logo-white", { staticClass: "logo" })],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "text-medium-12 text-font-sub text-white",
                staticStyle: { "margin-left": "6px", "margin-right": "6px" },
              },
              [_vm._v("\n          " + _vm._s(_vm.$t("LogIn.org")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              attrs: { "aria-label": "menu", id: "header-burger-menu" },
              on: {
                click: function ($event) {
                  return _vm.showMenu()
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "19.5",
                    height: "13.5",
                    viewBox: "0 0 19.5 13.5",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      id: "Path_2055",
                      "data-name": "Path 2055",
                      d: "M546.25,942a.75.75,0,0,1,.75-.75h18a.75.75,0,0,1,0,1.5H547A.75.75,0,0,1,546.25,942Zm0,6a.75.75,0,0,1,.75-.75h18a.75.75,0,0,1,0,1.5H547A.75.75,0,0,1,546.25,948Zm.75,5.25a.75.75,0,0,0,0,1.5h18a.75.75,0,0,0,0-1.5Z",
                      transform: "translate(-546.25 -941.25)",
                      fill: "#fff",
                      "fill-rule": "evenodd",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.activeMenu ? "active" : "", attrs: { id: "main-header" } },
        [
          _c("div", {
            staticClass: "overly-background",
            on: {
              click: function ($event) {
                _vm.activeMenu = false
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "main-layout" }, [
            _c("div", { staticClass: "sidebar-header" }, [
              _c(
                "div",
                { staticStyle: { "line-height": "14px" } },
                [
                  _c(
                    "NuxtLink",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                      attrs: {
                        to: `/${_vm.$i18n.locale}/`,
                        "aria-label": "logo2",
                      },
                    },
                    [_c("logo-white", { staticClass: "logo2" })],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "text-medium-12 text-font-sub text-white",
                        staticStyle: {
                          "margin-left": "6px",
                          "margin-right": "6px",
                        },
                      },
                      [_vm._v("\n          " + _vm._s(_vm.$t("LogIn.org")))]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.activeMenu = false
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "13.5",
                          height: "13.5",
                          viewBox: "0 0 13.5 13.5",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            id: "Path_2060",
                            "data-name": "Path 2060",
                            d: "M269.47,941.47a.749.749,0,0,1,1.06,0l5.47,5.469,5.47-5.469a.75.75,0,0,1,1.06,1.06L277.061,948l5.469,5.47a.75.75,0,0,1-1.06,1.06L276,949.061l-5.47,5.469a.75.75,0,0,1-1.06-1.06l5.469-5.47-5.469-5.47A.749.749,0,0,1,269.47,941.47Z",
                            transform: "translate(-269.25 -941.25)",
                            fill: "#fff",
                            "fill-rule": "evenodd",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "list-div", staticStyle: { "z-index": "1" } },
              [
                _c("ul", { staticClass: "main-list" }, [
                  _c(
                    "li",
                    [
                      _c(
                        "NuxtLink",
                        { attrs: { to: `/${_vm.$i18n.locale}/` } },
                        [_vm._v(_vm._s(_vm.$t("homePage.home")))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "NuxtLink",
                        {
                          attrs: { to: `/${_vm.$i18n.locale}/#about-section` },
                        },
                        [_vm._v(_vm._s(_vm.$t("homePage.who_we_are")))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "NuxtLink",
                        {
                          attrs: {
                            to: `/${_vm.$i18n.locale}/#package-section`,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("homePage.packages")))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "NuxtLink",
                        {
                          attrs: {
                            to: `/${_vm.$i18n.locale}/#questions-section`,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("homePage.faq")))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage(
                              _vm.$i18n.locale == "ar" ? "en" : "ar"
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$i18n.locale === "ar" ? "English" : "العربية"
                            ) +
                            "\n\n              "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "list-div login-btn-div",
                staticStyle: { "z-index": "1" },
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "loginBtn",
                    staticStyle: { "text-decoration": "none" },
                    attrs: {
                      outlined: "",
                      to: _vm.getIsLoggedIn
                        ? `/${_vm.$i18n.locale}/dashboard`
                        : `/${_vm.$i18n.locale}/login`,
                    },
                  },
                  [
                    _c("span", { staticStyle: { "text-decoration": "none" } }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.getIsLoggedIn
                              ? _vm.$t("homePage.dashboard")
                              : _vm.$t("homePage.loginAsCompany")
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }