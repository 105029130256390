export default () => ({
  allReviews: null,
  oneReview: null,
  deleteDialog: false,
  alertReviewDialog: false,
  sentimentRange:{
    days:[]
  },
  categoriesAnalysis:null,
  wordCloud:[],
  sentimentQuestions:null,
  surveyAnalysis:null
});
