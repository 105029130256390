<template>
  <v-app>
    <div v-if="isLoading">
      <landing-loading />
    </div>
    <div v-if="!isLoading">

      <landing-header />
      <Nuxt />
      <landing-footer />

      <messages />
    </div>
  </v-app>
</template>
<script>
import { mapGetters,} from 'vuex';
import Messages from '../components/material/messages.vue';
import LandingFooter from '../components/core/landing/LandingFooter.vue';
import LandingHeader from '../components/core/landing/LandingHeader.vue';
import LandingLoading from '../components/material/LandingLoading.vue';
export default {
  components: { Messages, LandingHeader, LandingFooter, LandingLoading },
  head() {
    return {
      htmlAttrs: {
        lang: this.language.locale,
        direction: this.language.rtl,
        dir: this.language.rtl,
        style: `direction: ${this.language.rtl}`,
      },
    };
  },
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({ language: 'app/getlang' }),
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.showcontent = true;
        this.isLoading = false;
      }, 200);
    });

    this.$vuetify.rtl = this.$i18n.locale=='ar';
  

  },
  methods: {
  },
};
</script>
 