// checking user permissions , if not exsist them redirect to the dashboard page
export default async ({ app ,route,store,redirect }) => {
       app.router.beforeResolve(async(to, from,next) => {
        if (process.browser) {
          }
         let loginData = app.$cookies.get('login-data', { parseJSON: true });
        if (loginData && route.meta[0] && route.meta[0].permissions) {
            if (!store.state.auth.isLoggedIn || (store.state.auth.isLoggedIn && store.state.auth.isLoggedIn.permissions.length === 0)) {
              await store.dispatch('auth/loadUserDetail')
            }
            if ( store.state.auth.isLoggedIn && !store.state.auth.isLoggedIn.permissions.some((value) => route.meta[0].permissions.includes(value))) {
               return redirect(window.origin+'/ar/dashboard');
              }
        }
       
         next();
      });
    

  
  }
  