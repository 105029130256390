//import i18n from '~/plugins/i18n'
export default {
  setStatistics: (state, data) => {
    state.statistics = data.data;
  },
  setAllSubscriptionRecords: (state, data) => {
    state.subscriptionRecords = data.content;
  },
  addNEwMessage: (state) => {
    state.statistics.no_read_messages = state.statistics.no_read_messages + 1;
  },
  readMessages: (state) => {
    state.statistics.no_read_messages = 0;
  },
  setDailyRatings:(state,data)=>{
    state.data.dailyRatingDays=data.days
    state.data.dailyRatingReviews=data.reviews_data
  },
  setMonthlyRatings:(state,data)=>{
    state.data.monthlyRatingDays=data.months
    state.data.monthlyRatingReviews=data.reviews_count
  },
  setQuestionRatings:(state,data)=>{
    state.data.questionRatingQuestions=data.questions
    state.data.questionRatingQuestionTypes=data.questions_types
    state.data.questionRatingReviews=data.reviews_data
    state.data.questionRatingChartData=data.chart_data
  },
  setSurveyRatings:(state,data)=>{
    state.data.surveyRatingSurveys=data.surveys
    state.data.surveyRatingReviews=data.reviews_data
  },
  setBranchRatings:(state,data)=>{
    state.data.branchRatings= data.branches
  },
};
