var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "user-footer-bar",
      staticStyle: { "background-color": "#fff" },
    },
    [
      _c(
        "v-layout",
        {
          staticClass: "mt-auto py-5",
          attrs: { "justify-center": "", "align-center": "" },
        },
        [
          _c(
            "div",
            { staticClass: "text-center d-flex justify-content-end" },
            [
              _c(
                "NuxtLink",
                {
                  staticStyle: { "text-decoration": "none" },
                  attrs: { to: `/${_vm.$i18n.locale}/` },
                },
                [
                  _c("span", { staticClass: "text-medium-16 text-font-main" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("branches.all_rights"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-medium-16 text-font-sub text-primary-600",
                    },
                    [_vm._v("\n          " + _vm._s(_vm.$t("LogIn.org")))]
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }