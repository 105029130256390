export default {
  setLoggedInuser: (state, data) => {
    state.isLoggedIn = data;
  }, 
  storeDetailsUpdate: (state, data) => {
    state.isLoggedIn = { ...state.isLoggedIn, ...data };
    state.updateDialog = true;
  },
  updatePassword: (state) => {
    state.updateDialog = true;
  },

  storeSateWhole: (state, data) => {
    state.storeBranchesState = data;
    state.updateDialog = true;
  },
  setStoreBranchesState: (state, data) => {
    state.storeBranchesState = data;
  },
  setUpdateDialog: (state) => {
    state.updateDialog = false;
  },
  setLoggedOutuser(state) {
    state.isLoggedIn = null;
  },
  setCheckEmail(state, data) {
    state.EmailSent = data;
  },
  setPhoneVerification(state, data) {
    state.PhoneCodeSent = data;
  },
  setOtpVerification(state, data) {
    state.OtpVerification = data;
  },
};
