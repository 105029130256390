export default {
  getStatistics(state) {
    return state.statistics;
  },
  getAllSubscriptionRecords(state) {
    return state.subscriptionRecords;
  },
 
  getDailyRatings: (state) => {
    return {
      days: state.data.dailyRatingDays,
      reviews: state.data.dailyRatingReviews,
    };
  },
  getMonthlyRatings: (state) => {
    return {
      months: state.data.monthlyRatingDays,
      reviews: state.data.monthlyRatingReviews,
    };
  },
  getQuestionRatings: (state) => {
    return {
      questions: state.data.questionRatingQuestions,
      questionsTypes: state.data.questionRatingQuestionTypes,
      reviews: state.data.questionRatingReviews,
      chartData: state.data.questionRatingChartData,
      
    };
  },
  getSurveyRatings: (state) => {
    return {
      surveys: state.data.surveyRatingSurveys,
      reviews: state.data.surveyRatingReviews,
    };
  },
  getBranchRatings: (state) => {
    return state.data.branchRatings
  }
}; 
