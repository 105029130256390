var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "drawerclass",
      staticStyle: { "max-height": "100%" },
      attrs: {
        id: "app-drawer",
        right: _vm.language.rtl == "rtl" ? true : false,
        app: "",
        dark: "",
        floating: "",
        persistent: "",
        "mobile-breakpoint": "992",
        width: "265",
      },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "fb-nav-list",
                  staticStyle: { padding: "15px 0" },
                },
                [
                  _c(
                    "v-list-group",
                    {
                      attrs: { "no-action": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function () {
                            return [
                              _c(
                                "v-list-item",
                                { staticClass: "px-0 no-hover" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "svg-icon" },
                                    [_c("help-icon")],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("Core.drawer.help")) +
                                        "\n            "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        { attrs: { to: `/${_vm.$i18n.locale}/chats` } },
                        [
                          _c("v-list-item-title", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("Core.drawer.Chats")),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        { attrs: { to: `/${_vm.$i18n.locale}/help-center` } },
                        [
                          _c("v-list-item-title", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("helpCenter.title")),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          _vm.logoutPopup = true
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "svg-icon" },
                        [_c("logout-icon")],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-title", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("Core.drawer.Logout")),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.inputValue,
        callback: function ($$v) {
          _vm.inputValue = $$v
        },
        expression: "inputValue",
      },
    },
    [
      _c(
        "v-list-item",
        { staticStyle: { padding: "9px 13px", height: "65px" } },
        [
          _vm.getIsLoggedIn
            ? _c("div", { staticClass: "layout align-center" }, [
                _c(
                  "a",
                  {
                    staticClass: "my-auto",
                    staticStyle: {
                      "background-color": "#fff",
                      "border-radius": "5px",
                      width: "38px",
                      height: "38px",
                    },
                    attrs: { href: "/" },
                  },
                  [
                    _vm.getIsLoggedIn && _vm.getIsLoggedIn.medias
                      ? _c("img", {
                          attrs: {
                            alt: "Logo",
                            src:
                              _vm.getImageUrl + _vm.getIsLoggedIn.medias.name,
                            width: "38px",
                            height: "38px",
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { margin: "0px 7px" } }, [
                  _c(
                    "p",
                    {
                      staticClass: "subtitle my-0",
                      staticStyle: {
                        color: "#fff !important",
                        "line-height": "15px !important",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getIsLoggedIn.name) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.getIsLoggedIn.subscription_type == 1
                    ? _c(
                        "span",
                        {
                          staticClass: "packageLabel",
                          staticStyle: { height: "14px !important" },
                        },
                        [_vm._v(_vm._s(_vm.$t("packages.startPackage1")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getIsLoggedIn.subscription_type == 2
                    ? _c(
                        "span",
                        {
                          staticClass: "packageLabel",
                          staticStyle: { height: "14px !important" },
                        },
                        [_vm._v(_vm._s(_vm.$t("packages.mediumPackage1")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getIsLoggedIn.subscription_type == 3
                    ? _c(
                        "span",
                        {
                          staticClass: "packageLabel",
                          staticStyle: { height: "14px !important" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("packages.professionalismPackage1"))
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fb-nav-list",
          style: _vm.responsive
            ? " padding-top: 46px; "
            : "padding-top: 55px; ",
        },
        [
          _c(
            "v-list-item",
            { attrs: { to: `/${_vm.$i18n.locale}/dashboard` } },
            [
              _c("div", { staticClass: "svg-icon" }, [_c("home-icon")], 1),
              _vm._v(" "),
              _c("v-list-item-title", {
                domProps: {
                  textContent: _vm._s(_vm.$t("Core.drawer.Dashboard")),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.getIsLoggedIn && _vm.getIsLoggedIn.restrictions.emailVerified
            ? _c(
                "div",
                [
                  _vm._l(_vm.navlinks, function (item, i) {
                    return [
                      item.link
                        ? _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "permissions",
                                  rawName: "v-permissions",
                                  value: item.permissions,
                                  expression: "item.permissions",
                                },
                              ],
                              key: i,
                              attrs: {
                                to: `/${_vm.$i18n.locale}/` + item.link,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "svg-icon" },
                                [
                                  _c(item.icon, {
                                    tag: "component",
                                    staticStyle: {
                                      height: "20px",
                                      width: "20px",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("Core.drawer." + item.text)
                                  ),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !item.link
                        ? _c(
                            "v-list-group",
                            {
                              key: i,
                              attrs: { "no-action": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "px-0 no-hover" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "svg-icon" },
                                              [_c("analysis-icon")],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Core.drawer." + item.text
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _vm._l(item.links, function (listItem, m) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: m,
                                    attrs: {
                                      to: `/${_vm.$i18n.locale}/${listItem.action}`,
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("Core.drawer." + listItem.text)
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c("delete-popup", {
        attrs: {
          visible: _vm.logoutPopup,
          title: _vm.$t("dashboard.logout"),
          subTitle: _vm.$t("dashboard.logoutSubTitle"),
          btntitle: _vm.$t("dashboard.logout"),
          stop: 3,
        },
        on: {
          deleteAction: function ($event) {
            return _vm.logOutFunction()
          },
          close: function ($event) {
            _vm.logoutPopup = false
          },
        },
      }),
      _vm._v(" "),
      _c("store-name", {
        attrs: { visible: _vm.showUpdate },
        on: {
          close: function ($event) {
            _vm.showUpdate = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }