import Vue from 'vue'
import VueSocialAuth from 'vue-social-auth'

export default (obj, inject) => {
  // aviable only in client side
  if (!process.client) {
    return
  }

  //initialize plugin with options
  const pluginOptions = [{"property":"$auth","providers":{"google":{"display":"popup","clientId":"319031464505-u2s034qpelkrde7j20lmpq7haga5nur1.apps.googleusercontent.com","redirectUri":"https:\u002F\u002Fo-gulf.com\u002Fcallback"},"apple":{"clientId":"com.feedbacksaudi.app","display":"popup","redirectUri":"https:\u002F\u002Fo-gulf.com\u002Fcallback"}}}][0] || {}
  Vue.use(VueSocialAuth, pluginOptions)
}
