<template>
  <!--begin::Header-->
  <div class="landing-header" style="z-index: 99;">
    <header class="review">
      <div class="main-layout">
        <v-layout align-center>
          <NuxtLink :to="`/${$i18n.locale}/`" style="display: flex; align-items: center">
            <div v-if="getSectionToReview" class="
                  bg-input
                  rounded-6

                  text-center
                  layout
                  align-center
                " style="height:70px; width: 70px;">
              <v-img :src="getSectionToReview.store_logo" class="mx-auto rounded-4" height="60px" max-width="60px" />
            </div>

            <logo-white v-else class="logo" />
          </NuxtLink>
          <span v-if="getSectionToReview"
            class="mx-2 text-medium-16 text-white">{{ getSectionToReview.section_name }}</span>
        </v-layout>
        <div class="list-div" style="z-index: 1;display:flex">
          <ul class="main-list">

            <li>
              <a @click="changeLanguage($i18n.locale == 'ar' ? 'en' : 'ar')">
                {{ $i18n.locale === "ar" ? "English" : "العربية"
                }}

              </a>


            </li>
          </ul>
        </div>
      </div>
    </header>
  </div>
  <!--end::Header-->
</template>

<script>
import { mapGetters } from "vuex";
export default {

  data: () => ({
    toggleLang: false,
  }),
  computed: {
    ...mapGetters({
      getSectionToReview: 'user-website/getSectionToReview'
    }),
  },
  mounted() {
     
  },
  methods: {

    changeLanguage(lang) {
      //changing language by route
      this.$router.push({
        path:
          "/" + lang + this.$route.path.substring(3, this.$route.path.length),
      });
      setTimeout(() => {
          this.$router.go()

        }, 100);
      this.$nextTick(() => {
        this.toggleLang = !this.toggleLang;
        this.$nuxt.$loading.start();
        setTimeout(() => {
          this.$nuxt.$loading.finish();
          this.showcontent = true;
        }, 3000);
      });
    },
  },
};
</script>
 