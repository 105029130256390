export default {
  async loadAllProjects({ commit }, data) {
    commit('setAllProjects', {
      data: {
        content: null
      }
    });
    commit('setAllStoreProjects', {
      data: {
        content: null
      }
    });
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`projects?${data.is_global ? 'is_global=' + data.is_global : ''}&order_by=${data.order_by}&${data.deleted_items ? 'deleted_items=' + data.deleted_items : ''}`, null).then(respo => {
      if (respo.status === 200) {
        if (data.is_all) {
          commit('setAllStoreProjects', respo.data);

        } else {
          commit('setAllProjects', respo.data);

        }
      }
    });
  },
  async loadOneProject({ commit }, branchId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`/projects/${branchId}`, null).then(respo => {
      if (respo.status === 200) {
        commit('setOneProject', respo.data.data);
      }
    });
  },
  async saveNewProject({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .post(`/projects`, data)
      .then(respo => {
        if (respo.status === 200) {

          this.$router.push({
            path:
              `/${this.app.i18n.locale}/surveys`,
          });
        }
      });
  },
  async editOneProject({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`/projects/${data.branchId}`, data.branchDetails)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateProject', respo.data.data.content);
          commit('app/successMessage', 'projectUpdated', { root: true });
          this.$router.back();

        }
      });
  },
  async deleteOneProject({ commit }, branchId) {
    commit('app/loadingStart', null, { root: true });

    return await this.$axios.delete(`/projects/${branchId}`).then(respo => {
      if (respo.status === 200) {
        commit('deleteProject', branchId);
        commit('app/successMessage', 'projectDeleted', { root: true });
        if (this.$router.history.current.name != 'lang-Surveys') {

          this.$router.push({
            path: '/' + this.app.i18n.locale + '/surveys/'
          });
          return true
        }
      }
    });
  },
  async changeProjectState({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`/projects/state/${data.type}/${data.id}`, null)
      .then(respo => {
        if (respo.status === 200) {
          commit('setProjectState', data);
          if (data.type == 1) {
            // disable/activate project
            commit(
              'app/successMessage',
              data.state == 1 ? 'projectIsDisabled' : 'projectIsActive',
              { root: true }
            );
          }
          if (data.type == 2) {
            // hide/show project
            commit(
              'app/successMessage',
              data.is_show == 1 ? 'projectIsHidden' : 'projectIsShown',
              { root: true }
            );
          }
        }
      });
  },

  // loading qr code as blob 
  async loadQrCode({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    return await this.$axios
      .get(`/download-qr-code/${data.id}?type=${data.type}`, {
        responseType: 'blob'
      })
      .then(response => {
        var blob = response.data;
        return blob;
      });
  },


  async CopySurvey({ commit, dispatch }, data) {
    commit('app/loadingStart', null, { root: true });

    return await this.$axios
      .post(`/projects/copy`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('updateProject', respo.data.data.content);

          if (this.$router.history.current.name == 'lang-Projects-id') {

            dispatch('surveys/loadAllSurveys', { order_by: 'Desc', project_id: data.id }, { root: true });
          }

          commit('app/successMessage', 'SurveyCopied', { root: true });
          return true
        }

      });
  },
  async loadCopiedProject({ commit }, surveyId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`/projects/${surveyId}`, null).then(respo => {
      if (respo.status === 200) {
        commit('setCopiedProject', respo.data.data.content);
        this.$router.push({
          path:
            `/${this.app.i18n.locale}/projects/create`,
        });
      }
    });
  },
  async checkQrCode({ commit, dispatch }, data) {
    commit('app/loadingStart', null, { root: true });

    return await this.$axios
      .get(`/qr/link/check?custom_url=${data.custom_url}&old_custom_url=${data.old_custom_url}`)
      .then((respo) => {
        return respo.data.data.content
      });
  },
  // 
  // This code snippet defines an asynchronous function getHtmlCanvas that takes an object with  data as its parameter. It then uses the html2canvas library to capture a screenshot of an HTML element and convert it to different file types (SVG, PDF, DXF, or PNG) based on the data.fileType parameter.
  async getHtmlCanvas({ commit }, data) {
    await data.html2canvas(data.element, {
      type: 'dataURL',
      allowTaint: false,
      useCORS: true,
      logging: true,
      scale: data.fileType == 'DXF' ? 0.5 : 5,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById(data.refName).style.display = 'block';
      },
    }).then((canvas) => {
      var link = document.createElement('a');
      if (data.fileType == 'SVG') {
        var ctx = new data.C2S(canvas.width, canvas.height);
        let width = 720
        let height = (canvas.height / canvas.width) * width
        ctx.drawImage(canvas, 10, 10, width, height);
        var mySvg = ctx.getSerializedSvg(true);
        link.download = data.title + '_qr.svg';
        link.href = 'data:image/svg+xml;utf8,' + mySvg;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        commit('app/loadingFinish', null, { root: true });
      }
      else if (data.fileType == 'PDF') {

        var myImage = canvas.toDataURL('image/png');

        const pdf = new data.jsPDF();
        const aspectRatio = canvas.width / canvas.height;

        let maxHeight = 290
        let width = 210

        let height = width / aspectRatio;

        if (height > maxHeight) {
          height = maxHeight;
          width = height * aspectRatio;
        }
        let marginStart = (210 - width) / 2

        pdf.addImage(myImage, "png", marginStart, 0, width, height);
        pdf.save(data.title + '_qr.PDF');
        commit('app/loadingFinish', null, { root: true });
      }
      else if (data.fileType == 'DXF') {
        const writer = new DxfWriter();

        const context = canvas.getContext('2d');
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

        let colors = []
        const solid = pattern({
          name: HatchPredefinedPatterns.SOLID
        });
        for (let y = 0; y < canvas.height; y++) {
          for (let x = 0; x < canvas.width; x++) {
            const index = (y * canvas.width + x) * 4;
            let color = imageData.data[index]
            if (imageData.data[index] > 208 && imageData.data[index] < 254) {
              color = 254
            }
            if (imageData.data[index] > 190 && imageData.data[index] < 209) {
              color = 255
            }
            if (imageData.data[index] > 130 && imageData.data[index] < 191) {
              color = 192
            }
            if (imageData.data[index] > 30 && imageData.data[index] < 59) {
              color = 192
            }
            if (imageData.data[index] > 70 && imageData.data[index] < 131) {
              color = 192
            }
            if (imageData.data[index] > 58 && imageData.data[index] < 71) {
              color = 192
            }
            if (color != 255) {
              let polyline = new HatchPolylineBoundary();
              polyline.add(vertex(x, (canvas.height - 1 - y)));
              polyline.add(vertex(x, (canvas.height - 1 - y) + 1));
              polyline.add(vertex(x + 1, (canvas.height - 1 - y) + 1));
              polyline.add(vertex(x + 1, (canvas.height - 1 - y)));
              let boundary = new HatchBoundaryPaths();

              boundary.addPolylineBoundary(polyline);

              const vertices = [
                {
                  point: point2d(x, (canvas.height - 1 - y)),
                },
                {
                  point: point2d(x, (canvas.height - 1 - y) + 1),
                },
                {
                  point: point2d(x + 1, (canvas.height - 1 - y) + 1),
                },
                {
                  point: point2d(x + 1, (canvas.height - 1 - y)),
                },
              ];

              if (colors.includes(color)) {

                writer
                  .layer('qr' + color)
                writer
                  .addLWPolyline(vertices, {
                    flags: LWPolylineFlags.Closed,
                  });
                writer.addHatch(boundary, solid, {
                  colorNumber: + color,
                  layerName: 'qr' + color,
                });

              } else {
                colors.push(color)

                writer
                  .addLayer('l_yellow' + color, color, 'CONTINUOUS')
                writer
                  .layer('l_yellow' + color)
                writer
                  .addLWPolyline(vertices, {
                    flags: LWPolylineFlags.Closed,
                  });
                writer.addHatch(boundary, solid, {
                  colorNumber: 3,
                  layerName: 'l_yellow' + color,
                });

              }

            }

          }
        }

        const blob = new Blob([writer.stringify()], { type: 'application/dxf' });
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = data.title + '_qr.dxf';
        link.click();

        commit('app/loadingFinish', null, { root: true });
      }
      else {

        var myImage = canvas.toDataURL('image/png');
        link.download = data.title + '_qr.png';
        link.href = myImage;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        commit('app/loadingFinish', null, { root: true });


      }
    });
  },
};
