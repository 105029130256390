export default async ({
  $axios,
  isHMR,
  app,
  store,
  route,
  error,
  redirect,
}) => {
  //default Lang
  const defaultLocale = app.i18n.fallbackLocale;
  //local cookies Lang
  const localLanguage = app.$cookies.get('Language', { parseJSON: true });
  //route param
  const locale = route.params.lang || defaultLocale;
  if (isHMR) {
    return;
  }
 await store.dispatch('auth/checkLoggedIn');
  if (localLanguage === undefined) {
    var data = {
      locale: 'ar',
      rtl: 'rtl',
      direction: 'right',
    };
    if (locale !== undefined) {
      if (!store.state.app.locales.includes(locale)) {
        // checking website qr link to redirect to the review page
        return await store.dispatch('user-website/checkRedirectLink', locale).then((respo) => {
          return redirect('/ar' + respo);



        }).catch((err) => {
          return redirect('/ar');

        });
      } else {
        //if there is local and also it is one of the languages that we have
        store.commit('app/SET_LANG', {
          locale: locale == 'ar' ? 'ar' : 'en',
          rtl: locale == 'ar' ? 'rtl' : 'ltr',
          rtl2: locale == 'ar' ? 'rtl.' : '',
          direction: locale == 'ar' ? 'right' : 'left',
        });
      }
    }

    app.$cookies.set('Language', JSON.stringify(store.state.app.language), {
      parseJSON: false,
    });

  } else {
    if (locale !== undefined) {
      if (!store.state.app.locales.includes(locale)) {
        
        // checking website qr link to redirect to the review page
        return await store.dispatch('user-website/checkRedirectLink', locale).then((respo) => {
          return redirect('/ar' + respo);



        }).catch((err) => {
          return redirect('/ar');

        });
      } else {
        store.commit('app/SET_LANG', {
          locale: locale == 'ar' ? 'ar' : 'en',
          rtl: locale == 'ar' ? 'rtl' : 'ltr',
          rtl2: locale == 'ar' ? 'rtl.' : '',
          direction: locale == 'ar' ? 'right' : 'left',
        });
      }
      app.$cookies.set('Language', JSON.stringify(store.state.app.language), {
        parseJSON: false,
      });
    } else {
      store.commit('app/SET_LANG', localLanguage);
    }
  }


  // Set locale


  app.i18n.locale = store.state.app.language.locale;
  
  app.vuetify.rtl = locale=='ar';
  app.vuetify.framework.rtl =locale=='ar';

  if (route.fullPath === '/') {
    return redirect('/' + locale);
  }

  // checking user data and routes for page redirecting

  let loginData = app.$cookies.get('login-data', { parseJSON: true });

  var authlinks = [
    'branches',
    'top-reviews',
    'google-reviews',
    'sentiment-anlysis',
    'questions',
    'sections',
    'settings',
    'dashboard',
    'chat',
    'points',
    'ratings',
    'payment',
  ];
  var noauthlinks = ['signup', 'login', 'restore-account', 'verify-account'];
  var SecondRoute = route.path.split('/')[2]
    ? route.path.split('/')[2].toLowerCase()
    : '';
  if (loginData) { 
    if (
      store.state.auth.isLoggedIn &&
      store.state.auth.isLoggedIn.restrictions &&
      !store.state.auth.isLoggedIn.restrictions.emailVerified

    ) {
     let notVerifiedRoutes =['dashboard','help-center','chat','email-verification']
      if (!notVerifiedRoutes.includes(SecondRoute) ) {
        return redirect('/' + locale + '/dashboard');
      }
    }

    if (noauthlinks.includes(SecondRoute)) {
      return redirect('/' + locale + '/dashboard');
    }
  } else {
    if (authlinks.includes(SecondRoute)) {
      return redirect('/' + locale + '/login');
    }
  }
}
