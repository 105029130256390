export default {
  getAllCoupons(state) {
    return state.allCoupons;
  },
  getOneCoupon(state) {
    return state.oneCoupon;
  },
  getAddDialog(state) {
    return state.addDialog;
  },
  getUpdateDialog(state) {
    return state.updateDialog;
  },
  getDeleteDialog(state) {
    return state.deleteDialog;
  },
  getChangeStateDialog(state) {
    return state.changeStateDialog;
  },
};
