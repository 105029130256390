var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { attrs: { id: "contact-us-section" } }, [
      _c("div", { staticClass: "flex-layout" }, [
        _c(
          "div",
          { staticClass: "mx-auto text-area text-center" },
          [
            _c("v-chip", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("homePage.contactUs")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("h3", {
              staticClass: "text-title",
              domProps: { innerHTML: _vm._s(_vm.$t("homePage.needHelp")) },
            }),
            _vm._v(" "),
            _c(
              "v-form",
              {
                ref: "form",
                attrs: { action: "" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.sendMessage()
                  },
                },
                model: {
                  value: _vm.valid,
                  callback: function ($$v) {
                    _vm.valid = $$v
                  },
                  expression: "valid",
                },
              },
              [
                _c(
                  "v-layout",
                  {
                    staticClass: "form-content",
                    attrs: { wrap: "", "align-center": "" },
                  },
                  [
                    _c("v-flex", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name",
                          },
                        ],
                        attrs: {
                          placeholder: _vm.$t("homePage.name"),
                          type: "text",
                          name: "",
                          id: "",
                        },
                        domProps: { value: _vm.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.name = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("v-flex", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email",
                          },
                        ],
                        attrs: {
                          placeholder: _vm.$t("homePage.email"),
                          isMobile: "",
                          type: "email",
                          name: "",
                          id: "",
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.email = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-flex",
                      { attrs: { lg12: "", md12: "", sm12: "", xs12: "" } },
                      [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.message,
                              expression: "message",
                            },
                          ],
                          staticStyle: { resize: "none" },
                          attrs: {
                            placeholder: _vm.$t("homePage.message"),
                            name: "",
                            id: "",
                            cols: "30",
                            rows: "8",
                          },
                          domProps: { value: _vm.message },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.message = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "rounded-12 text-medium-16 text-main",
                        attrs: {
                          type: "submit",
                          disabled: !_vm.message || !_vm.email || !_vm.name,
                          color: "white",
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("homePage.sendNow")) +
                            "\n              "
                        ),
                        _c("arrow-left"),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("footer", [
      _c("div", { staticClass: "footer main-layout" }, [
        _c(
          "div",
          { staticClass: "footer-logo px-2" },
          [
            _c(
              "NuxtLink",
              { attrs: { to: `/${_vm.$i18n.locale}/`, "aria-label": "Home" } },
              [_c("logo-white", { staticClass: "logo" })],
              1
            ),
            _vm._v(" "),
            _c("p", {
              staticClass: "text-regular-18 text-white",
              domProps: {
                innerHTML: _vm._s(_vm.$t("homePage.footer_content")),
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "list-div", attrs: { id: "main-links" } }, [
          _c("ul", { staticClass: "main-list" }, [
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: `/${_vm.$i18n.locale}/#`,
                    "aria-label": "Home",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("homePage.home")) + " ")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "NuxtLink",
                  { attrs: { to: `/${_vm.$i18n.locale}/#about-section` } },
                  [_vm._v(_vm._s(_vm.$t("homePage.who_we_are")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "NuxtLink",
                  { attrs: { to: `/${_vm.$i18n.locale}/packages` } },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("homePage.packages")) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "main-list" }, [
            _c(
              "li",
              [
                _c(
                  "NuxtLink",
                  { attrs: { to: `/${_vm.$i18n.locale}/#questions-section` } },
                  [_vm._v(_vm._s(_vm.$t("homePage.faq")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "NuxtLink",
                  { attrs: { to: `/${_vm.$i18n.locale}/privacy` } },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("homePage.privacy")) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "NuxtLink",
                  { attrs: { to: `/${_vm.$i18n.locale}/terms` } },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("homePage.termsandCondations")) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "main-list" }, [
            _c(
              "li",
              [
                _c(
                  "NuxtLink",
                  {
                    attrs: {
                      id: "contact-text",
                      to: `/${_vm.$i18n.locale}/#contact-us-section`,
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("homePage.contactUs")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "feedback-email",
                  attrs: {
                    href: "mailto:info@feedback.com",
                    "aria-label": "mail us",
                  },
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s("info@feedback.sa") +
                      "\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { "background-color": "#fff" } },
      [
        _c(
          "v-layout",
          {
            staticClass: "mt-auto py-5",
            attrs: { "justify-center": "", "align-center": "" },
          },
          [
            _c(
              "div",
              { staticClass: "text-center d-flex justify-content-end" },
              [
                _c(
                  "NuxtLink",
                  {
                    staticStyle: { "text-decoration": "none" },
                    attrs: { to: `/${_vm.$i18n.locale}/` },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "text-medium-16 text-font-main" },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.$t("branches.all_rights"))
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-medium-16 text-font-sub text-primary-600",
                      },
                      [_vm._v("\n          " + _vm._s(_vm.$t("LogIn.org")))]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { attrs: { id: "social-links" } }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.instagram.com/feedback.saudi/",
            "aria-label": "instagram",
          },
        },
        [_c("span", { staticClass: "fab fa-instagram" })]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://twitter.com/feedbacksaudi?s=11",
            "aria-label": "twitter",
          },
        },
        [_c("span", { staticClass: "fab fa-twitter" })]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.facebook.com/Feedbacksa-112378451229741",
            "aria-label": "facebook",
          },
        },
        [_c("span", { staticClass: "fab fa-facebook-f" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }