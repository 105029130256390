var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    _vm.isLoading ? _c("div", [_c("landing-loading")], 1) : _vm._e(),
    _vm._v(" "),
    !_vm.isLoading
      ? _c(
          "div",
          [
            _c("landing-header"),
            _vm._v(" "),
            _c("Nuxt"),
            _vm._v(" "),
            _c("landing-footer"),
            _vm._v(" "),
            _c("messages"),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }