export default {
  async loadAllSurveys({ commit }, data) {
    commit('setAllSurveys', {
      data: {
        content: null
      }
    });
    commit('setAllProjectsSurveys', {
      data: {
        content: null
      }
    });

    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`surveys/management?${data.is_global ? 'is_global=' + data.is_global : ''}&order_by=${data.order_by}&${data.project_id ? 'branch_id=' + data.project_id : ''}&load_questions=${data.load_questions ? data.load_questions : '0'}&${data.deleted_items ? 'deleted_items=' + data.deleted_items : ''}`, null).then(respo => {
      if (respo.status === 200) {
        if (data.is_all) {
          commit('setAllProjectsSurveys', respo.data);
        } else {
          commit('setAllSurveys', respo.data);
        }
      }
    });
  },
  async loadOneSurvey({ commit }, surveyId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`/surveys/management/${surveyId}`, null).then(respo => {
      if (respo.status === 200) {
        commit('setOneSurvey', respo.data.data);
      }
    });
  },
  async loadCopiedSurvey({ commit }, surveyId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`/surveys/management/${surveyId}`, null).then(respo => {
      if (respo.status === 200) {
        commit('setCopiedSurvey', respo.data.data.content);
        this.$router.push({
          path:
            `/${this.app.i18n.locale}/surveys/create`,
        });
      }
    });
  },
  async saveNewSurvey({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .post(`/surveys/management`, data)
      .then(respo => {
        if (respo.status === 200) {

          this.$router.push({
            path:
              `/${this.app.i18n.locale}/surveys`,
          });
        }
      });
  },
  async editOneSurvey({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`/surveys/management/${data.surveyId}`, data.surveyDetails)
      .then(respo => {
        if (respo.status === 200) {

          commit('updateSurvey', respo.data.data.content);
          commit('app/successMessage', 'surveyUpdated', { root: true });
          this.$router.back();

        }
      });
  },
  async deleteOneSurvey({ commit }, surveyId) {
    commit('app/loadingStart', null, { root: true });

    return await this.$axios.delete(`/surveys/management/${surveyId}`).then(respo => {
      if (respo.status === 200) {
        commit('deleteSurvey', surveyId);
        commit('app/successMessage', 'surveyDeleted', { root: true });
        if (this.$router.history.current.name != 'lang-Surveys') {
          this.$router.push({
            path: '/' + this.app.i18n.locale + '/surveys/'
          });
        }
        return true;
      }
    });
  },

  async changeSurveyState({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`/surveys/state/${data.id}`, null)
      .then(respo => {
        if (respo.status === 200) {
          commit('setSurveyState', data);
          commit(
            'app/successMessage',
            data.state == 1 ? 'surveyIsHidden' : 'surveyIsShown',
            { root: true }
          );
        }

      });
  },

  async moveSurvey({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`/surveys/management/move/${data.id}`, data)
      .then(respo => {
        if (respo.status === 200) {
          // if()
          commit('applyMoveSurvey', data);
          commit('projects/updateSurveyCount', data, { root: true });
          commit(
            'app/successMessage',
            'surveyMoved',
            { root: true }
          );
        }

      });
  },
  async loadSurveyBank({ commit }) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`surveys/bank`, null).then(respo => {
      if (respo.status === 200) {
        commit('setSurveyBank', respo.data.data.content);
      }
    });
  },
};
