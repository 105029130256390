export default () => ({
  balance: null,
  can_add: null,
  storeBalance: {
    total: null,
    spent: null,
  },
  reviewsBalance: null,
  balanceInfo: {},
  addDialog: false,
  PaymentProcessSuccess: false,
  PaymentProcessfail: false,
});
