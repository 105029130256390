import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  // If store is an exported method = classic mode (deprecated)

  if (typeof store === 'function') {
    return console.warn('Classic mode for store/ is deprecated and will be removed in Nuxt 3.')
  }

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/app/actions.js'), 'app/actions.js')
  resolveStoreModules(require('../store/app/getters.js'), 'app/getters.js')
  resolveStoreModules(require('../store/app/mutations.js'), 'app/mutations.js')
  resolveStoreModules(require('../store/app/state.js'), 'app/state.js')
  resolveStoreModules(require('../store/auth/actions.js'), 'auth/actions.js')
  resolveStoreModules(require('../store/auth/getters.js'), 'auth/getters.js')
  resolveStoreModules(require('../store/auth/mutations.js'), 'auth/mutations.js')
  resolveStoreModules(require('../store/auth/state.js'), 'auth/state.js')
  resolveStoreModules(require('../store/balance/actions.js'), 'balance/actions.js')
  resolveStoreModules(require('../store/balance/getters.js'), 'balance/getters.js')
  resolveStoreModules(require('../store/balance/mutations.js'), 'balance/mutations.js')
  resolveStoreModules(require('../store/balance/state.js'), 'balance/state.js')
  resolveStoreModules(require('../store/categories/actions.js'), 'categories/actions.js')
  resolveStoreModules(require('../store/categories/getters.js'), 'categories/getters.js')
  resolveStoreModules(require('../store/categories/mutations.js'), 'categories/mutations.js')
  resolveStoreModules(require('../store/categories/state.js'), 'categories/state.js')
  resolveStoreModules(require('../store/chat/actions.js'), 'chat/actions.js')
  resolveStoreModules(require('../store/chat/getters.js'), 'chat/getters.js')
  resolveStoreModules(require('../store/chat/mutations.js'), 'chat/mutations.js')
  resolveStoreModules(require('../store/chat/state.js'), 'chat/state.js')
  resolveStoreModules(require('../store/coupons/actions.js'), 'coupons/actions.js')
  resolveStoreModules(require('../store/coupons/getters.js'), 'coupons/getters.js')
  resolveStoreModules(require('../store/coupons/mutations.js'), 'coupons/mutations.js')
  resolveStoreModules(require('../store/coupons/state.js'), 'coupons/state.js')
  resolveStoreModules(require('../store/google-reviews/actions.js'), 'google-reviews/actions.js')
  resolveStoreModules(require('../store/google-reviews/getters.js'), 'google-reviews/getters.js')
  resolveStoreModules(require('../store/google-reviews/mutations.js'), 'google-reviews/mutations.js')
  resolveStoreModules(require('../store/google-reviews/state.js'), 'google-reviews/state.js')
  resolveStoreModules(require('../store/projects/actions.js'), 'projects/actions.js')
  resolveStoreModules(require('../store/projects/getters.js'), 'projects/getters.js')
  resolveStoreModules(require('../store/projects/mutations.js'), 'projects/mutations.js')
  resolveStoreModules(require('../store/projects/state.js'), 'projects/state.js')
  resolveStoreModules(require('../store/questions/actions.js'), 'questions/actions.js')
  resolveStoreModules(require('../store/questions/getters.js'), 'questions/getters.js')
  resolveStoreModules(require('../store/questions/mutations.js'), 'questions/mutations.js')
  resolveStoreModules(require('../store/questions/state.js'), 'questions/state.js')
  resolveStoreModules(require('../store/reports/actions.js'), 'reports/actions.js')
  resolveStoreModules(require('../store/reports/getters.js'), 'reports/getters.js')
  resolveStoreModules(require('../store/reports/mutations.js'), 'reports/mutations.js')
  resolveStoreModules(require('../store/reports/state.js'), 'reports/state.js')
  resolveStoreModules(require('../store/reviews/actions.js'), 'reviews/actions.js')
  resolveStoreModules(require('../store/reviews/getters.js'), 'reviews/getters.js')
  resolveStoreModules(require('../store/reviews/mutations.js'), 'reviews/mutations.js')
  resolveStoreModules(require('../store/reviews/state.js'), 'reviews/state.js')
  resolveStoreModules(require('../store/shop/actions.js'), 'shop/actions.js')
  resolveStoreModules(require('../store/shop/getters.js'), 'shop/getters.js')
  resolveStoreModules(require('../store/shop/mutations.js'), 'shop/mutations.js')
  resolveStoreModules(require('../store/shop/state.js'), 'shop/state.js')
  resolveStoreModules(require('../store/surveys/actions.js'), 'surveys/actions.js')
  resolveStoreModules(require('../store/surveys/getters.js'), 'surveys/getters.js')
  resolveStoreModules(require('../store/surveys/mutations.js'), 'surveys/mutations.js')
  resolveStoreModules(require('../store/surveys/state.js'), 'surveys/state.js')
  resolveStoreModules(require('../store/system/actions.js'), 'system/actions.js')
  resolveStoreModules(require('../store/system/getters.js'), 'system/getters.js')
  resolveStoreModules(require('../store/system/mutations.js'), 'system/mutations.js')
  resolveStoreModules(require('../store/system/state.js'), 'system/state.js')
  resolveStoreModules(require('../store/user-website/actions.js'), 'user-website/actions.js')
  resolveStoreModules(require('../store/user-website/getters.js'), 'user-website/getters.js')
  resolveStoreModules(require('../store/user-website/mutations.js'), 'user-website/mutations.js')
  resolveStoreModules(require('../store/user-website/state.js'), 'user-website/state.js')

  // If the environment supports hot reloading...

  if (process.client && module.hot) {
    // Whenever any Vuex module is updated...
    module.hot.accept([
      '../store/app/actions.js',
      '../store/app/getters.js',
      '../store/app/mutations.js',
      '../store/app/state.js',
      '../store/auth/actions.js',
      '../store/auth/getters.js',
      '../store/auth/mutations.js',
      '../store/auth/state.js',
      '../store/balance/actions.js',
      '../store/balance/getters.js',
      '../store/balance/mutations.js',
      '../store/balance/state.js',
      '../store/categories/actions.js',
      '../store/categories/getters.js',
      '../store/categories/mutations.js',
      '../store/categories/state.js',
      '../store/chat/actions.js',
      '../store/chat/getters.js',
      '../store/chat/mutations.js',
      '../store/chat/state.js',
      '../store/coupons/actions.js',
      '../store/coupons/getters.js',
      '../store/coupons/mutations.js',
      '../store/coupons/state.js',
      '../store/google-reviews/actions.js',
      '../store/google-reviews/getters.js',
      '../store/google-reviews/mutations.js',
      '../store/google-reviews/state.js',
      '../store/projects/actions.js',
      '../store/projects/getters.js',
      '../store/projects/mutations.js',
      '../store/projects/state.js',
      '../store/questions/actions.js',
      '../store/questions/getters.js',
      '../store/questions/mutations.js',
      '../store/questions/state.js',
      '../store/reports/actions.js',
      '../store/reports/getters.js',
      '../store/reports/mutations.js',
      '../store/reports/state.js',
      '../store/reviews/actions.js',
      '../store/reviews/getters.js',
      '../store/reviews/mutations.js',
      '../store/reviews/state.js',
      '../store/shop/actions.js',
      '../store/shop/getters.js',
      '../store/shop/mutations.js',
      '../store/shop/state.js',
      '../store/surveys/actions.js',
      '../store/surveys/getters.js',
      '../store/surveys/mutations.js',
      '../store/surveys/state.js',
      '../store/system/actions.js',
      '../store/system/getters.js',
      '../store/system/mutations.js',
      '../store/system/state.js',
      '../store/user-website/actions.js',
      '../store/user-website/getters.js',
      '../store/user-website/mutations.js',
      '../store/user-website/state.js',
    ], () => {
      // Update `root.modules` with the latest definitions.
      updateModules()
      // Trigger a hot update in the store.
      window.$nuxt.$store.hotUpdate(store)
    })
  }
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
