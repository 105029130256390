export default {
   
  getBranchToReview(state) {
    return state.branchToReview;
  },
  getSectionToReview(state) {
    return state.sectionToReview;
  },
  getReviewCoupon(state) {
    return state.reviewCoupon;
  },
  getAdvertisement(state) {
    return state.advertisement;
  },
  getIsReviewed(state) {
    return state.reviewed;
  },
  getGoogleReviewLink(state) {
    return state.googleReviewLink;
  },
  getReviewId(state) {
    return state.reviewId;
  },

  getPhoneCode(state) {
    return state.PhoneCodeSent;
  },
  getOtpVerification(state) {
    return state.OtpVerification;
  },
  
};
