var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "wameed-btn",
      class: [
        _vm.classes,
        _vm.btnVar,
        _vm.pill ? "btn-pill" : "",
        _vm.disabled ? "btn-disabled" : "",
      ],
      attrs: { id: _vm.id, type: _vm.type, disabled: _vm.disabled },
      on: { click: _vm.onClick },
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.appendIcon
        ? _c(_vm.appendIcon, { tag: "component", staticClass: "btn-text" })
        : _vm._e(),
      _vm._v(" "),
      _vm.title
        ? _c("span", { class: ["btn-text ", _vm.textClasses] }, [
            _vm._v("\n        " + _vm._s(_vm.title) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.subTitle
        ? _c("span", { class: ["btn-text ", _vm.subTextClasses] }, [
            _vm._v("\n        " + _vm._s(_vm.subTitle) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.prependIcon
        ? _c(_vm.prependIcon, { tag: "component", staticClass: "btn-text" })
        : _vm._e(),
      _vm._v(" "),
      _c("span", {
        class: ["btn-spinner", _vm.loadingVariant],
        attrs: { label: "Spinning", small: "" },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }