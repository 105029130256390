var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "loading-bg" } }, [
    _c("div", { staticClass: "loading-logo" }, [
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "111.218",
            height: "39.845",
            viewBox: "0 0 111.218 39.845",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Group_132074",
                "data-name": "Group 132074",
                transform: "translate(0 -199.68)",
              },
            },
            [
              _c("path", {
                attrs: {
                  id: "Path_150429",
                  "data-name": "Path 150429",
                  d: "M293.178,210.033a1.548,1.548,0,0,0,.464-1.132V205.65h-3.483a1.583,1.583,0,0,0-1.6,1.6V210.5h3.483A1.54,1.54,0,0,0,293.178,210.033Z",
                  transform: "translate(-190.205 -3.935)",
                  fill: "#fff",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_150430",
                  "data-name": "Path 150430",
                  d: "M288.6,265.166h5.024a.888.888,0,1,0,0-1.776H288.6Z",
                  transform: "translate(-190.232 -41.995)",
                  fill: "#fff",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_150431",
                  "data-name": "Path 150431",
                  d: "M246.27,249.815v1.667h21.18a6.1,6.1,0,0,0,6.1-6.1v-6.22a7.564,7.564,0,0,0-.59-3.02,7.163,7.163,0,0,0-4.257-4.179,6.7,6.7,0,0,0-2.785-.668h-5.331a7.35,7.35,0,0,0-2.942.668,6.976,6.976,0,0,0-4.175,4.179,7.556,7.556,0,0,0-.671,3.02l-.017,7.788h5.051v-6.064c0-3.061,1.275-4.71,3.821-4.813l2.734-.007a3.6,3.6,0,0,1,3.828,2.768h-6.623a.89.89,0,1,0,0,1.779h6.868v1.329a5.506,5.506,0,0,1-.978,3.453,3.662,3.662,0,0,1-3.071,1.37H248.646",
                  transform: "translate(-162.33 -20.836)",
                  fill: "#fff",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_150432",
                  "data-name": "Path 150432",
                  d: "M272.955,236.157a7.163,7.163,0,0,0-4.257-4.179,6.7,6.7,0,0,0-2.785-.668h-3.794l-.181,4.772c.058,0,2.6,0,2.659,0a3.557,3.557,0,0,1,3.613,2.764h-6.623a.89.89,0,1,0,0,1.779h6.868v1.329a5.506,5.506,0,0,1-.978,3.453,3.662,3.662,0,0,1-3.071,1.37H248.646l-2.376,3.051V251.5h21.177a6.1,6.1,0,0,0,6.1-6.1v-6.22A7.535,7.535,0,0,0,272.955,236.157Z",
                  transform: "translate(-162.33 -20.849)",
                  fill: "#fff",
                },
              }),
              _vm._v(" "),
              _c(
                "g",
                {
                  attrs: {
                    id: "Group_132069",
                    "data-name": "Group 132069",
                    transform: "translate(0 199.683)",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      id: "Path_150433",
                      "data-name": "Path 150433",
                      d: "M15.59,214.933c-1.462,0-2.188-.763-2.188-2.3v-.566q0-2.372,2.113-2.372h4.428l0-2.785h-5.2a3.436,3.436,0,0,0-1.571.307,3.983,3.983,0,0,0-2.628,3.92v2.427a3.879,3.879,0,0,0,.228,1.363H8.552v2.86H20.717v-2.86H15.59Zm8.944-15.243-.01,22.21q0,4.054-4.745,4.053H9.564q-4.847,0-4.847-4.053V210.478H0v12.455a6.769,6.769,0,0,0,.539,2.941,7.9,7.9,0,0,0,2.836,3.477A7.564,7.564,0,0,0,7.73,230.67H21.582a7.571,7.571,0,0,0,4.383-1.319,7.833,7.833,0,0,0,2.867-3.477,8.692,8.692,0,0,0,.539-2.941l.01-23.242Z",
                      transform: "translate(0 -199.69)",
                      fill: "#fff",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_150434",
                      "data-name": "Path 150434",
                      d: "M115.229,210.4v12.451a7.4,7.4,0,0,1-.62,2.942,7.817,7.817,0,0,1-2.86,3.483,7.607,7.607,0,0,1-4.383,1.312H97.75l.014-30.894h4.847l-.01,26.174h2.959q4.826,0,4.826-4.046V210.4Z",
                      transform: "translate(-64.432 -199.69)",
                      fill: "#fff",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_150435",
                      "data-name": "Path 150435",
                      d: "M118.325,302.59a1.517,1.517,0,0,0-1.111.464,1.446,1.446,0,0,0-.464,1.053v3.252h3.477a1.589,1.589,0,0,0,1.575-1.6v-3.17Z",
                      transform: "translate(-76.956 -267.517)",
                      fill: "#fff",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_150436",
                      "data-name": "Path 150436",
                      d: "M213.641,302.59a1.5,1.5,0,0,0-1.1.464,1.449,1.449,0,0,0-.467,1.053v3.252h3.483a1.589,1.589,0,0,0,1.575-1.6v-3.17Zm5.961,0a1.506,1.506,0,0,0-1.111.464,1.537,1.537,0,0,0-.464,1.135v3.17H221.5a1.589,1.589,0,0,0,1.575-1.6v-3.17Z",
                      transform: "translate(-139.787 -267.517)",
                      fill: "#fff",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_150437",
                  "data-name": "Path 150437",
                  d: "M226.279,246.322a6.177,6.177,0,0,0,.484-2.771V231.1h-4.826v11.422q0,4.049-4.768,4.046h-3.48l-2.2,3.034v1.684H219a7.6,7.6,0,0,0,4.356-1.343,4.139,4.139,0,0,0,.62-.464A7.688,7.688,0,0,0,226.279,246.322Zm-.058.17",
                  transform: "translate(-139.404 -20.711)",
                  fill: "#fff",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  id: "Path_150438",
                  "data-name": "Path 150438",
                  d: "M180.572,243.686a6.962,6.962,0,0,1-.542,3.016,7.133,7.133,0,0,1-4.254,4.254,8.6,8.6,0,0,1-2.938.542H160.383l.051-4.717h11.371a3.649,3.649,0,0,0,3.068-1.367,5.5,5.5,0,0,0,.978-3.456V240.9q0-4.745-4.049-4.745H160.38V231.31h12.455a7.059,7.059,0,0,1,2.938.593,7.823,7.823,0,0,1,4.8,7.192v4.591Z",
                  transform: "translate(-105.715 -20.849)",
                  fill: "#fff",
                },
              }),
              _vm._v(" "),
              _c(
                "g",
                {
                  attrs: {
                    id: "Group_132070",
                    "data-name": "Group 132070",
                    transform: "translate(41.975 199.68)",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      id: "Path_150439",
                      "data-name": "Path 150439",
                      d: "M127.2,199.69h-3.722a.357.357,0,0,0-.259.072.349.349,0,0,0-.072.249v6.473h1.183V203.61h2.406v-1.1h-2.406v-1.725h2.87a.378.378,0,0,0,.256-.065.34.34,0,0,0,.072-.249v-.457a.32.32,0,0,0-.072-.249A.357.357,0,0,0,127.2,199.69Z",
                      transform: "translate(-123.15 -199.687)",
                      fill: "#fff",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_150440",
                      "data-name": "Path 150440",
                      d: "M166.724,205.119a1.884,1.884,0,0,0-.678-.412,2.921,2.921,0,0,0-.968-.147,2.29,2.29,0,0,0-1.622.518,1.981,1.981,0,0,0-.556,1.507v1.1a2.493,2.493,0,0,0,.123.8,1.624,1.624,0,0,0,.382.641,1.731,1.731,0,0,0,.668.416,3.493,3.493,0,0,0,2.27-.092,1.68,1.68,0,0,0,.743-.661c.136-.208.1-.368-.112-.467l-.331-.177a.415.415,0,0,0-.266-.072.453.453,0,0,0-.215.184,1,1,0,0,1-.416.368,1.586,1.586,0,0,1-.689.133,1.194,1.194,0,0,1-.794-.239.87.87,0,0,1-.276-.7v-.348h2.829a.407.407,0,0,0,.337-.112.472.472,0,0,0,.085-.3V206.5a2.251,2.251,0,0,0-.126-.76A1.518,1.518,0,0,0,166.724,205.119Zm-.569,1.524h-2.164v-.208a.912.912,0,0,1,.276-.709,1.2,1.2,0,0,1,.815-.249,1.165,1.165,0,0,1,.8.249.9.9,0,0,1,.269.709v.208Z",
                      transform: "translate(-149.351 -202.897)",
                      fill: "#fff",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_150441",
                      "data-name": "Path 150441",
                      d: "M146.294,205.119a1.884,1.884,0,0,0-.678-.412,2.921,2.921,0,0,0-.968-.147,2.29,2.29,0,0,0-1.622.518,1.973,1.973,0,0,0-.556,1.507v1.1a2.493,2.493,0,0,0,.123.8,1.624,1.624,0,0,0,.382.641,1.732,1.732,0,0,0,.668.416,3.493,3.493,0,0,0,2.27-.092,1.68,1.68,0,0,0,.743-.661c.136-.208.1-.368-.112-.467l-.331-.177a.415.415,0,0,0-.266-.072.453.453,0,0,0-.215.184,1,1,0,0,1-.416.368,1.586,1.586,0,0,1-.689.133,1.194,1.194,0,0,1-.794-.239.87.87,0,0,1-.276-.7v-.348h2.829a.407.407,0,0,0,.337-.112.472.472,0,0,0,.085-.3V206.5a2.252,2.252,0,0,0-.126-.76A1.518,1.518,0,0,0,146.294,205.119Zm-.569,1.524h-2.164v-.208a.912.912,0,0,1,.276-.709,1.2,1.2,0,0,1,.815-.249,1.165,1.165,0,0,1,.8.249.9.9,0,0,1,.269.709v.208Z",
                      transform: "translate(-135.885 -202.897)",
                      fill: "#fff",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_150442",
                      "data-name": "Path 150442",
                      d: "M206.482,201.35a3.436,3.436,0,0,0-.627.055c-.187.034-.358.072-.5.1V199.98a.323.323,0,0,0-.061-.225.288.288,0,0,0-.218-.065h-.518a.323.323,0,0,0-.225.065.316.316,0,0,0-.065.225v6.3s1.663.208,2.045.208a2.2,2.2,0,0,0,1.6-.508,1.977,1.977,0,0,0,.518-1.476v-1.268a1.943,1.943,0,0,0-.467-1.391A1.983,1.983,0,0,0,206.482,201.35Zm.859,3.122q0,1.023-1.1,1.023a2.33,2.33,0,0,1-.8-.113.112.112,0,0,1-.061-.041.133.133,0,0,1-.014-.061v-2.768c.112-.034.249-.072.416-.113a2.7,2.7,0,0,1,.549-.055,1.085,1.085,0,0,1,.763.235.905.905,0,0,1,.256.689Z",
                      transform: "translate(-176.62 -199.687)",
                      fill: "#fff",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_150443",
                      "data-name": "Path 150443",
                      d: "M227.788,205.109a1.525,1.525,0,0,0-.617-.406,2.8,2.8,0,0,0-.961-.143,2.515,2.515,0,0,0-1.21.259,1.657,1.657,0,0,0-.729.712.3.3,0,0,0-.041.256.371.371,0,0,0,.2.167l.378.164a.337.337,0,0,0,.228.034.521.521,0,0,0,.174-.177,1.074,1.074,0,0,1,.982-.447.94.94,0,0,1,.733.239,1.044,1.044,0,0,1,.2.688v.259c-.082,0-.2-.014-.372-.024s-.348-.02-.525-.02a5.21,5.21,0,0,0-.968.082,1.72,1.72,0,0,0-.661.256.993.993,0,0,0-.378.464,1.841,1.841,0,0,0-.123.712,2.092,2.092,0,0,0,.092.648,1.029,1.029,0,0,0,.331.477,1.536,1.536,0,0,0,.641.3,4.49,4.49,0,0,0,1.023.1h2.025v-3.207a2.945,2.945,0,0,0-.1-.77A1.464,1.464,0,0,0,227.788,205.109Zm-.661,3.483v.2h-1.04a1.763,1.763,0,0,1-.443-.041.649.649,0,0,1-.276-.126.453.453,0,0,1-.133-.2,1.057,1.057,0,0,1-.031-.259.545.545,0,0,1,.184-.447,1.149,1.149,0,0,1,.719-.164c.092,0,.187,0,.286,0s.194.01.286.014.177.01.259.02.143,0,.187,0Z",
                      transform: "translate(-189.692 -202.897)",
                      fill: "#fff",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_150444",
                      "data-name": "Path 150444",
                      d: "M248.251,208.026l-.423-.123a.375.375,0,0,0-.256-.014.332.332,0,0,0-.157.2.966.966,0,0,1-.361.474,1.133,1.133,0,0,1-.661.174,1.069,1.069,0,0,1-.764-.239.886.886,0,0,1-.256-.678v-1.391a.872.872,0,0,1,.256-.682,1.1,1.1,0,0,1,.764-.235,1.181,1.181,0,0,1,.668.164.938.938,0,0,1,.358.464.393.393,0,0,0,.157.218.279.279,0,0,0,.256-.02l.412-.133a.459.459,0,0,0,.215-.153.381.381,0,0,0,0-.269,1.781,1.781,0,0,0-.678-.876,2.793,2.793,0,0,0-2.962.157,1.893,1.893,0,0,0-.525,1.411v1.3a1.922,1.922,0,0,0,.5,1.4,2.107,2.107,0,0,0,1.568.515,2.469,2.469,0,0,0,1.353-.32,1.9,1.9,0,0,0,.74-.927.3.3,0,0,0,.014-.266A.522.522,0,0,0,248.251,208.026Z",
                      transform: "translate(-203 -202.897)",
                      fill: "#fff",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_150445",
                      "data-name": "Path 150445",
                      d: "M267.613,203.869a1.178,1.178,0,0,0-.378-.3l1.415-1.752c.177-.225.112-.337-.187-.337h-.457a1.447,1.447,0,0,0-.348.024.444.444,0,0,0-.218.187l-1.271,1.66h-.487V199.98a.342.342,0,0,0-.061-.235.317.317,0,0,0-.239-.065H264.9a.328.328,0,0,0-.239.065.324.324,0,0,0-.065.235v6.5h1.084V204.37h.566a.861.861,0,0,1,.467.106.786.786,0,0,1,.279.351l.579,1.247c.051.112.187.4.187.4h1.21l-1.057-2.127A2.663,2.663,0,0,0,267.613,203.869Z",
                      transform: "translate(-216.387 -199.68)",
                      fill: "#fff",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Path_150446",
                      "data-name": "Path 150446",
                      d: "M187.232,199.69h-.518a.288.288,0,0,0-.218.065.317.317,0,0,0-.061.225v1.527c-.143-.031-.31-.065-.5-.1a3.435,3.435,0,0,0-.627-.055,1.971,1.971,0,0,0-1.483.494,1.938,1.938,0,0,0-.467,1.391V204.5a1.991,1.991,0,0,0,.518,1.476,2.2,2.2,0,0,0,1.6.508c.382,0,2.045-.208,2.045-.208v-6.3a.322.322,0,0,0-.065-.225A.307.307,0,0,0,187.232,199.69Zm-.8,5.59a.134.134,0,0,1-.014.061.141.141,0,0,1-.061.041,2.415,2.415,0,0,1-.8.113c-.74,0-1.1-.341-1.1-1.023v-1.207a.9.9,0,0,1,.256-.688,1.1,1.1,0,0,1,.764-.235,2.647,2.647,0,0,1,.549.055c.167.041.307.075.416.113v2.771Z",
                      transform: "translate(-162.838 -199.687)",
                      fill: "#fff",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading" }, [
      _c("div", { staticClass: "effect-1 effects" }),
      _vm._v(" "),
      _c("div", { staticClass: "effect-2 effects" }),
      _vm._v(" "),
      _c("div", { staticClass: "effect-3 effects" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }