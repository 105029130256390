import { render, staticRenderFns } from "./WameedButton.vue?vue&type=template&id=83d5be20"
import script from "./WameedButton.vue?vue&type=script&lang=js"
export * from "./WameedButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/client/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ogulf/nodevenv/public_html/client/16/lib/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('83d5be20')) {
      api.createRecord('83d5be20', component.options)
    } else {
      api.reload('83d5be20', component.options)
    }
    module.hot.accept("./WameedButton.vue?vue&type=template&id=83d5be20", function () {
      api.rerender('83d5be20', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/material/WameedButton.vue"
export default component.exports