<template>
  <!--begin::Header-->
  <div class="landing-header" style=" ">
    <header>
      <div class="main-layout responsive-header">
       <div style="line-height: 14px;">
        <NuxtLink :to="`/${$i18n.locale}/`" aria-label="logo" style="display: flex; align-items: center">
          <logo-white class="logo" />
        </NuxtLink>
        <span class="text-medium-12 text-font-sub text-white  " style="margin-left: 6px;margin-right: 6px;">
            {{ $t('LogIn.org') }}</span>
       </div>
        <div>
          <button @click="showMenu()" aria-label="menu" id="header-burger-menu">
            <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="13.5" viewBox="0 0 19.5 13.5">
              <path id="Path_2055" data-name="Path 2055"
                d="M546.25,942a.75.75,0,0,1,.75-.75h18a.75.75,0,0,1,0,1.5H547A.75.75,0,0,1,546.25,942Zm0,6a.75.75,0,0,1,.75-.75h18a.75.75,0,0,1,0,1.5H547A.75.75,0,0,1,546.25,948Zm.75,5.25a.75.75,0,0,0,0,1.5h18a.75.75,0,0,0,0-1.5Z"
                transform="translate(-546.25 -941.25)" fill="#fff" fill-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
      <div id="main-header" :class="activeMenu ? 'active' : ''">
        <div class="overly-background" @click="activeMenu = false">
        </div>
        <div class="main-layout ">
          <div class="sidebar-header">
            <div style="line-height: 14px;">
            <NuxtLink :to="`/${$i18n.locale}/`" style="display: flex; align-items: center" aria-label="logo2" >
              <logo-white class="logo2"   />
            </NuxtLink>
            <div>
              <span class="text-medium-12 text-font-sub text-white" style="margin-left: 6px;margin-right: 6px;">
            {{ $t('LogIn.org') }}</span>
            </div>
           </div>
            <div>
              <button @click="activeMenu = false">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
                  <path id="Path_2060" data-name="Path 2060"
                    d="M269.47,941.47a.749.749,0,0,1,1.06,0l5.47,5.469,5.47-5.469a.75.75,0,0,1,1.06,1.06L277.061,948l5.469,5.47a.75.75,0,0,1-1.06,1.06L276,949.061l-5.47,5.469a.75.75,0,0,1-1.06-1.06l5.469-5.47-5.469-5.47A.749.749,0,0,1,269.47,941.47Z"
                    transform="translate(-269.25 -941.25)" fill="#fff" fill-rule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
          <div class="list-div" style="z-index: 1;">
            <ul class="main-list">
              <li>
                <NuxtLink :to="`/${$i18n.locale}/`">{{
                  $t("homePage.home")
                }}</NuxtLink>
              </li>
              <li>
                <NuxtLink :to="`/${$i18n.locale}/#about-section`">{{
                  $t("homePage.who_we_are")
                }}</NuxtLink>
              </li>
              <li>
                <NuxtLink :to="`/${$i18n.locale}/#package-section`">{{
                  $t("homePage.packages")
                }}</NuxtLink>
              </li>

              <li>
                <NuxtLink :to="`/${$i18n.locale}/#questions-section`">{{
                  $t("homePage.faq")
                }}</NuxtLink>
              </li>
              <li>
                <a href="#" @click="changeLanguage($i18n.locale == 'ar' ? 'en' : 'ar')">
                  {{ $i18n.locale === "ar" ? "English" : "العربية"
                  }}

                </a>


              </li>
            </ul>
          </div>
          <div class="list-div login-btn-div" style="z-index: 1">
            <v-btn outlined :to="getIsLoggedIn
                  ? `/${$i18n.locale}/dashboard`
                  : `/${$i18n.locale}/login`
                " class="loginBtn" style="text-decoration: none;">
              <span  style="text-decoration: none;">
                {{
                  getIsLoggedIn
                  ? $t("homePage.dashboard")
                  : $t("homePage.loginAsCompany")
                }}
              </span>
            </v-btn>
          </div>
        </div>
      </div>
    </header>
  </div>
  <!--end::Header-->
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data: () => ({
    toggleLang: false,
    activeMenu: false
  }),
  computed: {
    ...mapGetters({
      getIsLoggedIn: "auth/getIsLoggedIn",
    }),
  },
  mounted() {
    this.$gsap
      .timeline()
      .from("header .logo", {
        duration: 1,
        y: -10,
        opacity: 0,
        ease: "power2.easeInOut",
      })
      .from("header .main-list li ", {
        duration: 0.6,
        opacity: 0,
        x: -10,
        stagger: 0.2,
      }) 
  },
  methods: {
    showMenu() {
      this.activeMenu = true 
    },

    changeLanguage(lang) {
      this.$router.push({
        path:
          "/" + lang + this.$route.path.substring(3, this.$route.path.length),
      });
      setTimeout(() => {
          this.$router.go()

        }, 100);
      this.$nextTick(() => {
        this.toggleLang = !this.toggleLang;
        this.$nuxt.$loading.start();
        setTimeout(() => {
          this.$nuxt.$loading.finish();
          this.showcontent = true;
        }, 3000);
      });
    },
  },
};
</script>
 