var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "elevation-0",
          staticStyle: { "z-index": "99" },
          style: _vm.responsive
            ? `background-color: #8363AA;padding:0;`
            : `background-color: #ffffff;padding-left: 10px !important;padding-right: 10px !important;`,
          attrs: {
            id: "core-toolbar",
            app: "",
            height: _vm.responsive ? `55` : `65`,
          },
        },
        [
          _c(
            "v-layout",
            { staticStyle: { "max-width": "1872px" } },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.responsive,
                      expression: "responsive",
                    },
                  ],
                  staticClass: "d-flex d-md-none",
                  attrs: { icon: "", dark: "", text: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onClickBtn.apply(null, arguments)
                    },
                  },
                },
                [
                  !this.getdrawer
                    ? _c("v-icon", [_vm._v("fas fa-bars")])
                    : _vm._e(),
                  _vm._v(" "),
                  this.getdrawer
                    ? _c("x-icon", { staticClass: "svg-white" })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.responsive,
                      expression: "!responsive",
                    },
                  ],
                  attrs: { lg6: "", md7: "", sm4: "", xs4: "" },
                },
                [
                  _c(
                    "v-autocomplete",
                    {
                      attrs: {
                        items: _vm.items,
                        loading: false,
                        placeholder: _vm.$t("Core.Header.Search") + " ...",
                        "search-input": _vm.search,
                        "hide-selected": "",
                        solo: "",
                        "hide-no-data": "",
                        "hide-details": "auto",
                        "item-text": "name",
                        "item-value": "abbr",
                        "return-object": "",
                        "append-icon": "",
                      },
                      on: {
                        "update:searchInput": function ($event) {
                          _vm.search = $event
                        },
                        "update:search-input": function ($event) {
                          _vm.search = $event
                        },
                      },
                      model: {
                        value: _vm.model,
                        callback: function ($$v) {
                          _vm.model = $$v
                        },
                        expression: "model",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          attrs: { slot: "prepend-inner" },
                          slot: "prepend-inner",
                        },
                        [_c("search-icon")],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.responsive,
                      expression: "responsive",
                    },
                  ],
                  staticClass: "my-auto",
                  staticStyle: {
                    "background-color": "#fff",
                    "border-radius": "5px",
                    width: "38px",
                    height: "38px",
                  },
                  attrs: { href: "/" },
                },
                [
                  _vm.getIsLoggedIn && _vm.getIsLoggedIn.medias
                    ? _c("img", {
                        attrs: {
                          alt: "Logo",
                          src: _vm.getImageUrl + _vm.getIsLoggedIn.medias.name,
                          width: "38px",
                          height: "38px",
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("v-spacer", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.responsive,
                    expression: "responsive",
                  },
                ],
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.responsive,
                      expression: "responsive",
                    },
                  ],
                  attrs: { text: "", size: "" },
                  on: {
                    click: function ($event) {
                      _vm.userMenu = !_vm.userMenu
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        id: "Stockholm-icons-_-General-_-User",
                        "data-name": "Stockholm-icons-/-General-/-User",
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "25.987",
                        height: "25.987",
                        viewBox: "0 0 16.987 16.987",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          id: "Shape",
                          d: "M0,0H16.987V16.987H0Z",
                          fill: "none",
                          "fill-rule": "evenodd",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          id: "Mask",
                          d: "M10.831,8.662a2.831,2.831,0,1,1,2.831-2.831A2.831,2.831,0,0,1,10.831,8.662Z",
                          transform: "translate(-2.338 -0.877)",
                          fill: "#fff",
                          opacity: "0.3",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          id: "Mask-Copy",
                          d: "M3,18.1C3.275,14.717,6.017,13,9.358,13c3.389,0,6.173,1.623,6.381,5.1a.486.486,0,0,1-.532.566H3.515A.784.784,0,0,1,3,18.1Z",
                          transform: "translate(-0.877 -3.799)",
                          fill: "#fff",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-layout",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.responsive,
                      expression: "!responsive",
                    },
                  ],
                  attrs: { "justify-end": "", "align-center": "" },
                },
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: "#089E90",
                        value: _vm.getIsLoggedIn.unread_alerts > 0 ? 1 : 0,
                        bordered: "",
                        dot: "",
                        overlap: "",
                        top: "",
                        "offset-x": "22",
                        "offset-y": "14",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-1 px-0",
                          staticStyle: { "min-width": "38px" },
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.showNotification = true
                            },
                          },
                        },
                        [_c("notifications-icon")],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: "#089E90",
                        value:
                          _vm.getStatistics &&
                          _vm.getStatistics.no_read_messages > 0
                            ? 1
                            : 0,
                        bordered: "",
                        dot: "",
                        overlap: "",
                        top: "",
                        "offset-x": "15",
                        "offset-y": "15",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-1 px-0",
                          staticStyle: { "min-width": "38px" },
                          attrs: { to: `/${_vm.$i18n.locale}/chats`, text: "" },
                        },
                        [_c("chat-icon")],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    { attrs: { id: "lang-img", text: "" } },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm.language.locale === "ar"
                                    ? _c("ar-svg-icon")
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.language.locale === "en"
                                    ? _c("en-svg-icon")
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list",
                        {
                          staticClass: "fb-lang-list p-0 m-0",
                          staticStyle: {
                            "max-width": "147px",
                            padding: "6px 0px",
                          },
                        },
                        [
                          _c(
                            "v-list-item",
                            {
                              class:
                                _vm.$i18n.locale === "ar" ? `active` : null,
                              staticStyle: {
                                "min-height": "22px",
                                "min-width": "140px",
                                padding: "4px 12px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLanguage("ar")
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        "align-center": "",
                                        "justify-space-between": "",
                                      },
                                    },
                                    [
                                      _c("v-layout", [
                                        _c(
                                          "span",
                                          [
                                            _c("ar-svg-icon", {
                                              staticStyle: {
                                                height: "16px",
                                                width: "16px",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-regular-12 text-font-main",
                                            staticStyle: { margin: "0 8px" },
                                          },
                                          [_vm._v("العربية")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm.$i18n.locale === "ar"
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                            },
                                            [_vm._v("fa-check")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            {
                              staticStyle: {
                                "min-height": "22px",
                                "min-width": "140px",
                                padding: "4px 12px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLanguage("en")
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { "justify-space-between": "" } },
                                    [
                                      _c("v-layout", [
                                        _c(
                                          "span",
                                          [
                                            _c("en-svg-icon", {
                                              staticStyle: {
                                                height: "16px",
                                                width: "16px",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-regular-12 text-font-main",
                                            staticStyle: { margin: "0 8px" },
                                          },
                                          [_vm._v("English")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm.$i18n.locale === "en"
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                            },
                                            [_vm._v("fa-check")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.getIsLoggedIn
                    ? _c(
                        "div",
                        { staticClass: "px-2" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "bg-light text-regular-13 text-font-sub px-0",
                              staticStyle: {
                                height: "40px",
                                "min-width": "40px",
                                margin: "0 10px",
                              },
                              attrs: { text: "" },
                            },
                            [
                              _vm.getIsLoggedIn.owner_name
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: { "font-weight": "bolder" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getIsLoggedIn.owner_name.substring(
                                            0,
                                            1
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "mx-1",
                              staticStyle: {
                                "font-weight": "700 !important",
                                color: "#b5b5c3 !important",
                                "font-size": "1rem",
                              },
                            },
                            [_vm._v("Hi,")]
                          ),
                          _vm._v(" "),
                          _vm.getIsLoggedIn.owner_name
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "600 !important",
                                    color: "#7e8299 !important",
                                    "font-size": "1rem",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getIsLoggedIn.owner_name.split(" ")[0]
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.responsive && _vm.userMenu,
              expression: "responsive && userMenu",
            },
          ],
          staticClass: "fb-user-menu",
          attrs: { "justify-end": "", "align-center": "" },
        },
        [
          _c(
            "v-badge",
            {
              attrs: {
                color: "#089E90",
                value: _vm.getIsLoggedIn.unread_alerts > 0 ? 1 : 0,
                bordered: "",
                dot: "",
                overlap: "",
                top: "",
                "offset-x": "22",
                "offset-y": "14",
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-1 px-0",
                  staticStyle: { "min-width": "38px" },
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.showNotification = true
                    },
                  },
                },
                [_c("notifications-icon")],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-badge",
            {
              attrs: {
                color: "#089E90",
                value:
                  _vm.getStatistics && _vm.getStatistics.no_read_messages > 0
                    ? 1
                    : 0,
                bordered: "",
                dot: "",
                overlap: "",
                top: "",
                "offset-x": "15",
                "offset-y": "15",
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-1 px-0",
                  staticStyle: { "min-width": "38px" },
                  attrs: { to: `/${_vm.$i18n.locale}/chats`, text: "" },
                },
                [_c("chat-icon")],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mx-1 px-0",
                              staticStyle: { "min-width": "28px" },
                              attrs: { text: "", id: "lang-img" },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm.language.locale === "ar"
                            ? _c("ar-svg-icon")
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.language.locale === "en"
                            ? _c("en-svg-icon")
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                "v-list",
                {
                  staticClass: "fb-lang-list p-0 m-0",
                  staticStyle: { "max-width": "147px", padding: "6px 0px" },
                },
                [
                  _c(
                    "v-list-item",
                    {
                      class: _vm.$i18n.locale === "ar" ? `active` : null,
                      staticStyle: {
                        "min-height": "22px",
                        "min-width": "140px",
                        padding: "4px 12px",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeLanguage("ar")
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                "align-center": "",
                                "justify-space-between": "",
                              },
                            },
                            [
                              _c("v-layout", [
                                _c(
                                  "span",
                                  [
                                    _c("ar-svg-icon", {
                                      staticStyle: {
                                        height: "16px",
                                        width: "16px",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-regular-12 text-font-main",
                                    staticStyle: { margin: "0 8px" },
                                  },
                                  [_vm._v("العربية")]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.$i18n.locale === "ar"
                                ? _c(
                                    "v-icon",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v("fa-check")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      staticStyle: {
                        "min-height": "22px",
                        "min-width": "140px",
                        padding: "4px 12px",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeLanguage("en")
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-layout",
                            { attrs: { "justify-space-between": "" } },
                            [
                              _c("v-layout", [
                                _c(
                                  "span",
                                  [
                                    _c("en-svg-icon", {
                                      staticStyle: {
                                        height: "16px",
                                        width: "16px",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-regular-12 text-font-main",
                                    staticStyle: { margin: "0 8px" },
                                  },
                                  [_vm._v("English")]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.$i18n.locale === "en"
                                ? _c(
                                    "v-icon",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v("fa-check")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.getIsLoggedIn
            ? _c(
                "div",
                { staticClass: "px-2" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "bg-light text-regular-13 text-font-sub px-0",
                      staticStyle: {
                        height: "40px",
                        "min-width": "40px",
                        margin: "0 10px",
                      },
                      attrs: { text: "" },
                    },
                    [
                      _vm.getIsLoggedIn.owner_name
                        ? _c(
                            "span",
                            { staticStyle: { "font-weight": "bolder" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getIsLoggedIn.owner_name.substring(0, 1)
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showNotification
        ? _c("notification", {
            attrs: { visible: _vm.showNotification },
            on: {
              close: function ($event) {
                _vm.showNotification = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }